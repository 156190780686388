import React, { useState, useEffect } from 'react';
import { getQrKey, getQrState } from '../../actions/user';
import { QRCodeSVG } from 'qrcode.react';
import useInterval from '../../hooks/useInterval';
import { Alert, Button, Fade, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading';
import { useUserStateDispatch } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { useLanguageValue } from '../../context/LanguageContext';
import { makeStyles } from '@mui/styles';
/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const useStyles = makeStyles((theme) => ({
  refreshButton: {
    height: 46,
    textTransform: 'none',
    width: 240,
  },
  errorMessage: {
    marginBottom: theme.spacing(4),
    width: '100%',
  },
}));
function SignInQr() {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  // global
  const {
    userDispatch,
    userState: { appInfo },
  } = useUserStateDispatch();
  const {
    languageState: { language },
  } = useLanguageValue();
  // local
  const [data, setData] = useState({ qrLoaded: false });
  const [counter, setCounter] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [serverResponse, setServerResponse] = useState('');

  // get start data
  useEffect(() => {
    setData({ qrLoaded: false });
    getQrKey(setData);
  }, []);

  // Set Counter
  useEffect(() => {
    if (data?.lifeTime != null) setCounter(data?.lifeTime);
    console.log(data);
  }, [data?.lifeTime]);

  // Counter
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  // Check state
  useInterval(
    () => {
      getQrState(
        data?.key,
        userDispatch,
        language,
        navigate,
        setServerResponse,
        setIsLoading,
        appInfo?.isAnonymousChildrenEnabled,
      );
    },
    data?.key && counter > 0,
    1000,
  );

  const value = data?.key
    ? `mobiapp://qrCodeLogin/${data?.key}`
    : null;
  //console.log('state', state);

  return (
    <>
      <Fade
        in={!!serverResponse}
        style={
          serverResponse == ''
            ? { display: 'none' }
            : { display: 'flex' }
        }
      >
        <Alert severity="error" className={classes.errorMessage}>
          <Typography variant="h6">
            {serverResponse || t('SIGN.IN_ERRORS')}
          </Typography>
        </Alert>
      </Fade>

      {isLoading ? (
        <Loading />
      ) : data.qrLoaded ? (
        <Grid container spacing={2}>
          <Grid item style={{ width: 334 }}>
            {value != null && <QRCodeSVG value={value} size={300} />}
          </Grid>
          <Grid item xs>
            {counter > 0 ? (
              <>
                <Typography variant="body2" mb={3}>
                  {t('SIGN.IN_BY_QR_INFO')}
                </Typography>
                <Alert severity="info">
                  <Typography variant="body1">
                    {t('SIGN.IN_BY_QR_LIFETIME', { sec: counter })}
                  </Typography>
                </Alert>
              </>
            ) : (
              <>
                <Typography variant="body2" mb={3}>
                  {t('SIGN.IN_BY_QR_INFO1', { sec: data?.lifeTime })}
                </Typography>
                <Button
                  onClick={() => {
                    setData({ qrLoaded: false });
                    getQrKey(setData);
                  }}
                  variant="contained"
                  color="primary"
                  className={classes.refreshButton}
                >
                  {t('SIGN.IN_BY_QR_REFRESH')}
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default SignInQr;
