import React, { useEffect } from 'react';

import { CircularProgress } from '@mui/material';

// styles
import useStyles from './styles';

// context
import { useUserStateDispatch } from '../../context/UserContext';

import { loginDoctor, authDataDoctor } from '../../actions/user';

//components
import Button from '@mui/material/Button';
import AutocompleteCommon from '../../components/FormComponents/AutocompleteCommon';

//form func
import useForm from '../../hooks/useForm';
import validate from './validationSignInMedDepId';

//i18n
import { useTranslation } from 'react-i18next';
import isEmpty from '../../helpers';
import { useLanguageValue } from '../../context/LanguageContext';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function SignInConfirm() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { languageState } = useLanguageValue();
  // global
  const {
    userDispatch,
    userState: { loading, doctor, appInfo },
  } = useUserStateDispatch();
  const medDepList = doctor?.medDepList,
    login = doctor?.login,
    password = doctor?.password,
    clinicId = doctor?.clinicId,
    isTwoFactorAuth = doctor?.isTwoFactorAuth,
    authToken = doctor?.authToken;

  const loginMedDep = () => {
    if (isTwoFactorAuth === false && authToken != null) {
      authDataDoctor(userDispatch, languageState.language, clinicId);
    }
    loginDoctor(
      userDispatch,
      login,
      password,
      clinicId,
      values?.medDepId,
      languageState.language,
    );
  };

  const { values, errors, handleSubmit, setValues } = useForm(
    loginMedDep,
    validate,
    appInfo,
  );

  useEffect(() => {
    setValues({
      ...values,
      medDepId: medDepList[0].id,
    });
  }, []);

  const handleChangeAutocomplete = (name, id) => {
    setValues({
      ...values,
      [name]: id,
    });
  };

  return (
    <>
      {!isEmpty(medDepList) && (
        <AutocompleteCommon
          data={medDepList}
          selectedDataId={values?.medDepId}
          handleDataChange={handleChangeAutocomplete}
          name="medDepId"
          label={t('COMPONENT.MED_DEP')}
          keyId="id"
          keyPrimary="name"
          errors={errors?.medDepId}
        />
      )}

      <div className={classes.formButtons}>
        {loading ? (
          <CircularProgress
            size={26}
            className={classes.loginLoader}
          />
        ) : (
          <Button
            className={classes.summitButton}
            variant="contained"
            color="primary"
            disabled={values?.medDepId == null}
            onClick={handleSubmit}
          >
            {isTwoFactorAuth
              ? t('COMPONENT.BUTTON_GET_CONFIRM_CODE')
              : t('COMPONENT.BUTTON_NEXT')}
          </Button>
        )}
        <Button
          color="primary"
          size="large"
          className={classes.forgetButton}
          onClick={() =>
            userDispatch({
              type: 'SET_DOC_DATA',
              payload: { medDepList: [], confirmationCodeInfo: null },
            })
          }
        >
          {t('COMPONENT.BACK_ONE_STEP')}
        </Button>
      </div>
    </>
  );
}

export default SignInConfirm;
