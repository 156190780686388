import React from 'react';
import { Link } from 'react-router-dom';

import { useUserStateDispatch } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

export default function СlinicWebsiteButtonHerro() {
  const {
    userState: { appInfo },
  } = useUserStateDispatch();
  const { t } = useTranslation();

  return (
    <Button
      color="primary"
      variant="text"
      component={Link}
      to={appInfo.clinicWebsiteUrl}
      sx={{ mr: 2 }}
      target="_blank"
    >
      {t('COMPONENT.REFER_BUTT')}
    </Button>
  );
}
