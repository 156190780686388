import React, { useEffect } from 'react';
import { Link, useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import { LeftPlace } from '../Wrappers/Wrappers';
import UserAvatarCard from '../UserAvatar/UserAvatarCard';
import Photo from '../UserAvatar/Photo';
import classNames from 'classnames';
import config from '../../config';
import { useUserStateDispatch } from '../../context/UserContext';
import { defPlaceCalypsoForm } from '../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  item: {
    color: `${theme.palette.sideBar.colorLeft} !important`,
    '&:hover, &:focus': {
      color: `${theme.palette.sideBar.colorHover} !important`,
      backgroundColor: 'transparent',
    },
  },
  activeItem: {
    color: `${theme.palette.sideBar.colorActive} !important`,
  },

  menuItem: {
    borderBottom: `1px solid ${theme.palette.bgLight.one}`,
    padding: 0,
    '&:last-child': {
      borderBottom: 0,
    },
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
  },
  menuButton: {
    justifyContent: 'start',
    padding: `${theme.spacing(1.6)} ${theme.spacing(1)} !important`,
    width: '100%',
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
  },
  menuLink: { textDecoration: 'none' },
  conteiner: {
    margin: theme.spacing(1),
  },
}));
/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const Item = ({ item, isAuthenticated }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const to = config.accessLink(isAuthenticated, item.link);
  const match = useMatch({
    path: to,
    end: to.length === 1,
  });
  const isActiveClassName = () => {
    return match ? classes.activeItem : classes.item;
  };

  return (
    <MenuItem className={classes.menuItem}>
      <Button
        component={Link}
        to={to}
        className={classNames(
          classes.menuButton,
          isActiveClassName(),
        )}
      >
        {item.title != null ? item.title : t(`MAINMENU.${item.t}`)}
      </Button>
    </MenuItem>
  );
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

function LeftMenu({ structure, isProfile = false }) {
  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  );
  const classes = useStyles();
  const {
    userState: { isAuthenticated, appInfo, calypsoData },
  } = useUserStateDispatch();

  useEffect(() => {
    if (!appInfo.isFaqEnabled)
      structure.find(
        (item) => item.link === 'faq',
      ).hasLeftMenu = false;
    //defPlaceCalypsoForm(appInfo, calypsoData, structure);
  }, []);

  return (
    <LeftPlace>
      {!isProfile ? <UserAvatarCard /> : <Photo />}
      {!isMobile && (
        <MenuList className={classes.conteiner}>
          {structure
            .filter((item) => item.hasLeftMenu === true)
            .map((item) => (
              <Item
                key={item.id}
                item={item}
                isAuthenticated={isAuthenticated}
              />
            ))}
        </MenuList>
      )}
    </LeftPlace>
  );
}
export default LeftMenu;
