import React from 'react';

import SignInForm from '../../components/Forms/SignInForm';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

//import EsiaLink from './EsiaLink';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function SignIn({
  setActiveTabId,
  values,
  errors,
  handleChange,
  setValues,
  setErrors,
  validate,
  handleSubmit,
  isLoading,
  serverResponse,
}) {
  // global
  const { t } = useTranslation();

  return (
    <>
      <Typography
        variant="h4"
        style={{ textAlign: 'center', marginBottom: 24 }}
      >
        {t('SIGN.IN_BUTTON')}
      </Typography>
      <SignInForm
        values={values}
        errors={errors}
        serverResponse={serverResponse}
        handleChange={handleChange}
        setValues={setValues}
        setErrors={setErrors}
        validate={validate}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        submitButtonWidth={'100%'}
      />
      <Button
        color="secondary"
        variant="contained"
        onClick={() => setActiveTabId(1)}
        style={{ width: '100%' }}
      >
        {t('SIGN.UP_BUTTON')}
      </Button>
      <Button
        color="primary"
        variant="text"
        style={{ width: '50%', marginTop: 24 }}
        onClick={() => {
          setActiveTabId(2);
        }}
      >
        {t('SIGN.CH_PASS_BUTTON')}
      </Button>

      <Button
        color="primary"
        variant="text"
        onClick={() => setActiveTabId(100)}
        style={{ width: '50%', marginTop: 24 }}
      >
        {t('COMPONENT.BACK_ONE_STEP')}
      </Button>
    </>
  );
}

export default SignIn;
