import logoHero from '../images/logo_hero_mediadoc.svg';
import { ReactComponent as LogoHeader } from '../images/logo_mediadoc.svg';
import heroImg from '../images/heroImgPatient.svg';
import logoOnly from '../images/logo_only.svg';

import alterDone from '../images/appointment_done.svg';

import checkMark from '../images/check_mark.svg';
import mmkNoRecords from '../images/mmk_no_records.svg';
import alterCallDrDone from '../images/call_dr_done.svg';
import { ReactComponent as AppStoreIcon } from '../images/appStore.svg';
import { ReactComponent as GooglePlayIcon } from '../images/googlePlay.svg';
import { ReactComponent as RuStoreIcon } from '../images/ruStoreBlack.svg';

//import call from '../images/call_doc.svg';
import favorites from '../images/favorites.svg';
import mmk from '../images/first_button_mmk.svg';
//import phone from '../images/call.svg';
import visit from '../images/visit.svg';
import clinic from '../images/clinic.svg';

const custom = {
  defTitle: 'MediaDoc.net',
  AppStoreIcon,
  GooglePlayIcon,
  RuStoreIcon,
  logoHero,
  LogoHeader,
  logoOnly,
  heroImg,
  alterDone,
  alterCallDrDone,
  mmkNoRecords,
  checkMark,

  availableLanguages: 'EN,FR',
  countryCode: 'FR',

  firstButtons: { favorites, mmk, visit, clinic },
  showLoginDoctorButton: true,
  authMenuItems: [
    'home',
    'events_and_visits',
    'mmk',
    'invoices',
    'directions',
    'drugs',
    'map',
  ],

  forPet: false,
  paidIsBowserWin: false,
  unAuthMenuItems: ['home', 'map'],
  isSimpleRegistrationForm: false,
  //email | phone | both
};
export default custom;
