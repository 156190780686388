import React, { useMemo, useState } from 'react';
import {
  Alert,
  Button,
  CircularProgress,
  Fade,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField as Input,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import isEmpty from '../../helpers';
import ButtonEsiaLink from '../../pages/sign_in_up/ButtonEsiaLink';
import { useUserStateDispatch } from '../../context/UserContext';
import { useLanguageValue } from '../../context/LanguageContext';
import { handlePhoneChange } from '../../helpers/handlers';
import { isValidPhone } from '../../helpers/validators';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    height: 46,
    textTransform: 'none',
    width: '100%',
  },
  errorMessage: {
    //textAlign: 'center',
    marginBottom: theme.spacing(4),
    width: '100%',
  },
  formButtons: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  loginLoader: {
    marginLeft: theme.spacing(4),
  },

  passwordError: {
    color: theme.palette.warning.error,
  },
}));

export default function SignInForm({
  values,
  errors,
  serverResponse,
  handleChange,
  setValues,
  setErrors,
  validate,
  handleSubmit,
  isLoading,
  submitButtonWidth,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    userState: { appInfo },
  } = useUserStateDispatch();
  const {
    languageState: { language },
  } = useLanguageValue();
  const loginLabel = () => {
    switch (appInfo.authIdentifiers) {
      case 'email': {
        return t('COMPONENT.FORM_EMAIL');
      }
      case 'phone': {
        return t('COMPONENT.FORM_PHONE');
      }
      default: {
        return t('COMPONENT.FORM_EMAIL_OR_PHONE');
      }
    }
  };

  const [visibilePass, setVisibilePass] = useState(false);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return useMemo(
    () => (
      <>
        <Fade
          in={!!serverResponse}
          style={
            serverResponse == ''
              ? { display: 'none' }
              : { display: 'flex' }
          }
        >
          <Alert severity="error" className={classes.errorMessage}>
            <Typography variant="h6">
              {serverResponse || t('SIGN.IN_ERRORS')}
            </Typography>
          </Alert>
        </Fade>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <Input
              name="login"
              variant="outlined"
              value={values.login || ''}
              onBlur={(e) =>
                appInfo.authIdentifiers !== 'phone' &&
                isValidPhone(e.target.value, appInfo.countryCode) &&
                handlePhoneChange(
                  e,
                  values,
                  setValues,
                  setErrors,
                  validate,
                  appInfo,
                )
              }
              onChange={(e) =>
                appInfo.authIdentifiers === 'phone'
                  ? handlePhoneChange(
                      e,
                      values,
                      setValues,
                      setErrors,
                      validate,
                      appInfo,
                    )
                  : handleChange(e)
              }
              margin="normal"
              label={loginLabel()}
              type="text"
              fullWidth
              required
              error={values.login != null && errors?.login != null}
              helperText={
                values.login != null &&
                errors?.login != null &&
                errors?.login
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password">
                {t('COMPONENT.FORM_PASS')}
              </InputLabel>
              <OutlinedInput
                name="password"
                autoComplete="off"
                variant="outlined"
                value={values.password || ''}
                onChange={handleChange}
                label={t('COMPONENT.FORM_PASS')}
                type={visibilePass ? 'text' : 'password'}
                required
                error={errors?.password != null}
                onKeyDown={(e) => {
                  if (
                    errors?.password == null &&
                    values.password !== '' &&
                    e.key === 'Enter'
                  ) {
                    handleSubmit();
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setVisibilePass(!visibilePass)}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {!visibilePass ? (
                        <VisibilityOffIcon color="primary" />
                      ) : (
                        <VisibilityIcon color="primary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText className={classes.passwordError}>
                {errors?.password != null && errors?.password}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.formButtons}>
          {isLoading ? (
            <CircularProgress
              size={26}
              className={classes.loginLoader}
            />
          ) : (
            <>
              <Button
                disabled={
                  values.login == null ||
                  values.password == null ||
                  !isEmpty(errors)
                }
                onClick={handleSubmit}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
                variant="contained"
                color="primary"
                className={classes.submitButton}
                style={{ width: submitButtonWidth }}
              >
                {t('SIGN.IN_BUTTON')}
              </Button>
              {appInfo.showEsiaAuth && <ButtonEsiaLink />}
            </>
          )}
        </div>
      </>
    ),

    [
      language,
      values,
      errors,
      visibilePass,
      serverResponse,
      isLoading,
    ],
  );
}
