import { useState } from 'react';
import { useVisitsState } from '../../context/VisitsContext';
import isEmpty from '../../helpers';

const useModalChatVisit = () => {
  const { setState } = useVisitsState();

  const [modalChatVisit, setModalChatVisit] = useState(false);

  const toggleModalChatVisit = (visit) => {
    if (!isEmpty(visit) && !modalChatVisit) {
      setState({
        type: 'SET_VISIT',
        payload: visit,
      });
      setModalChatVisit(true);
    }
    if (modalChatVisit) {
      setModalChatVisit(false);
      setState({
        type: 'RESET_VISIT',
      });
    }
  };
  return { modalChatVisit, setModalChatVisit, toggleModalChatVisit };
};
export default useModalChatVisit;
