import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import cn from 'classnames';
import {
  dateToHL7,
  HL7ToDateToSrtDate,
} from '../../helpers/dateFormat';
import isEmpty from '../../helpers';
import { useTranslation } from 'react-i18next';
import DirectionsItemFoldSvg from './DirectionsItemFoldSvg';
import { Alert } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  borderRadiusRight: {
    borderRadius: '0 6px 6px 0',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0 0 6px 6px',
    },
  },
  borderRadiusLeft: {
    borderRadius: '6px 0 0 6px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '6px 6px 0 0',
    },
  },
  ifMobileThenHide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  item: {
    backgroundColor: theme.palette.bgLight.one,
    padding: theme.spacing(2),
  },
  itemFolder: {
    alignSelf: 'center',
    justifySelf: 'stretch',
    textAlign: 'center',
    height: '100%',
  },

  itemHeight100: {
    height: '100%',
  },
  itemLast: {
    textAlign: 'right',
  },
  itemGep: {
    width: 2,
    height: 30,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 1,
  },

  LocationOnIcon: {
    top: 5,
    position: 'relative',
    color: theme.palette.primary.light,
  },
  marginBottom: { marginBottom: theme.spacing(1) },
  btnDoc: {
    padding: `0  0 ${theme.spacing(1)} 0`,
    textAlign: 'left',
  },
  Accordion: {
    marginTop: 4,
    border: 0,
    backgroundColor: 'inherit',
    boxShadow: 'none',
    padding: 0,
    '&::before': {
      display: 'none',
    },
    '& .MuiAccordionSummary-root': {
      minHeight: 22,
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      color: theme.palette.primary.light,
      fontWeight: 500,
      flexWrap: 'wrap',
    },
    '& .MuiAccordionDetails-root ': {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      display: 'block',
    },
    '& .MuiIconButton-root': {
      padding: 0,
      color: theme.palette.primary.light,
    },
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function DirectionsItem({
  item,
  clinics,
  lang,
  toggleModalDoc,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const format =
    lang === 'ru' ? 'dd.MM.YYYY HH:mm' : 'MM/dd/YYYY HH:mm';
  const getClinicsName = (clinicId) =>
    clinics.find((it) => it.id === clinicId)?.name;
  const getClinicsAddress = (clinicId) =>
    clinics.find((it) => it.id === clinicId)?.address;
  const [expanded, setExpanded] = useState(false);

  const handleChange = (id) => (event, isExpanded) => {
    setExpanded(isExpanded ? `panel${id}` : false);
  };
  const dateNow = new Date();
  const visitDate = dateToHL7(dateNow);

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.marginBottom}
    >
      <Grid
        item
        xs={12}
        sm={1}
        classes={{
          root: classes.borderRadiusLeft,
        }}
        className={classes.item}
      >
        <Grid
          container
          className={classes.itemHeight100}
          alignItems="center"
        >
          <Grid item>
            <DirectionsItemFoldSvg />
          </Grid>
        </Grid>
      </Grid>

      <Grid item className={classes.item} xs={12} sm={3}>
        <Typography variant="h6" className={classes.marginBottom}>
          {item.name}
        </Typography>
        <Typography variant="subtitle2">
          {item.beginDate &&
            `${t('DIRECTIONS.DATE_TILL')} ${HL7ToDateToSrtDate(
              item.beginDate,
              lang,
              format,
            )}`}{' '}
          {item.endDate &&
            `${t('DIRECTIONS.DATE_UNTILL')} ${HL7ToDateToSrtDate(
              item.endDate,
              lang,
              format,
            )}`}
        </Typography>
      </Grid>
      <Grid item className={classes.item} xs={12} sm={5}>
        {!isEmpty(item.plSubjClinic) &&
        !isEmpty(item.plSubjClinic[0].plSubj) ? (
          item.plSubjClinic.map((clItem) => (
            <Accordion
              key={clItem.clinicId}
              defaultExpanded={false}
              classes={{
                root: classes.Accordion,
              }}
              expanded={expanded === `panel${clItem.clinicId}`}
              onChange={handleChange(clItem.clinicId)}
            >
              <AccordionSummary
                classes={{
                  root: classes.Accordion,
                }}
                expandIcon={<ExpandMoreIcon />}
              >
                {
                  <>
                    <Typography>
                      {getClinicsName(item.clinicId)}
                    </Typography>
                    <Typography variant="subtitle2">
                      <LocationOnIcon
                        className={classes.LocationOnIcon}
                      />
                      {getClinicsAddress(item.clinicId)}
                    </Typography>
                  </>
                }
              </AccordionSummary>
              <AccordionDetails>
                {clItem.plSubj &&
                  clItem.plSubj
                    .filter((ite) => ite.doctorId != null)
                    .map((pl) => (
                      <div key={pl.doctorId}>
                        <Button
                          color="primary"
                          variant="outlined"
                          className={classes.btnDoc}
                          onClick={() =>
                            toggleModalDoc(
                              {
                                doctor: {
                                  ...pl?.doctor,
                                },
                              },
                              {
                                visitKindId: item.visitKindId,
                                visitKindName: item.visitKindName,
                                isDefaultWeb: item?.isDefaultWeb,
                              },
                              visitDate,
                              item.id, //patientDirectionId
                            )
                          }
                        >
                          {pl.plSubjName}
                        </Button>
                      </div>
                    ))}
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Alert severity="warning">
            <Typography variant="h6" component="p">
              {t('EMPTY_DATA.NO_DIR_DOCS')}
            </Typography>
          </Alert>
        )}
      </Grid>
      <Grid
        item
        classes={{
          root: cn(classes.item, classes.borderRadiusRight),
        }}
        xs={12}
        sm
      >
        <Typography variant="h6" style={{ marginBottom: 4 }}>
          {item.doctorName}
        </Typography>
      </Grid>
    </Grid>
  );
}
