import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Menu,
  MenuItem,
  IconButton,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import {
  signOut,
  changeUser,
  downloadPhoto,
} from '../../actions/user';

import Avatar from '@mui/material/Avatar';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import DevicesIcon from '@mui/icons-material/Devices';
import SettingsIcon from '@mui/icons-material/Settings';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { useTranslation } from 'react-i18next';
import { useUserStateDispatch } from '../../context/UserContext';
import config from '../../config';
import isEmpty from '../../helpers';

const useStyles = makeStyles((theme) => ({
  headerMenu: {
    //marginTop: theme.spacing(7),
  },
  headerMenuItem: {
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  avatarProfile: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  },
  avatarMemuItem: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },

  headerFio: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    color: '#fff',
  },

  profileMenu: {
    minWidth: 265,
  },

  profileMenuItem: {
    color: theme.palette.primary.main,
  },
  profileMenuIcon: {
    '&.MuiSvgIcon-root:hover': {
      color: '#fff',
    },
  },
  menuIcon: {
    width: theme.spacing(4),
    marginRight: theme.spacing(1.6),
    textAlign: 'center',
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const AvatarUser = ({
  authToken,
  mmkId,
  fio,
  cs,
  photo,
  userDispatch,
}) => {
  const [photoData, setPhotoData] = useState({
    photo,
    isLoaded: true,
  });

  useEffect(() => {
    if (photoData.photo == null && photoData.isLoaded) {
      const photoUrl = `${config.baseURLApi}user/photo/?mmkId=${mmkId}`;
      downloadPhoto({
        setPhotoData,
        photoUrl,
        appCode: config.APP_CODE,
        authToken,
        userDispatch,
        mmkId,
      });
    }
  }, [photoData.photo]);
  //

  const src =
    photoData.photo == null
      ? ''
      : photoData.photo.includes('base64')
      ? photoData.photo
      : `data:image/jpeg;base64,${photoData.photo}`;
  return photoData.isLoaded ? (
    <Avatar alt={fio} src={src} classes={{ root: cs }} />
  ) : (
    <CircularProgress size={26} color="secondary" />
  );
};

export default function UsersMenu() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  );

  const classes = useStyles();

  const navigate = useNavigate();
  const [profileMenu, setProfileMenu] = useState(null);

  const {
    userState: {
      user: {
        lastName,
        firstName,
        middleName,

        photo,
        mmkId,
      },
      appInfo,
      mmkLinkedList,
      authToken,
    },
    userDispatch,
  } = useUserStateDispatch();

  const fio = `${lastName ?? ''} ${firstName ?? ''} ${
    middleName ?? ''
  }`;

  const handleListItemClick = (mmkId, clinicId) => {
    changeUser({
      dispatch: userDispatch,
      mmkId,
      clinicId,
      mmkLinkedList,
      isAnonymousChildrenEnabled: appInfo?.isAnonymousChildrenEnabled,
    });
  };

  const AddMenuItems = () =>
    isMobile ? (
      <>
        <MenuItem
          className={classNames(
            classes.profileMenuItem,
            classes.headerMenuItem,
          )}
          onClick={() => {
            setProfileMenu(false);
            navigate('/app/profile');
          }}
        >
          <Box className={classes.menuIcon}>
            <PersonOutlineIcon className={classes.profileMenuIcon} />
          </Box>
          <Box>{t('MAINMENU.PROFILE')}</Box>
        </MenuItem>
        <MenuItem
          className={classNames(
            classes.profileMenuItem,
            classes.headerMenuItem,
          )}
          onClick={() => {
            setProfileMenu(false);
            navigate('/app/sessions');
          }}
        >
          <Box className={classes.menuIcon}>
            <DevicesIcon className={classes.profileMenuIcon} />
          </Box>
          <Box>{t('MAINMENU.USER_SESSIONS')}</Box>
        </MenuItem>
        {appInfo.isFaqEnabled && (
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
            onClick={() => {
              setProfileMenu(false);
              navigate('/app/faq');
            }}
          >
            <Box className={classes.menuIcon}>
              <LiveHelpIcon className={classes.profileMenuIcon} />
            </Box>
            <Box>{t('MAINMENU.FAQ')}</Box>
          </MenuItem>
        )}
      </>
    ) : (
      <MenuItem
        className={classNames(
          classes.profileMenuItem,
          classes.headerMenuItem,
        )}
        onClick={() => {
          setProfileMenu(false);
          navigate('/app/profile');
        }}
      >
        <Box className={classes.menuIcon}>
          <SettingsIcon className={classes.profileMenuIcon} />
        </Box>
        <Box>{t('MAINMENU.SETTINGS')}</Box>
      </MenuItem>
    );

  return (
    <>
      <Box className={classes.headerFio}>
        <Typography variant="h6">{fio}</Typography>
      </Box>

      <IconButton
        aria-haspopup="true"
        color="inherit"
        aria-controls="profile-menu"
        onClick={(e) => setProfileMenu(e.currentTarget)}
      >
        <Avatar
          alt={fio}
          src={`data:image/jpeg;base64,${photo}`}
          classes={{ root: classes.avatarProfile }}
        />
      </IconButton>

      <Menu
        id="profile-menu"
        open={Boolean(profileMenu)}
        anchorEl={profileMenu}
        onClose={() => setProfileMenu(null)}
        className={classes.headerMenu}
        classes={{ paper: classes.profileMenu }}
        disableAutoFocusItem
      >
        {!isEmpty(mmkLinkedList) &&
          mmkLinkedList
            //.filter((item) => item.number !== mmkId) // исключить выбранного
            .map((item) => (
              <MenuItem
                key={item.number}
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
                onClick={() =>
                  handleListItemClick(item.number, item.clinicId)
                }
              >
                <Box className={classes.menuIcon}>
                  <AvatarUser
                    authToken={authToken}
                    mmkId={item.number}
                    fio={item.name}
                    cs={classes.avatarMemuItem}
                    photo={item.photo}
                    userDispatch={userDispatch}
                  />
                </Box>
                <Box>
                  {item.name}
                  {/* <Typography  variant="body2">
                    {clinicName(item.clinicId)}
                  </Typography> */}
                </Box>
              </MenuItem>
            ))}

        <AddMenuItems />

        <MenuItem
          className={classNames(
            classes.profileMenuItem,
            classes.headerMenuItem,
          )}
          onClick={() => signOut(userDispatch, navigate, '/')}
        >
          <Box className={classes.menuIcon}>
            <ExitToAppIcon className={classes.profileMenuIcon} />
          </Box>
          <Box>{t('MAINMENU.SIGNOUT')}</Box>
        </MenuItem>
      </Menu>
    </>
  );
}
