import { Dispatch } from 'react';

// export type CalypsoAuthIn = {
//   formUUID: string;
//   clinicId?: number;
//   mmkId?: string;
// };

export type Calypso = {
  formUUID: string;
  title: string;
  icon: string;
  control: string;
  position: number;
  address?: string;
  accessToken?: string;
  refreshToken?: string;
};

export type State = {
  calypso: {
    isLoaded: boolean;
    data: Calypso | null;
    responseError: string | null;
  };
};

export enum ActionType {
  fetch = 'FETCH_CALYPSO',
  reset = 'REST_CALYPSO',
  error = 'FETCH_CALYPSO_ERROR',
}

export type ActionStringPayload = {
  type: ActionType.error;
  payload: string;
};

export type ActionObjectPayload = {
  type: ActionType.fetch | ActionType.reset;
  payload: Calypso;
};

export type Action = ActionStringPayload | ActionObjectPayload;

export type ContextState = {
  state: State;
  setState: Dispatch<Action>;
};
