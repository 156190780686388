import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Layout from '../components/Layout/Layout';

/* pages */
import BlankCovoid from '../pages/blank codiv';
import Home from '../pages/home';
import Visit from '../pages/visit';
import Map from '../pages/map';
import Error from '../pages/error/Error';
import SignDoc from '../pages/sign_in_up_doc';
import Esia from '../pages/esia';
import Sign from '../pages/sign_in_up';
import Certs from '../pages/certs';

/** ======== Public Routes =========== */
export default function PubliceRoutesPatient() {
  return (
    <Routes>
      <Route
        path="/showcase/:guid/:clinicCode/:lang"
        element={<BlankCovoid />}
      />
      <Route
        path="/showcase/:guid/:clinicCode"
        element={<BlankCovoid />}
      />

      <Route path="/sign" element={<Sign />} />
      <Route path="/" element={<Layout />}>
        <Route path="home" element={<Home />} />
        <Route
          path="visit/:specIdParam/:clinicId/:isOnlineParam/:fioParam/:favPram"
          element={<Visit />}
        />
        <Route
          path="visit/:specIdParam/:clinicId/:isOnlineParam/:fioParam"
          element={<Visit />}
        />
        <Route
          path="visit/:specIdParam/:clinicId/:isOnlineParam"
          element={<Visit />}
        />
        <Route
          path="visit/:specIdParam/:clinicId"
          element={<Visit />}
        />
        <Route path="visit/:specIdParam" element={<Visit />} />
        <Route path="visit" element={<Visit />} />
        <Route path="map" element={<Map />} />
        <Route path="certs" element={<Certs />} />
        <Route path="*" element={<Error />} />
      </Route>
      <Route path="/docsign" element={<SignDoc />} />
      <Route path="/esia" element={<Esia />} />
    </Routes>
  );
}
