import React, { useEffect } from 'react';

import { CircularProgress, TextField as Input } from '@mui/material';

// styles
import useStyles from './styles';

// context
import { useUserStateDispatch } from '../../context/UserContext';

import { getClinics, loginDoctor } from '../../actions/user';

//components
import Button from '@mui/material/Button';
import AutocompleteCommon from '../../components/FormComponents/AutocompleteCommon';

//form func
import useForm from '../../hooks/useForm';
import validate from './validationSignIn';

//i18n
import { useTranslation } from 'react-i18next';
import isEmpty from '../../helpers';
import { useLanguageValue } from '../../context/LanguageContext';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function SignIn({ valsTab }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { languageState } = useLanguageValue();

  // global
  const {
    userDispatch,
    userState: { loading, clinics, appInfo },
  } = useUserStateDispatch();
  const login = () =>
    loginDoctor(
      userDispatch,
      values.email,
      values.password,
      values.clinicId,
      0,
      languageState.language,
    );

  const { values, errors, handleChange, handleSubmit, setValues } =
    useForm(login, validate, appInfo);

  useEffect(() => {
    if (isEmpty(clinics)) {
      console.log('getClinics', clinics);
      getClinics(userDispatch);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(clinics))
      setValues({
        ...values,
        email: valsTab.email,
        password: valsTab.password,
        clinicId: clinics[0]?.id,
      });
  }, [clinics]);

  const handleChangeAutocomplete = (name, id) => {
    setValues({
      ...values,
      [name]: id,
    });
  };
  console.log('= loading =', loading);
  return (
    <>
      {!isEmpty(clinics) && (
        <AutocompleteCommon
          data={clinics}
          selectedDataId={values.clinicId}
          handleDataChange={handleChangeAutocomplete}
          name="clinicId"
          label={t('COMPONENT.CLINICS')}
          keyId="id"
          keyPrimary="name"
          keySecondary="address"
          errors={errors?.clinicId}
        />
      )}

      <Input
        name="email"
        variant="outlined"
        value={values.email || ''}
        onChange={handleChange}
        margin="normal"
        placeholder={t('COMPONENT.FORM_EMAIL')}
        type="email"
        fullWidth
        required
        error={errors?.email != null}
        helperText={errors?.email != null && errors?.email}
      />

      <Input
        name="password"
        variant="outlined"
        value={values.password || ''}
        onChange={handleChange}
        margin="normal"
        placeholder={t('COMPONENT.FORM_PASS')}
        type="password"
        fullWidth
        required
        error={errors?.password != null}
        helperText={errors?.password != null && errors?.password}
      />

      <div className={classes.formButtons}>
        {loading ? (
          <CircularProgress
            size={26}
            className={classes.loginLoader}
          />
        ) : (
          <Button
            className={classes.summitButton}
            variant="contained"
            color="primary"
            disabled={
              values.clinicId == null ||
              values.email == null ||
              values.password == null
            }
            onClick={handleSubmit}
          >
            {t('COMPONENT.BUTTON_NEXT')}
          </Button>
        )}
      </div>
    </>
  );
}
export default SignIn;
