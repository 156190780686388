import React, { Fragment, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Theme,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MarkdownPreview from '@uiw/react-markdown-preview';

import { Faq } from '../../types/faq';

const useStyles = makeStyles((theme: Theme) => ({
  Accordion: {
    '& .MuiAccordionSummary-content': {
      margin: 0,
      color: theme.palette.primary.main,
      fontWeight: 500,
      padding: `${theme.spacing(3)} 0`,
    },
    '& .MuiAccordionDetails-root ': {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      display: 'block',
    },
    '& .MuiIconButton-root': {
      padding: 0,
      color: theme.palette.primary.main,
    },
    '& ol,ul': {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    },
  },
  markdownPreview: {
    marginBottom: theme.spacing(5),
    '& .wmde-markdown': {
      fontFamily: 'unset',
      fontSize: 'inherit',
      color: 'inherit',
      lineHeight: 'unset',
      backgroundColor: 'unset',
      '& table tr, & table th, & table tr td': {
        backgroundColor: 'unset',
        color: 'inherit',
        fontWeight: 'inherit',
      },
      '& table th': {
        fontWeight: 450,
        border: `1px solid ${theme.palette.grey[400]}`,
      },
      '& table tr': {
        borderTop: `1px solid ${theme.palette.grey[400]}`,
      },
      '& table td': {
        border: `1px solid ${theme.palette.grey[400]}`,
      },

      '& img': {
        //filter: 'grayscale(100%)',
        //width: '52%',
        //height: 'auto',
        filter: 'drop-shadow(8px 8px 10px #e8e8e8)',
        borderRadius: theme.spacing(1),
        margin: `${theme.spacing(2)} auto`,
        display: 'block',
      },
    },
  },
}));

interface FaqCompItemProps {
  expanded: string | boolean;
  groupName: string;
  keyy: string;
  handleChange: (
    panel: string,
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  transformImageUri: (arg: string) => string;
  group: Faq[];
}

const FaqCompItem = ({
  expanded,
  groupName,
  keyy,
  handleChange,
  transformImageUri,
  group,
}: FaqCompItemProps): JSX.Element => {
  const classes = useStyles();
  const accRef = useRef<HTMLHeadingElement>(null);
  const scrollToMyRef = (accRef: HTMLHeadingElement) => {
    if (accRef != null)
      window.scrollTo({
        top: accRef.offsetTop,
        behavior: 'smooth', // optional
      });
  };

  return (
    <Accordion
      key={keyy}
      expanded={expanded === keyy}
      onChange={handleChange(keyy)}
      classes={{
        root: classes.Accordion,
      }}
    >
      <AccordionSummary
        ref={accRef}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id={`panel${keyy}bh-header`}
        onClick={() =>
          accRef.current != null && scrollToMyRef(accRef.current)
        }
      >
        <Typography
          variant="h4"
          component="h4"
          sx={{ flexShrink: 0 }}
        >
          {groupName}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {group.map((sitem: Faq) => (
          <Fragment key={sitem.medicalNetFaqId}>
            <Typography variant="h6" component="h6" mt={2} mb={1}>
              {sitem.question}
            </Typography>
            <div className={classes.markdownPreview}>
              <MarkdownPreview
                source={sitem.answer}
                transformImageUri={transformImageUri}
              />
            </div>
          </Fragment>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default FaqCompItem;
