import React, { useCallback, useState } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ErrorIcon from '@mui/icons-material/Error';
import PaymentIcon from '@mui/icons-material/Payment';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import classnames from 'classnames';
import {
  HL7ToDateToTime,
  HL7ToDateToSrtDate,
} from '../../helpers/dateFormat';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import { Button, Badge, Alert, Box } from '@mui/material';
import { useUserStateDispatch } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';

// styles
import useStyles from './visitsItemStyle';
import { useTheme } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import { getVisitKinds } from '../../actions/visits';
import isEmpty from '../../helpers';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function VisitsItem({
  visit,
  mmkId,
  showClinic,
  setState,
  toggleModalChatVisit,
  createInvoice,
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const navigate = useNavigate();

  const {
    userState: {
      clinics,
      user: { lang, isAnonymous },
      appInfo,
    },
  } = useUserStateDispatch();
  const classes = useStyles();

  const handleCancelVisit = useCallback(() => {
    setState({
      type: 'CANCEL_VISIT_CONFIRM',
      payload: {
        planningId: visit.planningId,
        clinicId: visit.clinicId,
        id: visit.id,
        mmkId,
      },
    });
  }, []);

  const handleICameVisit = useCallback(() => {
    setState({
      type: 'ICAME_VISIT_CONFIRM',
      payload: {
        clinicId: visit.clinicId,
        id: visit.id,
      },
    });
  }, []);

  const handleCreateInvoice = useCallback(() => {
    if (visit?.fmInvoiceId != null)
      navigate(
        `/app/invoices/${visit?.fmInvoiceId}/${visit.clinicId}`,
      );
    else
      setState({
        type: 'CONFIRM_CREATE_PAYMENT',
        payload: {
          planningId: visit.planningId,
          clinicId: visit.clinicId,
          id: visit.id,
          mmkId,
        },
      });
  }, [visit?.fmInvoiceId]);

  // const fio =
  //   visit.doctor?.lastName || visit.doctor?.firstName
  //     ? `${visit.doctor?.lastName} ${visit.doctor?.firstName} ${
  //         visit.doctor?.middleName ?? ''
  //       }`
  //     : visit.doctor?.plSubjName;

  const fio = visit.doctor?.plSubjName;

  const specName =
    visit.doctor?.specializationInfo || visit?.doctorSpecialization;

  const clinic = clinics.find((it) => it.id === visit.clinicId);

  // console.log('createInvoice.id', createInvoice.id, visit.id);
  const showPayVisitButton = () => {
    //values in appInfo.showPayVisitButton online,offline,all,none
    if (appInfo.showPayVisitButton === 'all') return true;
    if (
      appInfo.showPayVisitButton === 'online' &&
      visit?.isOnlineConsultation
    )
      return true;
    if (
      appInfo.showPayVisitButton === 'offline' &&
      !visit?.isOnlineConsultation
    )
      return true;
    else return false;
  };

  const [expanded, setExpanded] = useState(false);

  const handleChange = (id) => (event, isExpanded) => {
    setExpanded(isExpanded ? `panel${id}` : false);
    if (isExpanded)
      getVisitKinds({
        setState,
        id,
        clinicId: visit.clinicId,
        plExamId: visit.plExamId,
      });
  };

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="stretch"
      style={showClinic ? { marginBottom: theme.spacing(2) } : null}
    >
      <Grid item xs={12} sm={12} md={2}>
        <Grid
          container
          classes={{
            root: classes.borderRadiusLeft,
            container: classnames(
              classes.item,
              classes.itemHeight100,
              visit?.isOnlineConsultation
                ? classes.itemConf
                : classes.itemVisit,
            ),
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs>
            <Typography variant="h4" align="center">
              {visit.startDateTime &&
                HL7ToDateToSrtDate(
                  visit.startDateTime,
                  lang,
                  'DD',
                )}{' '}
            </Typography>
            <Typography align="center" variant="subtitle2">
              {visit.startDateTime &&
                HL7ToDateToSrtDate(visit.startDateTime, lang, 'MMMM')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid className={classes.ifMobileThenHide}>
        <Grid
          container
          className={classes.itemHeight100}
          justifyContent="center"
          alignItems="center"
        >
          <Grid className={classes.itemGep}></Grid>
        </Grid>
      </Grid>

      <Grid
        item
        classes={{
          root: classnames(
            classes.item,
            visit?.isOnlineConsultation
              ? classes.itemConf
              : classes.itemVisit,
          ),
        }}
        xs={12}
        sm={12}
        md={showClinic ? 3.5 : 5.5}
      >
        {visit?.isOnlineConsultation && visit?.chatInfo != null ? (
          <Button
            color="primary"
            variant="text"
            size="large"
            className={classes.buttonConf}
            onClick={() => toggleModalChatVisit(visit)}
          >
            <HeadsetMicIcon className={classes.iconConf} />
            {t('EVENTS_VISITS.TITLE_ONLINE_CONF')}
            <Badge
              badgeContent={visit?.chatInfo?.unreadCount}
              color="warning"
              className={classes.unreadMessagesCount}
            />
          </Button>
        ) : visit?.isOnlineConsultation ? (
          <Box display="flex">
            <HeadsetMicIcon className={classes.iconConf} />
            <Typography variant="h6" style={{ marginBottom: 8 }}>
              {t('EVENTS_VISITS.TITLE_ONLINE_CONF')}
            </Typography>
          </Box>
        ) : (
          <Typography variant="h6" style={{ marginBottom: 8 }}>
            {visit.isCancelled
              ? t('EVENTS_VISITS.TITLE_VISIT_CANCELED')
              : t('EVENTS_VISITS.TITLE_VISIT')}
          </Typography>
        )}

        <Typography variant="body2">{fio}</Typography>
        <Typography variant="body2">{specName}</Typography>

        <Typography variant="body2" className={classes.wrapIcon}>
          <WatchLaterIcon
            color="primary"
            className={classes.iconWatch}
          />{' '}
          {visit.startDateTime &&
            HL7ToDateToTime(visit.startDateTime)}{' '}
          — {visit.endDateTime && HL7ToDateToTime(visit.endDateTime)}
        </Typography>
        {visit.canAcceptICameMessage && (
          <div style={{ margin: '18px 0 0 0' }}>
            <Button
              color="info"
              variant="contained"
              className={classes.iCame}
              onClick={() => handleICameVisit()}
            >
              <CheckCircleIcon className={classes.iconCheck} />
              {t('COMPONENT.BUT_ICAME')}
            </Button>
          </div>
        )}
      </Grid>

      {showClinic && (
        <Grid
          item
          classes={{
            root: classnames(
              classes.item,
              visit?.isOnlineConsultation
                ? classes.itemConf
                : classes.itemVisit,
            ),
          }}
          xs={12}
          sm={12}
          md={3.4}
        >
          <Typography variant="h6">{clinic?.name}</Typography>
          <Typography variant="subtitle2" component="p">
            <LocationOnIcon className={classes.locationOnIcon} />
            {clinic?.address}
          </Typography>
          {visit.plExamName && (
            <>
              <Typography variant="h6" mt={2}>
                {t('VISIT.TYPE')}
              </Typography>
              <Typography variant="subtitle2" component="p">
                {visit.plExamName}
              </Typography>
            </>
          )}
          {visit.plExamId != null && !visit.plExamName && (
            <Accordion
              defaultExpanded={false}
              classes={{
                root: classes.Accordion,
              }}
              expanded={expanded === `panel${visit.id}`}
              onChange={handleChange(visit.id)}
            >
              <AccordionSummary
                classes={{
                  root: classes.Accordion,
                }}
                expandIcon={<ExpandMoreIcon />}
              >
                {t('VISIT.TYPE')}
              </AccordionSummary>
              <AccordionDetails>
                {!visit.visitKinds?.isLoaded && (
                  <CircularProgress size={26} />
                )}
                {visit.visitKinds?.isLoaded &&
                  visit.visitKinds?.responseRecordError != null && (
                    <Alert severity="error">
                      <Typography variant="h6" component="p">
                        {visit.visitKinds?.responseRecordError}
                      </Typography>
                    </Alert>
                  )}

                {visit.visitKinds?.isLoaded &&
                  !isEmpty(visit.visitKinds?.data?.plExamInfo) &&
                  visit.visitKinds.data.plExamInfo.map((item) => {
                    return (
                      <Typography
                        variant="body2"
                        component="p"
                        key={item.plExamId}
                      >
                        {item.name}
                      </Typography>
                    );
                  })}
              </AccordionDetails>
            </Accordion>
          )}
        </Grid>
      )}

      <Grid
        item
        classes={{
          root: classnames(
            classes.item,
            visit?.isOnlineConsultation
              ? classes.itemConf
              : classes.itemVisit,
            classes.borderRadiusRight,
          ),
        }}
        xs={12}
        sm={12}
        md={showClinic ? 3 : 3.5}
      >
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid item className={classes.itemButtons}>
            {!visit.isCancelled &&
              !visit.isPaid &&
              visit.isFutureVisit && (
                <Button
                  variant="text"
                  className={classes.cancelled}
                  onClick={() => handleCancelVisit()}
                  style={{ marginBottom: 18 }}
                >
                  <ErrorIcon className={classes.iconButt} />
                  {t('COMPONENT.BUT_CANCEL')}
                </Button>
              )}
            {!isAnonymous &&
              !visit.isCancelled &&
              !visit.isPaid &&
              showPayVisitButton() &&
              (createInvoice?.id === visit.id &&
              createInvoice?.data?.status === 2 ? (
                <LoadingButton
                  color="success"
                  variant="contained"
                  className={classes.payBut}
                  onClick={() => handleCreateInvoice()}
                  style={{ marginBottom: 18 }}
                  loading={true}
                  loadingPosition="end"
                >
                  <PaymentIcon
                    className={classes.iconButtCreateInvoice}
                  />{' '}
                  {t('VISIT.LOADING')}
                </LoadingButton>
              ) : (
                <Button
                  color={
                    visit?.fmInvoiceId != null ? 'success' : 'info'
                  }
                  variant="outlined"
                  className={classes.payBut}
                  onClick={() => handleCreateInvoice()}
                  style={{ marginBottom: 18 }}
                >
                  <PaymentIcon
                    className={classes.iconButtCreateInvoice}
                  />
                  {t(
                    visit?.fmInvoiceId != null
                      ? 'EVENTS_VISITS.PAY_INVOICE'
                      : 'VISIT.BUT_CREATE_INVOICE',
                  )}
                </Button>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
