import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Alert, Typography } from '@mui/material';
import { confirmAuth } from '../../actions/user';
import { useTranslation } from 'react-i18next';
import { useUserStateDispatch } from '../../context/UserContext';

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    //textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
}));

const Confirm = () => {
  const getPrarams = useParams();
  const { t } = useTranslation();
  const classes = useStyles();
  const [serverResponse, setServerResponse] = useState(null);
  const {
    userState: {
      user: { lang },
      appInfo,
    },
    userDispatch,
  } = useUserStateDispatch();

  useEffect(() => {
    if (getPrarams?.token != null) {
      confirmAuth(
        userDispatch,
        lang,
        setServerResponse,
        getPrarams?.token,
        appInfo.isAnonymousChildrenEnabled,
      );
    }
  }, []);

  return (
    <Alert
      severity={serverResponse === true ? 'success' : 'warning'}
      className={classes.errorMessage}
    >
      <Typography variant="h6">
        {serverResponse === true ? t('CONFIRM.OK') : t('CONFIRM.ERR')}
      </Typography>
    </Alert>
  );
};

export default Confirm;
