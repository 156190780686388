import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import Grid from '@mui/material/Grid';
import FirstButtons from './first';
import Specs from './specs';
import HeroVisits from './visits';
import Promo from './promo';

import { useTranslation } from 'react-i18next';
import { useUserStateDispatch } from '../../context/UserContext';
import isEmpty from '../../helpers';

const useStyles = makeStyles((theme) => ({
  marginHead: {
    marginBottom: theme.spacing(3),
  },
  marginMid: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  containerOnline: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(368px, 1fr))',
    gridGap: theme.spacing(3),
  },
}));

const Home = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    userState: {
      promoActions,
      appInfo,
      user: { mmkId },
    },
  } = useUserStateDispatch();

  const [isVisitsExist, setExistVisits] = useState(true);
  const isExistPromo = !isEmpty(promoActions);
  useEffect(() => {
    if (mmkId != null && !isVisitsExist != null) {
      setExistVisits(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mmkId]);

  return (
    <>
      {appInfo.showFastButtonsOnMainPage &&
        !isEmpty(appInfo.firstButtons) && <FirstButtons />}

      <Typography variant="h3" className={classes.marginMid}>
        {t('HOME.BRANCH2')}
      </Typography>
      <Specs />

      <Grid container spacing={3}>
        {isVisitsExist && mmkId != null && (
          <Grid item xs={12} sm={isExistPromo ? 6 : 12}>
            <HeroVisits
              setExistVisits={setExistVisits}
              isExistPromo={isExistPromo}
            />
          </Grid>
        )}
        {isExistPromo && (
          <Grid item xs={12} sm={isVisitsExist ? 6 : 12}>
            <Promo isVisitsExist={isVisitsExist} />
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default Home;
