import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
//import Typography from '@mui/material/Typography';
import {
  Box,
  Button,
  Grid,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import { ReactComponent as MedicineIcon } from '../../images/icons/common_medicine.svg';
import { ReactComponent as DevIcon } from '../../images/icons/common_dev.svg';
import { ReactComponent as CRMIcon } from '../../images/icons/common_crm.svg';
import { ReactComponent as GalleryIcon } from '../../images/icons/common_gallery.svg';
import { ReactComponent as EctIcon } from '../../images/icons/common_ect.svg';
import { ReactComponent as DoctorIcon } from '../../images/icons/common_doctor.svg';
import { ReactComponent as PatientIcon } from '../../images/icons/common_patient.svg';
import { ReactComponent as ClinicBranchIcon } from '../../images/icons/common_clinic_branch.svg';

import { ReactComponent as ArrowIcon } from '../../images/icons/common_arrow.svg';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => {
  return {
    containerButtons: {
      minWidth: 520,
      [theme.breakpoints.down('sm')]: {
        minWidth: 'auto',
      },
    },
    button_cur: {
      cursor: 'pointer',
    },
    button: {
      maxWidth: 520,
      boxShadow: '0px 2px 10px 0px rgba(53, 54, 56, 0.07)',
      borderRadius: theme.spacing(1.5),
      transition: 'all .3s ease-in-out',
      '& h6': {
        transition: 'all .3s ease-in-out',
      },

      '& .MuiTypography-body1': {
        transition: 'all .3s ease-in-out',
      },
      '& .arrow': {
        transition: 'all .3s ease-in-out',
      },
      '& .arrow > path': {
        transition: 'all .3s ease-in-out',
      },

      '&:hover': {
        transition: 'all .3s ease-in-out',
        boxShadow: '0px 2px 10px 0px rgba(53, 54, 56, 0.14)',
        '& h6': {
          transition: 'all .3s ease-in-out',
          color: theme.palette.primary.main,
        },

        '& .MuiTypography-body1': {
          transition: 'all .3s ease-in-out',
          transformOrigin: 'center left',
          transform: 'scaleX(0.95)',
        },
        '& .arrow': {
          transition: 'all .3s ease-in-out',
          transform: 'translate(12px, 0)',
          fill: `${theme.palette.grey[300]}`,
        },
        '& .arrow > path': {
          transition: 'all .3s ease-in-out',
          fill: `${theme.palette.grey[300]}`,
        },
      },
    },
  };
});
function searchRecursive(data, id) {
  let found = data.find((d) => d.id === id);
  if (!found) {
    let i = 0;
    while (!found && i < data.length) {
      if (data[i].sub_menu && data[i].sub_menu.length) {
        found = searchRecursive(data[i].sub_menu, id);
      }
      i++;
    }
  }
  return found;
}
const menuAll = [
  {
    icon: <MedicineIcon />,
    title: 'Medicine',
    sub_title: 'Краткое описание специализации',
    link: '',
    id: '1',
    parent: null,
    sub_menu: [
      {
        icon: <DoctorIcon />,
        title: 'Doctor',
        sub_title: '',
        link: '',
        parent: '1',
        id: '1_1',
        sub_menu: [
          {
            icon: <ClinicBranchIcon />,
            title: 'Clinik Med dep 1',
            sub_title: '',
            link: '',
            parent: '1_1',
            id: '1_1_1',
          },
          {
            icon: <ClinicBranchIcon />,
            title: 'Clinik Med dep 1',
            sub_title: '',
            link: '',
            parent: '1_1',
            id: '1_1_2',
          },
        ],
      },
      {
        icon: <PatientIcon />,
        title: 'Patient',
        sub_title: '',
        link: '/#sign',
        parent: '1',
        id: '1_2',
      },
    ],
  },
  {
    icon: <DevIcon />,
    title: 'Develop',
    sub_title: 'Краткое описание специализации',
    link: '',
    id: '2',
    parent: null,
  },
  {
    icon: <CRMIcon />,
    title: 'CRM',
    sub_title: 'Краткое описание специализации',
    link: '',
    id: '3',
    parent: null,
  },
  {
    icon: <GalleryIcon />,
    title: 'Gallery',
    sub_title: 'Краткое описание специализации',
    link: '',
    id: '4',
    parent: null,
  },
  {
    icon: <EctIcon />,
    title: 'Ect',
    sub_title: 'Краткое описание специализации',
    link: '',
    id: '5',
    parent: null,
  },
];

// eslint-disable-next-line react/prop-types
const LinkWrap = ({ link, cildCopm }) => {
  return link ? <Link href={link}>{cildCopm}</Link> : cildCopm;
};

// eslint-disable-next-line react/prop-types
const Item = ({ item, handleSetMemu, classes }) => {
  return (
    <Paper
      className={classNames(
        classes.button,
        item.sub_menu && classes.button_cur,
      )}
      onClick={() => item.sub_menu && handleSetMemu(item.id)}
    >
      <Grid
        container
        spacing={0}
        justifyContent="center"
        alignItems="center"
        py={2}
        my={3}
      >
        <Grid item xs={2} textAlign="center">
          {item.icon}
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h6">{item.title}</Typography>
          <Typography variant="body1">{item.sub_title}</Typography>
        </Grid>
        <Grid item xs={2} textAlign="center">
          <ArrowIcon className="arrow" />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default function Workspace() {
  const classes = useStyles();
  const [menu, setMenu] = useState({
    menuAct: menuAll,
    parent: undefined,
  });
  console.log('menu', menu);
  const handleSetMemu = (idAct) => {
    const item = menu.menuAct.find((it) => it.id === idAct) || [];

    setMenu({
      menuAct: item?.sub_menu,
      parent: item?.parent,
    });
  };

  const handleSetMemuPrev = () => {
    const item = searchRecursive(menuAll, menu.parent);
    setMenu({
      menuAct: item?.sub_menu || menuAll,
      parent: item?.parent,
    });
  };

  //console.log('menu', menu);

  return (
    <Grid container mt={3} width="100%">
      <Grid item xs={12} sm={2} mt={3}>
        {menu.parent !== undefined && (
          <Button
            onClick={handleSetMemuPrev}
            variant="contained"
            color="secondary"
          >
            dos
          </Button>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        justifyContent="center"
        display="flex"
      >
        <Box className={classes.containerButtons}>
          <Typography variant="h1" my={3}>
            Choisisse l&apos;orientation
          </Typography>
          {(menu.menuAct || []).map((item) => (
            <LinkWrap
              key={item.id}
              link={item.link}
              cildCopm={
                <Item
                  item={item}
                  handleSetMemu={handleSetMemu}
                  classes={classes}
                />
              }
            />
          ))}
        </Box>
      </Grid>
      <Grid item xs={12} sm={2}></Grid>
    </Grid>
  );
}
