import React, { useState } from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import {
  useLanguageValue,
  LANGUAGES_ACTIONS,
  LANGUAGES,
} from '../../context/LanguageContext';
// context
import { useUserStateDispatch } from '../../context/UserContext';

const useStyles = makeStyles((theme) => ({
  headerMenu: {
    marginTop: theme.spacing(1),
  },

  headerMenuItem: {
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },

  languagesMenu: {
    minWidth: 265,
  },
  languagesMenuItem: {
    color: theme.palette.primary.main,
  },

  text: {
    textTransform: 'uppercase',
  },
}));

export default function UsersLanguageMenu() {
  const classes = useStyles();
  const { languageState, dispatchLanguage } = useLanguageValue();

  const {
    userState: { appInfo },
  } = useUserStateDispatch();

  const [languagesMenu, setLanguagesMenu] = useState(null);

  const handleOpenMenu = (e) => {
    setLanguagesMenu(e.currentTarget);
  };

  const handleChangeLang = (e, langStatus) => {
    setLanguagesMenu(null);

    switch (langStatus) {
      case LANGUAGES.RU:
        dispatchLanguage({ type: LANGUAGES_ACTIONS.SET_RUSSIAN });
        return;
      case LANGUAGES.FR:
        dispatchLanguage({ type: LANGUAGES_ACTIONS.SET_FRENCH });
        return;
      case LANGUAGES.EN:
        dispatchLanguage({ type: LANGUAGES_ACTIONS.SET_ENGLISH });
        return;
      default:
        dispatchLanguage({ type: LANGUAGES_ACTIONS.SET_RUSSIAN });
    }
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-haspopup="true"
        aria-controls="mail-menu"
        onClick={handleOpenMenu}
      >
        <Typography className={classes.text}>
          {languageState.language}
        </Typography>
      </IconButton>

      <Menu
        id="profile-menu"
        open={Boolean(languagesMenu)}
        anchorEl={languagesMenu}
        onClose={() => setLanguagesMenu(null)}
        className={classes.headerMenu}
        classes={{ paper: classes.languagesMenu }}
        disableAutoFocusItem
      >
        {Object.keys(LANGUAGES)
          .filter((it) => appInfo.availableLanguages.includes(it))
          .map((key) => (
            <MenuItem
              key={key}
              className={classNames(
                classes.languagesMenuItem,
                classes.headerMenuItem,
              )}
              onClick={(event) =>
                handleChangeLang(event, LANGUAGES[key])
              }
            >
              <Typography className={classes.text}>
                {LANGUAGES[key]}
              </Typography>
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}
