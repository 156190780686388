import React, { useReducer, useContext, createContext } from 'react';
import { savedEntites } from '../actions/user';
import config from '../config';
import isEmpty from '../helpers';
const UserContext = createContext();

function userReducer(state, action) {
  switch (action.type) {
    case 'LOGIN_PATIENT':
      return { ...state, ...action.payload, isAuthenticated: true };

    case 'SIGN_OUT_SUCCESS':
      console.log(' ==== SIGN_OUT_SUCCESS === ');
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticatedDoctor: false,
        serverResponse: null,
        user: {},
        doctor: {},
      };
    case 'SIGN_UP_AGREEMENT':
      return { ...state, ...action.payload };
    case 'SET_USER':
      return {
        ...state,
        ...action.payload,
      };

    case 'SET_DICTS': {
      console.log('\n === DICTS ==== \n', action.payload);
      // if Online visits false
      // remove OnlineConsultation, isDirectionsEnabled, NewAssignments
      // values from notificationsTypes

      let { notificationsTypes } = action.payload;
      if (
        !isEmpty(action.payload?.appInfo) &&
        action.payload.appInfo.showFilterOnline != null &&
        !isEmpty(action.payload?.notificationsTypes) &&
        !action.payload.appInfo.showFilterOnline
      ) {
        notificationsTypes = notificationsTypes.filter(
          (item) => item.name !== 'OnlineConsultation',
        );
      }

      if (
        !isEmpty(action.payload?.appInfo) &&
        action.payload.appInfo.isDirectionsEnabled != null &&
        !isEmpty(action.payload?.notificationsTypes) &&
        !action.payload.appInfo.isDirectionsEnabled
      ) {
        notificationsTypes = notificationsTypes.filter(
          (item) => item.name !== 'NewDirections',
        );
      }

      if (
        !isEmpty(action.payload?.appInfo) &&
        action.payload.appInfo.isPrescribedDrugsEnabled != null &&
        !isEmpty(action.payload?.notificationsTypes) &&
        !action.payload.appInfo.isPrescribedDrugsEnabled
      ) {
        notificationsTypes = notificationsTypes.filter(
          (item) => item.name !== 'NewAssignments',
        );
      }

      delete action.payload.notificationsTypes;
      return {
        ...state,
        ...{ ...action.payload, notificationsTypes, isLoaded: true },
      };
    }

    case 'SET_USER_PHOTO':
      return {
        ...state,
        ...action.payload,
        mmkLinkedList: state.mmkLinkedList.map((item) =>
          item.number === action.payload.user.mmkId
            ? { ...item, photo: action.payload.user.photo }
            : item,
        ),
      };

    case 'SET_MMK_LIST_PHOTO':
      return {
        ...state,
        mmkLinkedList: state.mmkLinkedList.map((item) =>
          item.number === action.payload.mmkId
            ? { ...item, photo: action.payload.photo }
            : item,
        ),
      };

    case 'SET_MMK_LINKED_LIST': {
      const mmkLinkedList = [
        ...new Set(action.payload.map((obj) => obj.number)),
      ].map((number) =>
        action.payload.find((obj) => obj.number === number),
      );

      localStorage.setItem(
        'mmkLinkedList',
        JSON.stringify(mmkLinkedList),
      );

      return {
        ...state,
        mmkLinkedList,
        isLoaded: true,
      };
    }

    case 'SET_PROFILE_CODE_WRONG':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.user,
        },
        serverResponse: action.payload.serverResponse,
        isLoaded: true,
      };

    case 'LOGIN_DOCTOR':
      localStorage.setItem(
        'doctor',
        JSON.stringify({
          ...action.payload.doctor,
        }),
      );
      localStorage.setItem(
        'user',
        JSON.stringify({
          ...action.payload.user,
        }),
      );
      localStorage.setItem(
        'mmkRecordTypes',
        JSON.stringify(action.payload.mmkRecordTypes),
      );
      localStorage.setItem(
        'specs',
        JSON.stringify(action.payload.specs),
      );

      return {
        ...state,
        ...action.payload,
        isAuthenticatedDoctor: true,
        loading: false,
        error: '',
      };
    case 'SET_DOC_DATA':
      return {
        ...state,
        doctor: {
          ...action.payload,
        },
        loading: false,
        error: '',
      };
    case 'SET_CLINICS': {
      const clinics = (action.payload || []).filter(
        (it) => it.isVisible,
      );

      return {
        ...state,
        clinics,
        loading: false,
        error: '',
      };
    }
    case 'LOADING':
      return {
        ...state,
        loading: true,
        error: '',
      };
    case 'SET_CHAT_USER':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
        loading: false,
        error: '',
      };

    case 'SET_SERVER_RESPONSE':
      return {
        ...state,
        loading: false,
        serverResponse: action.payload,
      };
    case 'LOGIN_FAILURE':
      return {
        ...state,
        loading: false,
        serverResponse: { message: 'Something went wrong' },
      };
    case 'LOGIN_DOCTOR_FAILURE':
      return {
        ...state,
        loading: false,
        serverResponse: 'Something went wrong',
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function UserProvider({ children }) {
  const authToken = localStorage.getItem('authToken');
  const doctor = JSON.parse(localStorage.getItem('doctor')) ?? {};

  const user = JSON.parse(localStorage.getItem('user')) ?? {
    lang: config.defLang,
    clinicId: 0,
    mmkId: null,
  };

  const isAuthenticated =
    authToken != null && user?.mmkId != null && isEmpty(doctor);

  const mmkLinkedList =
    JSON.parse(localStorage.getItem('mmkLinkedList')) ?? [];

  const isAuthenticatedDoctor =
    !isAuthenticated && !!authToken && !isEmpty(doctor);

  const dicts = savedEntites.reduce((acc, cur) => {
    if (cur === 'appInfo')
      acc[cur] = JSON.parse(localStorage.getItem(cur)) ?? {};
    else acc[cur] = JSON.parse(localStorage.getItem(cur)) ?? [];
    return acc;
  }, {});
  //console.log('dicts', dicts);

  const [userState, userDispatch] = useReducer(userReducer, {
    /** user  */
    isAuthenticated,
    user,
    mmkLinkedList,
    ...dicts,
    isLoaded: true,
    serverResponse: null,
    /** doctor  */
    isAuthenticatedDoctor,
    doctor: { ...doctor, step: 0 },
    loading: false,
    error: '',

    authToken,
  });

  return (
    <UserContext.Provider value={{ userState, userDispatch }}>
      {children}
    </UserContext.Provider>
  );
}

function useUserStateDispatch() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(
      'useUserStateDispatch must be used within a UserProvider',
    );
  }
  return context;
}

export { UserProvider, useUserStateDispatch };
