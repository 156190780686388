import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import cn from 'classnames';
import Typography from '@mui/material/Typography';
import './fontRoboto/index.css';
import useStyles from './styles';

import { showcaseInfo } from '../../actions/user';
import { HL7ToDateToSrtDate } from '../../helpers/dateFormat';
import Loading from '../../components/Loading';
import isEmpty from '../../helpers';
import api from '../../api/apiParams';
import config from '../../config';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

export default function Blank() {
  const classes = useStyles();
  const { lang, guid, clinicCode } = useParams();
  const inLang = () => (lang === 'Eng' ? 'Eng' : 'Rus');
  const outLang = (l) => (l === 'Eng' ? 'Eng' : '');

  const [isLoading, setIsLoading] = useState(false);
  const [caseInfo, setCaseInfo] = useState(null);

  const reqData = {
    guid,
    clinicCode,
  };
  useEffect(() => {
    setIsLoading(true);
    showcaseInfo(reqData, setCaseInfo, setIsLoading, lang);
  }, []);

  const navigate = useNavigate();
  const setLang = (e, l) => {
    navigate(`/showcase/${guid}/${clinicCode}/${outLang(l)}`);
  };
  console.log('caseInfo', caseInfo);

  const dataByLang = (key, data = null) => {
    //const key = Object.keys(data)[0];
    if (isEmpty(caseInfo)) return null;
    data = data != null ? data : caseInfo;

    return lang === 'Eng' && data[`${key}${lang}`] != null
      ? data[`${key}${lang}`]
      : data[key];
  };
  const dateByLang = (key, format = 'DD.MM.YYYY HH:mm') => {
    if (isEmpty(caseInfo)) return null;
    return caseInfo[key] != null
      ? HL7ToDateToSrtDate(caseInfo[key], lang, format)
      : null;
  };

  const uiData = {
    birthday: 'Дата рождения',
    birthdayEng: 'Date of birth',

    card: 'Амбулаторная карта',
    cardEng: 'Medical record',

    gender: 'Пол',
    genderEng: 'Gender',

    bio: 'Биоматериал',
    bioEng: 'Sample type',

    no: 'No исследования',
    noEng: 'Request form No',

    dateGetBio: 'Дата взятия биоматериала',
    dateGetBioEng: 'Sample collection date',

    dateResult: 'Дата получения результата',
    dateResultEng: 'Date of analysis',

    param: 'Параметр',
    paramEng: 'Test',
    result: 'Результат',
    resultEng: 'Result',
    norma: 'Норма',
    normaEng: 'Reference interval',
  };

  const uiDataByLang = (key) => {
    return uiData[key] != null && lang === 'Eng'
      ? uiData[`${key}${lang}`]
      : uiData[key];
  };
  const brandUrl = `${config.baseURLApi}${api.url(
    'GetBrandLogoImage',
  )}?brandId=${caseInfo?.brandId}`;
  return (
    <div className={classes.blankCovid}>
      {isLoading ? (
        <Loading />
      ) : (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.container}
        >
          <Grid item xs={12} sm={6} className={classes.headLeft}>
            {caseInfo?.brandId != null && (
              <img
                className={classes.logo}
                src={brandUrl}
                alt="logo"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} className={classes.headRight}>
            <p className={classes.headTitle}>
              Общество с ограниченной
              ответственностью&quot;Медилюкc-ТМ&quot;119019
              <br />
              {dataByLang('name', caseInfo?.organization)},{' '}
              {dataByLang('legalAddress', caseInfo?.organization)}
              <br />
              Лицензия No{' '}
              {dataByLang('num', caseInfo?.organization?.license)},
              Тел. {dataByLang('helpPhone', caseInfo?.organization)}{' '}
              www.medswiss.ru
            </p>
          </Grid>
          <Grid item xs={12} className={classes.hr} />
          <Grid item xs={12} sm={6} className={classes.containerFio}>
            <p className={classes.textFio}>
              {dataByLang('patients')}
            </p>

            <p className={classes.textGender}>
              {uiDataByLang('gender')}:{' '}
              <strong>{dataByLang('sex')}</strong>
            </p>
            <p />
            <p className={classes.text20}>
              {uiDataByLang('birthday')}:{' '}
              {dateByLang('birthday', 'DD.MM.YYYY')}
            </p>
            <p className={classes.text20}>
              {uiDataByLang('no')}: {dataByLang('bioCode')}
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className={cn(classes.containerFio, classes.alignRight)}
          >
            <ToggleButtonGroup
              value={inLang()}
              exclusive
              onChange={setLang}
              aria-label="sweetch-langs"
              className={classes.switchLang}
            >
              <ToggleButton value={'Rus'} aria-label={'Rus'}>
                <Typography variant="body2">Рус</Typography>
              </ToggleButton>
              <ToggleButton value="Eng" aria-label={'Eng'}>
                <Typography variant="body2">Eng</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className={cn(classes.containerFio, classes.marginTop16)}
          >
            <p className={classes.text16}>
              {uiDataByLang('card')} No: {dataByLang('cardNumber')}
            </p>
            <p className={classes.text16}>
              {uiDataByLang('bio')}: {dataByLang('bioType')}
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className={cn(classes.containerFio, classes.marginTop16)}
          >
            <Grid
              container
              spacing={0}
              className={cn(
                classes.containerDateGrid,
                classes.alignRight,
              )}
            >
              <Grid
                item
                xs={7}
                sm={8}
                className={cn(
                  classes.marginTopBottom6,
                  classes.alignRight,
                )}
              >
                <p className={classes.textDateTitle}>
                  {uiDataByLang('dateGetBio')} :
                </p>
              </Grid>
              <Grid
                item
                xs={5}
                sm={4}
                className={classes.marginTopBottom6}
              >
                <p className={classes.textDateValue}>
                  {dateByLang('bioDate')}
                </p>
              </Grid>
              <Grid xs={7} sm={8} className={classes.alignRight}>
                <p className={classes.textDateTitle}>
                  {uiDataByLang('dateResult')}:
                </p>
              </Grid>
              <Grid item xs={5} sm={4}>
                <p className={classes.textDateValue}>
                  {dateByLang('resultDate')}
                </p>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.containerTitleBlank}>
            <p className={classes.textTitleBlank}>
              {dataByLang('testName', caseInfo?.details[0])}
            </p>
          </Grid>
          <Grid item xs={12} className={classes.containerTable}>
            <Grid
              container
              spacing={0}
              className={classes.tableContainer}
            >
              <Grid
                item
                xs={6}
                sm={8}
                className={classes.tableHeader}
              >
                {uiDataByLang('param')}
              </Grid>
              <Grid
                item
                xs={3}
                sm={2}
                className={classes.tableHeader}
              >
                {uiDataByLang('result')}
              </Grid>
              <Grid
                item
                xs={3}
                sm={2}
                className={classes.tableHeader}
              >
                {uiDataByLang('norma')}
              </Grid>
              {!isEmpty(caseInfo?.details) &&
                caseInfo?.details.map((item) => (
                  <React.Fragment key={item.id}>
                    <Grid
                      item
                      xs={6}
                      sm={8}
                      className={classes.tableContent}
                    >
                      {dataByLang('paramName', item)}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={2}
                      className={cn(
                        classes.tableContent,
                        classes.alignCenter,
                      )}
                    >
                      {dataByLang('resultVal', item)}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={2}
                      className={cn(
                        classes.tableContent,
                        classes.alignCenter,
                      )}
                    >
                      {dataByLang('resultReference', item)}
                    </Grid>
                  </React.Fragment>
                ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
