import React, { useEffect, useState } from 'react';

import {
  createUser,
  singInUpConfirmationCode,
  loginUser,
} from '../../actions/user';

import useForm from '../../hooks/useForm';
import { validate as validateSignUp } from '../../pages/sign_in_up/validationSignUp';
import validateConfirmSignUp from '../../pages/sign_in_up/validationConfirmSignUp';

import { dateToHL7 } from '../../helpers/dateFormat';
import SignUpForm from '../../components/Forms/SignUpForm';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUserStateDispatch } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { useLanguageValue } from '../../context/LanguageContext';
import dayjs from 'dayjs';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

function SignUp({ setValsTab, setActiveTabId }) {
  const { t } = useTranslation();

  // local
  const [isLoading, setIsLoading] = useState(false);
  const [serverResponse, setServerResponse] = useState(null);
  const {
    userState: { appInfo },
    userDispatch,
  } = useUserStateDispatch();

  const {
    languageState: { language },
  } = useLanguageValue();
  const navigate = useNavigate();

  const login = () =>
    loginUser(
      userDispatch,
      values?.login,
      values?.password,
      navigate,
      setIsLoading,
      setServerResponse,
      setValsTab,
      language,
      appInfo.isAnonymousChildrenEnabled,
    );

  const signUp = () =>
    createUser(
      {
        ...values,
        birthDate: values.birthDate
          ? dateToHL7(values.birthDate.toDate())
          : null,
      },
      setIsLoading,
      setServerResponse,
      appInfo.countryCode,
    );

  const confirmSignUp = () =>
    singInUpConfirmationCode(
      {
        ...values,
        birthDate: values.birthDate
          ? dateToHL7(values.birthDate.toDate())
          : null,
      },
      setIsLoading,
      setServerResponse,
    );
  const isConfirmForm =
    serverResponse?.action === 'CONFIRM_CODE_SENT' ||
    serverResponse?.action === 'WRONG_CONFIRMATION_CODE';

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
  } = useForm(
    isConfirmForm ? confirmSignUp : signUp,
    isConfirmForm ? validateConfirmSignUp : validateSignUp,
    appInfo,
  );

  useEffect(() => {
    if (serverResponse?.action === 'MAY_LOGIN') login();
    // eslint-disable-next-line
  }, [serverResponse?.action]);

  const handleDateChange = (birthDate) => {
    birthDate = birthDate != null ? dayjs(birthDate) : null;

    const vals = {
      ...values,
      birthDate,
    };

    setValues(vals);
    setErrors(validateSignUp(vals, appInfo));
  };

  const handleChangeGender = (event) => {
    const vals = {
      ...values,
      gender: event.target.value,
    };
    setValues(vals);
    setErrors(validateSignUp(vals, appInfo));
  };

  return (
    <>
      <Typography
        variant="h4"
        style={{ textAlign: 'center', marginBottom: 24 }}
      >
        {t('SIGN.UP_BUTTON')}
      </Typography>
      <SignUpForm
        values={values}
        handleChange={handleChange}
        setValues={setValues}
        setErrors={setErrors}
        errors={errors}
        validate={validateSignUp}
        serverResponse={serverResponse}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setServerResponse={setServerResponse}
        handleSubmit={handleSubmit}
        handleDateChange={handleDateChange}
        handleChangeGender={handleChangeGender}
      />
      <Button
        color="primary"
        variant="text"
        onClick={() => setActiveTabId(0)}
        style={{ width: '100%', marginTop: 24 }}
      >
        {t('COMPONENT.BACK_ONE_STEP')}
      </Button>
    </>
  );
}

export default SignUp;
