import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ButtonIcon } from '../../images/icons/button_in.svg';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

export default function LoginDoctorButton() {
  const { t } = useTranslation();

  return (
    <Button
      color="primary"
      variant="text"
      component={Link}
      to="/docsign"
      sx={{ ml: 2 }}
      startIcon={<ButtonIcon />}
    >
      {t('SIGN.IN_DOC_ENTER')}
    </Button>
  );
}
