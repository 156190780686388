import React from 'react';
import Modale from '../../components/Modals/Modale';
import {
  HL7ToDateToSrtDate,
  HL7ToDateToTime,
} from '../../helpers/dateFormat';
import CreateVisit from './CreateVisit';
import isEmpty from '../../helpers';
import { useVisitState } from '../../context/VisitContext';
import { useUserStateDispatch } from '../../context/UserContext';
import Sign from './Sign';
import AnonymousNotEnabled from './AnonymousNotEnabled';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function ModaleCreateVisit({
  modalCreateVisit,
  toggleModalCreateVisit,
}) {
  const {
    state: { visitData },
  } = useVisitState();
  const {
    userState: {
      isAuthenticated,
      user: { isAnonymous, lang },
      appInfo,
    },
  } = useUserStateDispatch();
  const anonymousNotEnabled =
    isAnonymous && !appInfo.isAnonymousVisitsEnabled;

  return (
    <Modale
      open={modalCreateVisit}
      toggleModal={toggleModalCreateVisit}
      title={
        isEmpty(visitData?.timeSlots) && visitData?.visitDate != null
          ? `${HL7ToDateToSrtDate(visitData?.visitDate, lang)}${
              HL7ToDateToTime(visitData?.visitDate) !== '00:00'
                ? ' ' + HL7ToDateToTime(visitData?.visitDate)
                : ''
            }${
              !isEmpty(visitData.visitKind)
                ? '. ' + visitData.visitKind?.name
                : ''
            }`
          : null
      }
      maxWidth={isAuthenticated ? 'md' : 'sm'}
      height={anonymousNotEnabled ? 220 : 740}
    >
      {isAuthenticated ? (
        anonymousNotEnabled ? (
          <AnonymousNotEnabled />
        ) : (
          <CreateVisit
            toggleModalCreateVisit={toggleModalCreateVisit}
          />
        )
      ) : (
        <Sign
          toggleModal={toggleModalCreateVisit}
          visitData={visitData}
        />
      )}
    </Modale>
  );
}
