import i18next from 'i18next';
import {
  isValidPhone,
  rexFio,
  RANGE_AGE_GROUP,
  notAvalibleBirthDate,
} from '../../helpers/validators';
import dayjs from 'dayjs';

export const MIN_BIRTH_DATE_CHILD = -100;
export const MAX_BIRTH_DATE_CHILD = 0;

export default function validate(values, _appInfo) {
  const isValidAgeGroup = (values) => {
    if (values.ageGroup === 'Any') return true;
    if (values.birthDate == null) return true;

    const age = dayjs().diff(values.birthDate, 'years');

    if (values.ageGroup === 'Adult' && age >= RANGE_AGE_GROUP)
      return true;
    if (values.ageGroup === 'Child' && age < RANGE_AGE_GROUP)
      return true;
    if (
      values.ageGroup === 'Child' &&
      age >= RANGE_AGE_GROUP &&
      values.imChildRep
    )
      return true;
    return false;
  };

  let errors = {};

  if (values.firstName != null && !values.firstName.trim()) {
    errors.firstName = i18next.t('COMPONENT.EMPTY_NAME');
  }
  if (values.lastName != null && !values.lastName.trim()) {
    errors.lastName = i18next.t('COMPONENT.EMPTY_LAST_NAME');
  }
  if (
    values.firstName != null &&
    !rexFio.test(values.firstName.trim())
  ) {
    errors.firstName = i18next.t('SIGN.UP_NOT_VALID_NAME');
  }
  if (values.lastName != null && !rexFio.test(values.lastName)) {
    errors.lastName = i18next.t('SIGN.UP_NOT_VALID_LAST_NAME');
  }

  if (values.middleName && !rexFio.test(values.middleName)) {
    errors.middleName = i18next.t('SIGN.UP_NOT_VALID_MIDDLE_NAME');
  }

  if (
    values.firstName != null &&
    values.lastName != null &&
    values.firstName.trim() === values.lastName.trim()
  ) {
    errors.firstName = i18next.t('SIGN.UP_CANNOT_BE_SAME');
    errors.lastName = i18next.t('SIGN.UP_CANNOT_BE_SAME');
  }

  if (
    values.needPhone &&
    (values.phone === null || values.phone === '')
  ) {
    errors.phone = i18next.t('COMPONENT.EMPTY_PHONE');
  } else if (
    values.needPhone &&
    !isValidPhone(values.phone, _appInfo.countryCode)
  ) {
    errors.phone = i18next.t('COMPONENT.INCORECT_PHONE');
  }

  if (!isValidAgeGroup(values) && values.isMmkLinkedList)
    errors.ageGroup = i18next.t('VISIT.AGE_GROUP_ALERT_MORE');
  if (!isValidAgeGroup(values) && !values.isMmkLinkedList)
    errors.ageGroup = i18next.t('VISIT.AGE_GROUP_ALERT');

  if (
    _appInfo.visitCustomCheck &&
    values.customCheck?.status === null
  ) {
    errors.customCheck = i18next.t('VISIT.WAITING_THE_CUSTOM_CHECK');
  }
  if (_appInfo.visitCustomCheck && values.customCheck?.status === 1) {
    delete errors.customCheck;
  }
  if (
    _appInfo.visitCustomCheck &&
    values.customCheck?.status === 2 &&
    !values.customCheck?.checked
  ) {
    errors.customCheck = values.customCheck.msgText;
  }

  if (_appInfo.visitCustomCheck && values.customCheck?.status === 3) {
    errors.customCheck = values.customCheck.msgText;
  }
  // console.log(
  //   '_appInfo.visitCustomCheck',
  //   _appInfo.visitCustomCheck,
  // );

  // ----------------------- Child --------------------
  if (values.childBirthDate === null) {
    errors.childBirthDate = i18next.t('COMPONENT.EMPTY_BIRTHDAY');
  } else if (
    values.childBirthDate !== undefined &&
    (!values.childBirthDate.isValid() ||
      notAvalibleBirthDate(values.childBirthDate, [
        MIN_BIRTH_DATE_CHILD,
        MAX_BIRTH_DATE_CHILD,
      ]))
  ) {
    errors.childBirthDate = i18next.t('COMPONENT.INVALID_BIRTH_DATE');
  }

  if (
    values.childFirstName === null ||
    (values.childFirstName !== undefined &&
      values.childFirstName.trim() === '')
  ) {
    errors.childFirstName = i18next.t('COMPONENT.EMPTY_NAME');
  }
  if (
    values.childLastName === null ||
    (values.childLastName !== undefined &&
      values.childLastName.trim() === '')
  ) {
    errors.childLastName = i18next.t('COMPONENT.EMPTY_LAST_NAME');
  }
  if (values?.childFirstName && !rexFio.test(values.childFirstName)) {
    errors.childFirstName = i18next.t('SIGN.UP_NOT_VALID_NAME');
  }
  if (values?.childLastName && !rexFio.test(values.childLastName)) {
    errors.childLastName = i18next.t('SIGN.UP_NOT_VALID_LAST_NAME');
  }

  if (
    values.childMiddleName &&
    !rexFio.test(values.childMiddleName)
  ) {
    errors.childMiddleName = i18next.t(
      'SIGN.UP_NOT_VALID_MIDDLE_NAME',
    );
  }

  if (
    values.childFirstName &&
    values.childLastName &&
    values.childFirstName.trim() === values.childLastName.trim()
  ) {
    errors.childFirstName = i18next.t('SIGN.UP_CANNOT_BE_SAME');
    errors.childLastName = i18next.t('SIGN.UP_CANNOT_BE_SAME');
  }

  if (
    values.childMiddleName &&
    !rexFio.test(values.childMiddleName)
  ) {
    errors.childMiddleName = i18next.t(
      'SIGN.UP_NOT_VALID_MIDDLE_NAME',
    );
  }

  // console.log('values', values);
  // console.log('errors', errors);

  return errors;
}
