import React, { useState } from 'react';
import SignIn from './SignIn';

import SignUp from './SignUp';
import SignInConfirm from '../../pages/sign_in_up/SignInConfirm';
import ChangePassword from '../../pages/sign_in_up/ChangePassword';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  conteiner: {
    [theme.breakpoints.up('sm')]: {
      margin: `0 ${theme.spacing(4)}`,
      minWidth: theme.spacing(61),
    },
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function Sign({ toggleModal }) {
  const { t } = useTranslation();
  const classes = useStyles();
  // global
  const [valsTab, setValsTab] = useState({
    activeTabId: 0,
    ...config.defLoginPassword,
  });

  const setActiveTabId = (activeTabId) => {
    setValsTab({
      ...valsTab,
      activeTabId,
    });
  };
  return (
    <Box className={classes.conteiner}>
      {valsTab.activeTabId === 0 ? (
        valsTab?.warningNote === 'CONFIRM_AUTH' ? (
          <>
            <Typography
              variant="h4"
              sx={{ textAlign: 'center', marginBottom: 3 }}
            >
              {t('SIGN.CH_CONFIRM_CODE')}
            </Typography>
            <SignInConfirm />
          </>
        ) : (
          <SignIn
            setValsTab={setValsTab}
            toggleModal={toggleModal}
            setActiveTabId={setActiveTabId}
          />
        )
      ) : valsTab.activeTabId === 1 ? (
        <SignUp
          setValsTab={setValsTab}
          toggleModal={toggleModal}
          setActiveTabId={setActiveTabId}
        />
      ) : valsTab.activeTabId === 2 ? (
        <>
          <Typography
            variant="h4"
            sx={{ textAlign: 'center', marginBottom: 3 }}
          >
            {t('SIGN.CH_PASS_BUTTON')}
          </Typography>
          <ChangePassword setValsTab={setValsTab} valsTab={valsTab} />
          <Button
            color="primary"
            variant="text"
            onClick={() => setActiveTabId(0)}
            style={{ width: '100%', marginTop: 24 }}
          >
            {t('COMPONENT.BACK_ONE_STEP')}
          </Button>
        </>
      ) : null}
    </Box>
  );
}

export default Sign;
