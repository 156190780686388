import React from 'react';
import {
  Action,
  ActionType,
  ContextState,
  State,
} from '../types/calypso.d';

const CalypsoContext = React.createContext<ContextState>(
  {} as ContextState,
);

const rootReducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case ActionType.fetch:
      return {
        ...state,
        calypso: {
          isLoaded: true,
          data: { ...action.payload },
          responseError: null,
        },
      };

    case ActionType.reset:
      return {
        calypso: {
          isLoaded: false,
          data: action.payload,
          responseError: null,
        },
      };
    case ActionType.error:
      return {
        ...state,
        calypso: {
          ...state.calypso,
          isLoaded: true,
          responseError: action.payload,
        },
      };
    default:
      return { ...state };
  }
};

function CalypsoProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [state, setState] = React.useReducer<
    React.Reducer<State, Action>
  >(rootReducer, {
    calypso: {
      isLoaded: false,
      data: null,
      responseError: null,
    },
  });

  return (
    <CalypsoContext.Provider value={{ state, setState }}>
      {children}
    </CalypsoContext.Provider>
  );
}

const useCalypsoState = () => {
  const context = React.useContext(CalypsoContext);
  return context;
};

export { CalypsoProvider, useCalypsoState };
