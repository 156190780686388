import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';

/* ui mui components */
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid } from '@mui/material';

/* ui local components */

import Header from '../Header/Header';
import Sidebar from '../Sidebar';
import SidebarInfo from '../Sidebar/SidebarInfo';
import LeftMenu from '../Sidebar/LeftMenu';

import Footer from '../Footer';
import { Link, Typography } from '@mui/material';
import ChatOper from '../ChatOper';

/* Sidebar structure */
import structure from '../Sidebar/structure';

/* lang */
import { useTranslation } from 'react-i18next';

import { getChatOperatorInfo } from '../../actions/user';

import cn from 'classnames';
import { useUserStateDispatch } from '../../context/UserContext';
import isEmpty, { getYearNow } from '../../helpers';

/* styles */
import useStyles from './styles';

import config from '../../config';
import useInterval from '../../hooks/useInterval';
import custom from '../../custom';

function Layout() {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    userState: {
      user: { lang },
      isAuthenticated,
      appInfo,
    },
  } = useUserStateDispatch();

  const GooglePlayIcon = custom.GooglePlayIcon;
  const AppStoreIcon = custom.AppStoreIcon;
  const RuStoreIcon = custom.RuStoreIcon;

  const withPhoto = [
    '/app/directions',
    '/app/sessions',
    '/app/faq',
    '/app/tax_deduction',
  ];
  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  );

  const location = useLocation();

  const hasPhoto =
    withPhoto.find((it) => location.pathname.startsWith(it)) !=
      null && !isMobile;
  const dataH1 = structure.find(
    (c) =>
      c.defaultH1 &&
      location.pathname.startsWith(
        config.accessLink(isAuthenticated, c.link),
      ),
  );

  const HeaderH1 = () => {
    //console.log('dataH1', dataH1, location.pathname);
    return (
      !isEmpty(dataH1) && (
        <Typography variant="h1" style={{ marginBottom: 32 }}>
          {t(`MAINMENU.${dataH1.t}`)}
        </Typography>
      )
    );
  };

  const [modalChat, setModalChat] = useState(false);
  const [chatInfo, setChatInfo] = useState({
    unreadCount: null,
    groupId: null,
  });

  const toggleModalChat = () => {
    setModalChat(!modalChat);
  };

  useEffect(() => {
    if (
      appInfo.isChatEnabled &&
      appInfo.showChatWithOperator &&
      isAuthenticated
    ) {
      getChatOperatorInfo(setChatInfo);
    }
  }, []);

  useInterval(
    () => {
      getChatOperatorInfo(setChatInfo);
    },
    appInfo.isChatEnabled &&
      appInfo.showChatWithOperator &&
      isAuthenticated,
    15000,
  );

  return useMemo(
    () => (
      <div className={classes.root}>
        <Header />
        <Sidebar
          structure={structure}
          isAuthenticated={isAuthenticated}
        />

        <SidebarInfo
          lang={lang}
          chatInfo={chatInfo}
          modalChat={modalChat}
          toggleModalChat={toggleModalChat}
        />

        <Container
          className={cn(
            classes.conteiner,
            appInfo.showChatWithOperator &&
              chatInfo?.unreadCount !== 0
              ? classes.conteinerTopInfo
              : classes.conteinerTop,
          )}
        >
          <div className={classes.content}>
            {hasPhoto ? (
              <Grid container spacing={3}>
                <Grid item sm={3}>
                  <LeftMenu structure={structure} />
                </Grid>
                <Grid item sm={9}>
                  <HeaderH1 />
                  <Outlet />
                </Grid>
              </Grid>
            ) : (
              <>
                <HeaderH1 />
                <Outlet />
              </>
            )}
          </div>
          {appInfo.isChatEnabled && appInfo.showChatWithOperator && (
            <ChatOper
              lang={lang}
              chatInfo={chatInfo}
              modalChat={modalChat}
              toggleModalChat={toggleModalChat}
            />
          )}
          <Footer>
            <Divider />

            <Grid container>
              {appInfo.showStoreLinks && (
                <Grid item className={classes.marginGoogleButtons}>
                  {appInfo.linkGooglePlay !== '' && (
                    <Link
                      href={appInfo.linkGooglePlay}
                      target={'_blank'}
                      className={classes.appLink}
                    >
                      <GooglePlayIcon />
                    </Link>
                  )}
                  {appInfo.linkAppStore !== '' && (
                    <Link
                      href={appInfo.linkAppStore}
                      target={'_blank'}
                      className={classes.appLink}
                    >
                      <AppStoreIcon />
                    </Link>
                  )}
                  {appInfo.linkRuStore !== '' && (
                    <Link
                      href={appInfo.linkRuStore}
                      target={'_blank'}
                      className={classes.appLink}
                    >
                      <RuStoreIcon />
                    </Link>
                  )}
                </Grid>
              )}
              <Grid item>
                <Typography className={classes.copyright}>
                  {t('BOTTOM.COPY', { year: getYearNow() })}{' '}
                  <Link to="/" className={classes.link}>
                    {appInfo.defTitle}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Footer>
        </Container>
      </div>
    ),
    [chatInfo?.unreadCount, modalChat, hasPhoto, dataH1, lang],
  );
}

export default Layout;
