interface ApiParamsI {
  method: 'GET' | 'POST' | 'DELETE';
  url: string;
}

const apiParams: Record<string, ApiParamsI> = {
  // chat
  GetChatOperatorInfo: {
    method: 'GET',
    url: 'chat/operatorInfo',
  },
  // calypso
  CalypsoForms: {
    method: 'GET',
    url: 'webform/forms',
  },
  calypsoAuth: {
    method: 'GET',
    url: 'webform/auth',
  },

  // info
  GetAppLicenseAgreementText: {
    method: 'GET',
    url: 'appLicenseAgreementText',
  },
  AppInfo: {
    method: 'GET',
    url: 'appInfo',
  },
  GetFaq: {
    method: 'GET',
    url: 'faq',
  },

  // auth
  ExternalLogin: { method: 'POST', url: 'auth/login' },
  СonfirmLogin: { method: 'POST', url: 'auth/confirmLogin' },
  LoginEsiaMam: { method: 'POST', url: 'auth/loginEsiaMam' },
  RefreshToken: { method: 'POST', url: 'auth/refreshToken' },
  Logout: { method: 'POST', url: 'auth/logout' },

  ConfirmNewAccount: {
    method: 'POST',
    url: 'auth/confirmNewAccount',
  },
  GetSessions: {
    method: 'GET',
    url: 'auth/sessions',
  },
  DeleteSession: {
    method: 'DELETE',
    url: 'auth/session',
  },
  RegisterNewUser: { method: 'POST', url: 'auth/registerNewUser' },
  InitPasswordChanging: {
    method: 'POST',
    url: 'auth/initPasswordChanging',
  },
  ChangePassword: { method: 'POST', url: 'auth/changePassword' },
  ConfirmAuth: {
    method: 'POST',
    url: 'auth/confirm',
  },
  // user
  GetUserInfo: { method: 'GET', url: 'user/info' },
  UpdateUserInfo: { method: 'POST', url: 'user/info' },
  UploadUserPhoto: { method: 'POST', url: 'user/photo' },
  DeleteUserPhoto: { method: 'DELETE', url: 'user/photo' },

  ConfirmNewEmail: {
    method: 'POST',
    url: 'user/confirmNewEmail',
  },
  ConfirmNewPhone: {
    method: 'POST',
    url: 'user/confirmNewPhone',
  },
  DeleteAccount: {
    method: 'DELETE',
    url: 'auth/userAccount',
  },
  GetQrKey: {
    method: 'POST',
    url: 'auth/temporaryKey',
  },
  GetQrState: {
    method: 'GET',
    url: 'auth/temporaryKey',
  },

  // mmk
  GetMmkHeaders: { method: 'GET', url: 'mmk/headers' },
  GetMmkRecordData: { method: 'GET', url: 'mmk/recordData' },
  GetLinkedMmkList: { method: 'GET', url: 'mmk/linkedList' },
  RenewLinkedMmkList: { method: 'POST', url: 'mmk/renewLinkedList' },
  GetMmkRecordTypes: { method: 'GET', url: 'mmk/recordTypes' },

  GetPatientPrescribedDrugs: {
    method: 'GET',
    url: 'mmk/prescribedDrugs',
  },
  ChangePatientPrescribedDrugState: {
    method: 'POST',
    url: 'mmk/prescribedDrugState',
  },
  ShowcaseInfo: {
    method: 'GET',
    url: 'mmk/showcaseInfo',
  },
  GetDirections: { method: 'GET', url: 'mmk/directions' },

  // clinic
  CallDoctor: {
    method: 'POST',
    url: 'clinic/doctorCall',
  },
  GetBrandLogoImage: { method: 'GET', url: 'clinic/brandLogoImage' },
  GetFilials: { method: 'GET', url: 'clinic/filials' },
  GetDoctorSpecializations: {
    method: 'GET',
    url: 'clinic/doctorSpecializations',
  },
  GetPromoActions: { method: 'GET', url: 'clinic/promoActions' },
  GetDoctors: { method: 'GET', url: 'clinic/doctors' },
  // planning
  GetFirstTimeSlot: {
    method: 'GET',
    url: 'planning/firstSlots',
  },
  GetDoctorsTimeSlots: {
    method: 'GET',
    url: 'planning/doctorsTimeSlots',
  },
  //  GetVisitKinds: { method: 'GET', url: 'planning/visitKinds' },
  DoctorFreeDates: { method: 'GET', url: 'planning/doctorFreeDates' },

  // visit
  GetChatInfo: { method: 'GET', url: 'visit/chatInfo' },
  AddVisit: { method: 'POST', url: 'visit/add' },
  GetVisits: { method: 'GET', url: 'visit/list' },
  CancelVisit: { method: 'POST', url: 'visit/cancel' },
  SendICameNotification: {
    method: 'POST',
    url: 'visit/sendICameNotification',
  },
  SendVisitRequestEmailToClinic: {
    method: 'POST',
    url: 'visit/sendRequestEmailToClinic',
  },
  AddDoctorReview: {
    method: 'POST',
    url: 'clinic/doctorReview',
  },
  GetDoctorReviews: {
    method: 'GET',
    url: 'clinic/doctorReviews',
  },
  CustomCheck: {
    method: 'POST',
    url: 'visit/customCheck',
  },
  // invoice
  GetInvoices: { method: 'GET', url: 'invoice/list' },
  GetInvoiceDetails: { method: 'GET', url: 'invoice/details' },
  GetInvoicePayLink: { method: 'GET', url: 'invoice/payLink' },
  GetInvoiceById: { method: 'GET', url: 'invoice/byId' },
  CreateInvoiceByVisit: {
    method: 'POST',
    url: 'invoice/createByVisit',
  },

  // notifications
  GetNotificationTypes: { method: 'GET', url: 'notification/types' },
  GetNotifications: { method: 'POST', url: 'notification/list' },
  UpdateNotificationState: {
    method: 'POST',
    url: 'notification/state',
  },
  NotificationCount: {
    method: 'POST',
    url: 'notification/count',
  },

  // doctor
  LoginAsDoctor: { method: 'POST', url: 'doctor/login' },
  SendLoginAsDoctorConfirmationCode: {
    method: 'POST',
    url: 'doctor/loginConfirmationCode',
  },
  AddAnonymousVisit: {
    method: 'POST',
    url: 'doctor/addAnonymousVisit',
  },
  // calypso
  GetCalypsoServerParams: {
    method: 'GET',
    url: 'calypso/serverParams',
  },
  GetCalypsoMainFormParams: {
    method: 'GET',
    url: 'calypso/mainFormParams',
  },
  GetVisitsDoc: { method: 'GET', url: 'doctor/visits' },
  GetPlExam: { method: 'GET', url: 'planning/plExam' },
};

const url = (nameFunc: string) => {
  return apiParams[nameFunc].url;
};

const method = (nameFunc: string) => apiParams[nameFunc].method;
const params = { url, method };
export default params;
