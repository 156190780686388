import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import Carousel from 'react-material-ui-carousel';
import ModaleCallDoctor from './ModaleCallDoctor';
import AlertDialogSlide from '../../../components/Modals/AlertDialogSlide';
import ModaleShowPhone from './ModaleShowPhone';
import config from '../../../config';
import { useUserStateDispatch } from '../../../context/UserContext';
import isEmpty from '../../../helpers';
import { parsePhoneNumber } from '../../../helpers/validators';

const useStyles = (custom) =>
  makeStyles((theme) => ({
    containerIsSomethingBotheringYou:
      Object.keys(custom.firstButtons).length === 4
        ? {
            display: 'grid',
            gridGap: theme.spacing(3),
            marginBottom: theme.spacing(8),

            gridTemplateColumns:
              'repeat(auto-fill, minmax(264px, 1fr))',
            [theme.breakpoints.down('lg')]: {
              gridTemplateColumns:
                'repeat(auto-fill, minmax(372px, 1fr))',
            },
            [theme.breakpoints.down('md')]: {
              gridTemplateColumns:
                'repeat(auto-fill, minmax(262px, 1fr))',
            },
          }
        : Object.keys(custom.firstButtons).length === 3
        ? {
            display: 'grid',
            gridGap: theme.spacing(3),
            marginBottom: theme.spacing(8),
            gridTemplateColumns:
              'repeat(auto-fill, minmax(320px, 1fr))',
            [theme.breakpoints.down('lg')]: {
              gridTemplateColumns:
                'repeat(auto-fill, minmax(272px, 1fr))',
            },
            [theme.breakpoints.down('md')]: {
              gridTemplateColumns:
                'repeat(auto-fill, minmax(198px, 1fr))',
            },
          }
        : {
            display: 'grid',
            gridGap: theme.spacing(3),
            marginBottom: theme.spacing(8),
            gridTemplateColumns:
              'repeat(auto-fill, minmax(400px, 1fr))',
            [theme.breakpoints.down('lg')]: {
              gridTemplateColumns:
                'repeat(auto-fill, minmax(368px, 1fr))',
            },
            [theme.breakpoints.down('md')]: {
              gridTemplateColumns:
                'repeat(auto-fill, minmax(268px, 1fr))',
            },
          },
    paper: {
      textAlign: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      padding: theme.spacing(2),
      // '&:hover': {
      //   background: theme.palette.bgLight.two,
      // },
    },

    item: {
      boxSizing: 'border-box',
      minHeight: 100,
      cursor: 'pointer',
      '& .MuiPaper-root': {
        borderRadius: 8,
        '&:hover': {
          boxShadow: theme.palette.shadows.firstHover,
          //backgroundColor: theme.palette.bgLight.two,
        },
      },
    },
    img: {
      display: 'inline-flex',
      minHeight: 96,
    },
    containerCorousel: {
      height: 190,
      display: 'block',
      '& .MuiButtonBase-root': {
        color: theme.palette.primary.main,
        background: theme.palette.bgLight.one,
        boxShadow: theme.palette.shadows.card,
        '&:hover': {
          color: '#fff',
          background: `${theme.palette.action.toggle1} !important`,
        },
        '&$selected': {
          color: '#fff',
          background: `${theme.palette.primary.main} !important`,
        },
      },
    },
    selected: {},
  }));

export default function FirstButtons() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [postCallDoctor, setPostCallDoctor] = useState({});
  const [modalCallDoc, setModalCallDoc] = useState(false);
  const [modalShowPhone, setModalShowPhone] = useState({
    open: false,
    phoneNumber: null,
  });
  const {
    userState: { isAuthenticated, appInfo, clinics },
  } = useUserStateDispatch();
  const classes = useStyles({ firstButtons: appInfo.firstButtons })();
  const items = [
    {
      id: 'favorites',
      to: 'visit/null/0/false/null/true',
      label: t('VISIT.FAVS'),
      img: appInfo.firstButtons.favorites,
    },
    {
      id: 'visit',
      to: 'visit',
      label: t('HOME.APPOINTMENT'),
      img: appInfo.firstButtons.visit,
    },
    {
      id: 'mmk',
      to: () => {
        if (isAuthenticated) {
          navigate('/app/mmk');
        } else {
          navigate('/sign');
        }
      },

      label: t('MAINMENU.MED_CARD'),
      img: appInfo.firstButtons.mmk,
    },
    {
      id: 'call',
      to: () => {
        if (isAuthenticated) {
          setModalCallDoc(true);
        } else {
          navigate('/sign');
        }
      },
      label: t('HOME.CALL_DOCTOR'),
      img: appInfo.firstButtons.call,
    },
    {
      id: 'ambulance',
      to: () => {
        if (appInfo?.ambulancePhone) {
          const phoneNumber = parsePhoneNumber()(
            appInfo.ambulancePhone,
            {
              countryCode: appInfo.countryCode,
              extract: false,
            },
          )?.formatNational();
          setModalShowPhone({ open: true, phoneNumber });
        } else {
          setModalShowPhone({ open: true, phoneNumber: '' });
        }
      },
      label: t('HOME.AMBULANCE'),
      img: appInfo.firstButtons.ambulance,
    },

    {
      id: 'clinic',
      to: 'map',
      label: t('MAINMENU.CLINIC_MAP'),
      img: appInfo.firstButtons.clinic,
    },
    {
      id: 'phone',
      to: () => {
        /** getting clinic phone */
        if (isEmpty(clinics) || clinics[0]?.phone == null) return;
        const clinic = clinics[0];
        const phoneNumber = clinic?.phone
          ? parsePhoneNumber(appInfo.countryCode)(clinic?.phone, {
              defaultCountry: appInfo.countryCode,
              extract: false,
            })?.formatNational()
          : '';
        setModalShowPhone({ open: true, phoneNumber });
      },
      label: t('HOME.CLINIC_PHONE'),
      img: appInfo.firstButtons.phone,
    },
  ];
  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('sm'),
  );

  const go = (to) => {
    if (typeof to === 'string')
      navigate(
        config.accessLink(
          appInfo.requireStartPageAuth || isAuthenticated,
          to,
        ),
      );
    else to();
  };

  /* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
  const Item = ({ to, img, label }) => (
    <div className={classes.item} onClick={() => go(to)}>
      <Paper className={classes.paper}>
        <img src={img} alt="" className={classes.img} />
        <Typography variant="h6">{label}</Typography>
      </Paper>
    </div>
  );
  // console.log(
  //   'Object.keys(appInfo.firstButtons)',
  //   Object.keys(appInfo.firstButtons).join(','),
  // );
  return (
    <>
      {!isMobile ? (
        <div className={classes.containerIsSomethingBotheringYou}>
          {Object.keys(appInfo.firstButtons).map((key) => (
            <Item
              {...items.find((item) => item.id === key)}
              key={key}
            />
          ))}
        </div>
      ) : (
        <Carousel
          autoPlay={false}
          indicators={false}
          className={classes.containerCorousel}
          navButtonsAlwaysVisible={true}
        >
          {Object.keys(appInfo.firstButtons).map((key) => (
            <Item
              {...items.find((item) => item.id === key)}
              key={key}
            />
          ))}
        </Carousel>
      )}
      <ModaleShowPhone
        open={modalShowPhone.open}
        phoneNumber={modalShowPhone.phoneNumber}
        setModalShowPhone={setModalShowPhone}
      />
      <ModaleCallDoctor
        modalCallDoc={modalCallDoc}
        setModalCallDoc={setModalCallDoc}
        setPostCallDoctor={setPostCallDoctor}
      />

      {postCallDoctor?.posted && (
        <AlertDialogSlide
          state={postCallDoctor}
          setState={setPostCallDoctor}
          isOpen={true}
          contentTextOk={
            postCallDoctor?.data ? t('CALL_DOCTOR.FORM_SENT_OK') : ''
          }
          contentTextOkDetail={appInfo.doctorCallText}
          doCallbackWhenOk={() => setPostCallDoctor({})}
          doOnClose={() => setPostCallDoctor({})}
          keyImg="alterCallDrDone"
        />
      )}
    </>
  );
}
