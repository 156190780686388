import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid } from '@mui/material';
import Invoices from './Invoices';
import { InvoicesProvider } from '../../context/InvoicesContext';
import InvoicesFilter from './InvoicesFilter';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import BannerCert from './BannerCert';

export default function InvoicesPage() {
  const { t } = useTranslation();

  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  );

  const HeaderH1 = () => (
    <Typography variant="h1" style={{ marginBottom: 32 }}>
      {t('MAINMENU.INVOICES')}
    </Typography>
  );

  return (
    <InvoicesProvider>
      {!isMobile ? (
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={3}>
            <InvoicesFilter />
          </Grid>
          <Grid item xs={12} sm={9}>
            <BannerCert />
            <HeaderH1 />
            <Invoices />
          </Grid>
        </Grid>
      ) : (
        <>
          <BannerCert />
          <HeaderH1 />

          <InvoicesFilter />
          <Invoices />
        </>
      )}
    </InvoicesProvider>
  );
}
