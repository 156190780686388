import React, { useState } from 'react';

// context
import { useUserStateDispatch } from '../../context/UserContext';

import { loginUser } from '../../actions/user';

//form func
import useForm from '../../hooks/useForm';
import validateSignIn from '../../pages/sign_in_up/validationSignIn';

import SignInForm from '../../components/Forms/SignInForm';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLanguageValue } from '../../context/LanguageContext';

//import EsiaLink from './EsiaLink';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function SignIn({ setValsTab, setActiveTabId }) {
  // global
  const { t } = useTranslation();

  const {
    userState: { appInfo },
    userDispatch,
  } = useUserStateDispatch();

  const {
    languageState: { language },
  } = useLanguageValue();

  const navigate = useNavigate();

  // local
  const [isLoading, setIsLoading] = useState(false);
  const [serverResponse, setServerResponse] = useState('');

  const login = () =>
    loginUser(
      userDispatch,
      values?.login,
      values?.password,
      navigate,
      setIsLoading,
      setServerResponse,
      setValsTab,
      language,
      appInfo.isAnonymousChildrenEnabled,
    );

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
  } = useForm(login, validateSignIn, appInfo);

  return (
    <>
      <Typography
        variant="h4"
        style={{ textAlign: 'center', marginBottom: 24 }}
      >
        {t('SIGN.IN_BUTTON')}
      </Typography>
      <SignInForm
        values={values}
        errors={errors}
        serverResponse={serverResponse}
        handleChange={handleChange}
        setValues={setValues}
        setErrors={setErrors}
        validate={validateSignIn}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        submitButtonWidth={'100%'}
      />
      <Button
        color="secondary"
        variant="contained"
        onClick={() => setActiveTabId(1)}
        style={{ width: '100%' }}
      >
        {t('SIGN.UP_BUTTON')}
      </Button>
      <Button
        style={{ width: '100%', marginTop: 24 }}
        color="primary"
        variant="text"
        onClick={() => {
          setActiveTabId(2);
        }}
      >
        {t('SIGN.CH_PASS_BUTTON')}
      </Button>
    </>
  );
}

export default SignIn;
