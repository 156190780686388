import React, { useCallback, useState } from 'react';
//import { useParams } from 'react-router-dom';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

import Doctors from './Doctors';
import DoctorCard from './DoctorCard';
import DoctorsFilter from './DoctorsFilters';
import useStyles from './styles';
import ModaleDoctorRating from './ModaleDoctorRating';
import { useVisitState } from '../../context/VisitContext';
import isEmpty from '../../helpers';
import ModaleDoctorInfo from './ModaleDoctorInfo';
import { useUserStateDispatch } from '../../context/UserContext';
import { dateToHL7 } from '../../helpers/dateFormat';
import useDoctorsParams from './useDoctorsParams';
import ModaleCreateVisit from './ModaleCreateVisit';
import DoctorsFilterFIO from './DoctorsFiltersFIO';
import useIsMobile from '../../hooks/useIsMobile';
//import Doctor from './Doctor';
/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const HeaderH1 = ({ className }) => {
  const { t } = useTranslation();
  return (
    <Typography variant="h1" mb={3} className={className}>
      {t('MAINMENU.APPOINTMENT')}
    </Typography>
  );
};
export default function DoctorsContainer() {
  const classes = useStyles();
  const isMobile = useIsMobile('lg', 768);
  const [modalCreateVisit, setModalCreateVisit] = useState(false);
  const [modalDoc, setModalDoc] = useState(false);
  const [modalRating, setModalRaring] = useState(false);

  const {
    state: { doctors, selectedDate, visitData },
    setState,
  } = useVisitState();

  const {
    userState: { clinics },
  } = useUserStateDispatch();
  const { isOnlineParam } = useDoctorsParams();

  const toggleModalCreateVisit = useCallback(
    ({
      visitDate,
      doctor,
      timeSlots,
      visitKind,
      clinic,
      visitKinds,
    }) => {
      if (!modalCreateVisit) {
        const payload = {
          visitDate,
          doctor,
          timeSlots,
          visitKind,
          visitKinds,
          clinic,
          isOnline: isOnlineParam,
        };
        setState({
          type: 'SET_VISIT_DATA',
          payload,
        });
      } else {
        setState({
          type: 'SET_VISIT_DATA',
          payload: {},
        });
      }

      setModalCreateVisit(!modalCreateVisit);
    },

    [modalCreateVisit, visitData, isOnlineParam],
  );

  const toggleModalDoc = (
    itemId,
    visitKindId,
    visitDate = dateToHL7(selectedDate.toDate()),
    patientDirectionId = 0,
  ) => {
    if (!modalDoc && !isEmpty(doctors.data) && itemId) {
      const item = doctors.data.find((it) => it.itemId === itemId);
      if (!isEmpty(item)) {
        const visitKind = (item.visitKinds || []).find(
          (it) => it.id === visitKindId,
        );
        const clinic = (clinics || []).find(
          (it) => it.id === item.doctor.clinicId,
        );
        setState({
          type: 'SET_VISIT_DATA',
          payload: {
            doctor: item.doctor,
            visitDate,
            visitKind,
            visitKinds: item.visitKinds,
            clinic,
            isOnline: isOnlineParam,
            review: item.review,
            patientDirectionId,
          },
        });
      }
    }
    setModalDoc(!modalDoc);
  };

  const toggleModalRating = (doctor, visitKind, review) => {
    if (!modalRating) {
      setState({
        type: 'SET_RATING_DATA',
        payload: {
          doctor,
          visitKind,
          review,
          favOnly: false,
        },
      });
    }
    setModalRaring(!modalRating);
  };

  return (
    <>
      {!isMobile ? (
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className={classes.container}
        >
          <Grid item xs={12}>
            <HeaderH1 className={classes.filtersFixed} />
          </Grid>
          <Grid item xs={12} sm={3} mt={6}>
            <div className={classes.filtersFixedWidth}>
              <DoctorsFilter />
            </div>
          </Grid>
          <Grid item xs={12} sm={9} mt={6}>
            <DoctorsFilterFIO />
            <Doctors
              toggleModalRating={toggleModalRating}
              toggleModalDoc={toggleModalDoc}
              toggleModalCreateVisit={toggleModalCreateVisit}
            >
              <DoctorCard />
            </Doctors>
          </Grid>
        </Grid>
      ) : (
        <>
          <HeaderH1 />
          <DoctorsFilter />
          <Doctors
            toggleModalRating={toggleModalRating}
            toggleModalDoc={toggleModalDoc}
            toggleModalCreateVisit={toggleModalCreateVisit}
          >
            <DoctorCard />
          </Doctors>
        </>
      )}
      <ModaleCreateVisit
        modalCreateVisit={modalCreateVisit}
        toggleModalCreateVisit={toggleModalCreateVisit}
      />

      <ModaleDoctorInfo
        modalDoc={modalDoc}
        toggleModalCreateVisit={toggleModalCreateVisit}
        setModalDoc={setModalDoc}
        isOnline={isOnlineParam}
      />
      <ModaleDoctorRating
        modalRating={modalRating}
        setModalRaring={setModalRaring}
      />
    </>
  );
}
