import React from 'react';
import { DEFAULT_COUNT } from '../actions/visit';
import isEmpty from '../helpers';

const VisitsContext = React.createContext();

const rootReducer = (state, action) => {
  switch (action.type) {
    case 'RESET_VISITS':
      return {
        ...state,
        visits: {
          isLoaded: false,
          data: [],
          startIndex: 0,
          count: DEFAULT_COUNT,
          hasMore: true,
          responseError: null,
        },
      };

    case 'FETCH_VISITS':
      return {
        ...state,
        visits: {
          ...state.visits,
          isLoaded: true,
          data: [
            ...state.visits.data,
            ...action.payload.data.map((item) => ({
              ...item,
              visitKinds: { isLoaded: false, data: [] },
            })),
          ],
          startIndex: state.visits.startIndex + state.visits.count,
          count: state.visits.count,
          hasMore: action.payload.data.length === state.visits.count,
        },
      };
    case 'FETCH_VISIT_KINDS':
      return {
        ...state,
        visits: {
          ...state.visits,
          data: state.visits.data.map((item) => ({
            ...item,
            visitKinds:
              item.id === action.payload.id
                ? {
                    isLoaded: true,
                    data: action.payload.data,
                  }
                : item.visitKinds,
          })),
        },
      };

    case 'FETCH_VISIT_KINDS_ERROR':
      return {
        ...state,
        visits: {
          ...state.visits,
          //isLoaded: action.payload.isLoaded,
          data: state.visits.data.map((item) => ({
            ...item,
            visitKinds:
              item.id === action.payload.id
                ? { isLoaded: true, data: [], ...action.payload }
                : item.visitKinds,
          })),
        },
      };

    case 'FETCH_VISIT':
      return {
        ...state,
        visits: {
          ...state.visits,
          isLoaded: true,
          data: !isEmpty(state.visits.data)
            ? [
                ...state.visits.data.map((item) =>
                  item.id === action.payload.data[0].id
                    ? {
                        ...action.payload.data[0],
                        visitKinds: { isLoaded: false, data: [] },
                      }
                    : item,
                ),
              ]
            : [...action.payload.data],
          startIndex: state.visits.startIndex + state.visits.count,
          count: state.visits.count,
          hasMore: action.payload.data.length === state.visits.count,
        },
      };

    case 'SET_CHAT_INFO':
      return {
        ...state,
        visits: {
          ...state.visits,
          data: state.visits.data.map((item) => {
            if (item.id === action.payload.visitId) {
              return {
                ...item,
                chatInfo: {
                  ...item.chatInfo,
                  ...action.payload.chatInfo,
                },
              };
            } else {
              return item;
            }
          }),
        },
      };
    case 'FETCH_VISITS_ERROR':
      return {
        ...state,
        visits: {
          ...state.visits,
          isLoaded: true,
          responseError: action.payload,
        },
      };

    case 'SET_VISIT':
      return {
        ...state,
        visit: {
          ...state.visit,
          ...action.payload,
        },
      };

    // case 'RESET_VISIT':
    //   return {
    //     ...state,
    //     visit: {},
    //   };
    // case 'SET_VISIT_KINDS':
    //   return {
    //     ...state,
    //     visitKinds: {
    //       ...state.visitKinds,
    //       ...action.payload,
    //       isLoaded: true,
    //     },
    //   };
    case 'SET_VISIT_LOADING':
      return {
        ...state,
        visitKinds: {
          ...state.visitKinds,
          isLoaded: false,
        },
      };

    case 'RESET_VISIT_KINDS':
      return {
        ...state,
        visitKinds: {
          data: [],
          isLoaded: true,
          responseError: null,
        },
      };

    case 'CANCEL_VISIT_CONFIRM':
      return {
        ...state,
        canceledVisit: {
          ...state.canceledVisit,
          ...action.payload,
          isConfirm: true,
        },
      };
    case 'CANCEL_VISIT_SUBMITED':
      return {
        ...state,
        canceledVisit: {
          ...state.canceledVisit,
          isSubmited: true,
        },
      };

    case 'CANCEL_VISIT_SERVER_ERR':
      return {
        ...state,
        canceledVisit: {
          ...state.canceledVisit,
          isLoaded: true,
          serverError: action.payload.serverError,
        },
      };

    case 'CANCEL_VISIT_REST':
      return {
        ...state,
        canceledVisit: {
          serverError: '',
          data: false,
          isConfirm: false,
          isSubmited: false,
          isLoaded: false,
          planningId: null,
          id: null,
        },
      };

    case 'CANCEL_VISIT':
      return {
        ...state,

        canceledVisit: {
          ...state.canceledVisit,
          ...action.payload,
        },
        visits: {
          ...state.visits,
          isLoaded: true,
          data: state.visits.data.map((item) =>
            item.id === action.payload.id
              ? {
                  ...item,
                  isCancelled: true,
                }
              : {
                  ...item,
                },
          ),
        },
      };

    case 'ICAME_VISIT_CONFIRM':
      return {
        ...state,
        iCameVisit: {
          ...state.iCameVisit,
          ...action.payload,
          isConfirm: true,
        },
      };
    case 'ICAME_VISIT_SUBMITED':
      return {
        ...state,
        iCameVisit: {
          ...state.iCameVisit,
          isSubmited: true,
        },
      };
    case 'ICAME_VISIT_SERVER_ERR':
      return {
        ...state,
        iCameVisit: {
          ...state.iCameVisit,
          isLoaded: true,
          serverError: action.payload.serverError,
        },
      };
    case 'ICAME_VISIT_REST':
      return {
        ...state,
        iCameVisit: {
          serverError: '',
          data: null,
          isConfirm: false,
          isSubmited: false,
          clinicId: null,
          id: null,
        },
      };
    case 'ICAME_VISIT':
      return {
        ...state,
        iCameVisit: {
          ...state.iCameVisit,
          ...action.payload,
          isConfirm: false,
        },
        visits: {
          ...state.visits,
          isLoaded: true,
          data: state.visits.data.map((item) =>
            item.id === action.payload.id
              ? {
                  ...item,
                  canAcceptICameMessage: false,
                }
              : {
                  ...item,
                },
          ),
        },
      };

    case 'CONFIRM_CREATE_PAYMENT':
      return {
        ...state,
        createInvoice: {
          ...state.createInvoice,
          ...action.payload,
          isConfirm: true,
        },
      };
    case 'CREATE_INVOICE_SUBMITED':
      return {
        ...state,
        createInvoice: {
          ...state.createInvoice,
          isSubmited: true,
        },
      };
    case 'CREATE_INVOICE_SERVER_ERR':
      return {
        ...state,
        createInvoice: {
          ...state.createInvoice,
          isLoaded: true,
          serverError: action.payload.serverError,
        },
      };
    case 'CREATE_INVOICE_REST':
      return {
        ...state,
        createInvoice: {
          serverError: '',
          data: null,
          isConfirm: false,
          isSubmited: false,
          clinicId: null,
          planningId: null,
          id: null,
        },
      };
    case 'CREATE_INVOICE':
      return {
        ...state,
        createInvoice: {
          ...state.createInvoice,
          ...action.payload,
        },
      };
    case 'SET_FILTER':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

const VisitsProvider = ({ children }) => {
  const [state, setState] = React.useReducer(rootReducer, {
    type: 'All',
    specOrName: null,
    futureVisits: true,
    includeCancelled: false,
    visit: {},
    canceledVisit: {
      isLoaded: false,
      serverError: '',
      data: false,
      isConfirm: false,
      isSubmited: false,
      planningId: null,
      clinicId: null,
      mmkId: 'parent',
      id: null,
    },

    iCameVisit: {
      isLoaded: false,
      serverError: '',
      data: null,
      isConfirm: false,
      isSubmited: false,
      clinicId: null,
      id: null,
    },
    createInvoice: {
      isLoaded: false,
      serverError: '',
      data: null,
      isConfirm: false,
      isSubmited: false,
      clinicId: null,
      planningId: null,
      id: null,
    },

    visits: {
      isLoaded: false,
      data: [],
      startIndex: 0,
      count: DEFAULT_COUNT,
      hasMore: true,
      responseError: null,
    },
  });

  return (
    <VisitsContext.Provider value={{ state, setState }}>
      {children}
    </VisitsContext.Provider>
  );
};

const useVisitsState = () => {
  const context = React.useContext(VisitsContext);

  return context;
};

export { VisitsProvider, VisitsContext, useVisitsState };
