import React from 'react';
import { useTranslation } from 'react-i18next';
//components
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  forgetButton: {
    textTransform: 'none',
    fontWeight: 500,
    marginLeft: 24,
  },
}));

const ButtonEsiaLink = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const referUrl = `${window.location.protocol}//${window.location.host}`;

  const href = `https://ak.medaboutme.ru/esia/?authesia&referer_uri=${encodeURI(
    referUrl,
  )}`;

  return (
    <Button
      color="primary"
      className={classes.forgetButton}
      component="a"
      href={href}
    >
      {t('SIGN.IN_EISA')}
    </Button>
  );
};
export default ButtonEsiaLink;
