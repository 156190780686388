import React from 'react';
const refer_url = 'http://localhost:3000';

const Esia = () => {
  return (
    <div>
      <a
        href={`http://medaboutme.ru/esia/?authesia&referer_uri=${encodeURI(
          refer_url,
        )}`}
      >
        auth
      </a>
    </div>
  );
};
export default Esia;
