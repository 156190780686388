import { formarIncompletePhone } from './utils';

export const handlePhoneChange = (
  e,
  values,
  setValues,
  setErrors,
  validate,
  appInfo,
) => {
  const vals = {
    ...values,
    [e.target.name]: formarIncompletePhone(
      e.target.value,
      appInfo.countryCode,
    ),
  };
  setValues(vals);
  setErrors(validate(vals, appInfo));
};
