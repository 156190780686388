import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useSessionsState } from '../../context/SessionsContext';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Loading from '../../components/Loading';
import { deleteSession, getSessions } from '../../actions/user';
import isEmpty from '../../helpers';
import { useUserStateDispatch } from '../../context/UserContext';
import { HL7ToDateToSrtDate } from '../../helpers/dateFormat';
import { Alert } from '@mui/material';

const useStyles = makeStyles({
  table: {
    minWidth: 350,
  },
});

const Sessions: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    state: { sessions },
    setState,
  } = useSessionsState();

  const {
    userState: {
      user: { lang },
    },
  } = useUserStateDispatch();

  useEffect(() => {
    getSessions(setState);
  }, []);

  const handleDeleteSession = (v: string) => {
    deleteSession(setState, v);
  };

  return (
    <>
      {!sessions.isLoaded ? (
        <Loading />
      ) : sessions.responseError != null ? (
        <Alert severity="warning">
          <Typography variant="h6" component="p">
            {sessions.responseError}
          </Typography>
        </Alert>
      ) : !isEmpty(sessions.data) ? (
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              {sessions.data.map((row) => (
                <TableRow key={row.jti}>
                  <TableCell component="th" scope="row">
                    {HL7ToDateToSrtDate(
                      row.cdate,
                      lang,
                      lang === 'ru'
                        ? 'DD.MM.YYYY HH:mm'
                        : 'MMM dd, YYYY HH:mm',
                    )}
                  </TableCell>
                  <TableCell>{row.ip}</TableCell>
                  <TableCell>
                    {isEmpty(row?.userAgent.device)
                      ? `${row?.userAgent?.browser?.name ?? ''} ${
                          row?.userAgent.browser?.version ?? ''
                        } `
                      : `${row?.userAgent?.device?.model ?? ''} ${
                          row?.userAgent.device?.type ?? ''
                        } ${row?.userAgent.device?.vendor ?? ''} `}
                  </TableCell>
                  <TableCell>
                    {row?.userAgent.os?.name ?? ''}{' '}
                    {row?.userAgent.os?.version ?? ''}
                  </TableCell>
                  <TableCell>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => handleDeleteSession(row.jti)}
                    >
                      {t('SESSIONS.REMOVE')}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Alert severity="warning">
          <Typography variant="h6" component="p">
            {t('SESSIONS.NO_SESSIONS_RECORDS')}
          </Typography>
        </Alert>
      )}
    </>
  );
};

export default Sessions;
