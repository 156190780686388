import React from 'react';
import { DEFAULT_COUNT_SIZE } from '../actions/mmk';
const MmkContext = React.createContext();

const rootReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_MMK':
      return {
        ...state,
        mmk: {
          ...state.mmk,
          isLoaded: true,
          data: [
            ...state.mmk.data,
            ...action.payload.data.map((item) => ({
              ...item,
              records: { isLoaded: false, data: [] },
            })),
          ],
          startIndex: state.mmk.startIndex + state.mmk.count,
          count: state.mmk.count,
          hasMore: action.payload.data.length === state.mmk.count,
        },
      };
    case 'FETCH_MMK_RECORDS':
      return {
        ...state,
        mmk: {
          ...state.mmk,
          data: state.mmk.data.map((item) => ({
            ...item,
            records:
              item.id === action.payload.id
                ? {
                    isLoaded: true,
                    data: action.payload.data,
                  }
                : item.records,
          })),
        },
      };
    case 'FETCH_MMK_RECORDS_ERROR':
      return {
        ...state,
        mmk: {
          ...state.mmk,
          data: state.mmk.data.map((item) => ({
            ...item,
            records:
              item.id === action.payload.id
                ? {
                    data: [],
                    isLoaded: true,
                    ...action.payload,
                  }
                : item.records,
          })),
        },
      };

    case 'FETCH_MMK_ERROR':
      return {
        ...state,
        mmk: {
          ...state.mmk,
          isLoaded: true,
          responseError: action.payload,
        },
      };
    case 'FETCH_MMK_ERROR_FILE':
      return {
        ...state,
        mmk: {
          ...state.mmk,
          isLoaded: true,
          responseFileError: action.payload,
        },
      };

    case 'SET_FILTER':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_ORDER':
      return {
        ...state,
        sortOrder: state.sortOrder === 'desc' ? 'asc' : 'desc',
      };

    case 'RESET_MMK':
      return {
        ...state,
        mmk: {
          isLoaded: false,
          data: [],
          responseError: null,
          responseFileError: null,
          startIndex: 0,
          count: DEFAULT_COUNT_SIZE,
          hasMore: true,
        },
      };

    default:
      return { ...state };
  }
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

const MmkProvider = ({ children }) => {
  const [state, setState] = React.useReducer(rootReducer, {
    mmk: {
      isLoaded: false,
      data: [],
      responseError: null,
      responseFileError: null,
      startIndex: 0,
      count: DEFAULT_COUNT_SIZE,
      hasMore: true,
    },
    recordTypeIdFilter: 0,
    specOrName: null,
    sortOrder: 'desc',
  });

  return (
    <MmkContext.Provider value={{ state, setState }}>
      {children}
    </MmkContext.Provider>
  );
};

const useMmkState = () => {
  const context = React.useContext(MmkContext);
  return context;
};

export { MmkProvider, MmkContext, useMmkState };
