import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Layout from '../components/Layout/Layout';

/* pages */
import Home from '../pages/home';
import Mmk from '../pages/mmk';
import Visit from '../pages/visit';
import Invoices from '../pages/invoices';
import Visits from '../pages/visits';
import Profile from '../pages/profile';
//import Anketa from '../pages/blank';
import Map from '../pages/map';
import PrescribedDrugs from '../pages/prescribed_drugs';
import Directions from '../pages/directions';
import Sessions from '../pages/sessions';
import Faq from '../pages/faq';
import CalypsoForms from '../pages/calypso_forms';
import Chat from '../pages/chat';
import Error from '../pages/error/Error';
import Confirm from '../pages/confirm';
import Certs from '../pages/certs';

/** ======== Private Routes =========== */
export default function PrivateRoutesPatient() {
  return (
    <Routes>
      <Route path="*" element={<Layout />}>
        <Route path="home" element={<Home />} />
        <Route
          path="visit/:specIdParam/:clinicId/:isOnlineParam/:fioParam/:favPram"
          element={<Visit />}
        />
        <Route
          path="visit/:specIdParam/:clinicId/:isOnlineParam/:fioParam"
          element={<Visit />}
        />
        <Route
          path="visit/:specIdParam/:clinicId/:isOnlineParam"
          element={<Visit />}
        />
        <Route
          path="visit/:specIdParam/:clinicId"
          element={<Visit />}
        />

        <Route path="visit/:specIdParam" element={<Visit />} />
        <Route path="visit" element={<Visit />} />

        <Route
          path="events_and_visits/:specificRecordId/:paramClinicId"
          element={<Visits />}
        />
        <Route path="events_and_visits" element={<Visits />} />

        <Route
          path="mmk/:specificRecordId/:paramClinicId"
          element={<Mmk />}
        />
        <Route path="mmk" element={<Mmk />} />

        <Route path="invoices" element={<Invoices />} />
        <Route
          path="invoices/:invoiceId/:paramClinicId"
          element={<Invoices />}
        />

        <Route path="directions" element={<Directions />} />
        <Route
          path="directions/:specificRecordId/:paramClinicId"
          element={<Directions />}
        />

        <Route
          path="drugs/:filterPrescribedDrug/:patientDirectionId/:paramClinicId"
          element={<PrescribedDrugs />}
        />
        <Route
          path="drugs/:filterPrescribedDrug"
          element={<PrescribedDrugs />}
        />
        <Route path="drugs" element={<PrescribedDrugs />} />

        <Route path="profile" element={<Profile />} />

        <Route path="sessions" element={<Sessions />} />

        <Route path="map" element={<Map />} />

        <Route path="chat" element={<Chat />} />
        <Route path="faq" element={<Faq />} />
        <Route
          path="calypsoForm/:formUUIDroute"
          element={<CalypsoForms />}
        />

        <Route path="certs" element={<Certs />} />

        <Route path="confirm/:token" element={<Confirm />} />

        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
  );
}
