import React from 'react';
import { DEFAULT_COUNT, itemId } from '../actions/visit';
import dayjs from 'dayjs';
import { useUserStateDispatch } from './UserContext';
export const DEFAULT_COUNT_SIZE = 15;
const VisitContext = React.createContext();

const defaultDate = () => {
  const today = new Date();
  // const hoursNow = today.getHours();
  // const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
  // return hoursNow <= 20 ? today : tomorrow;
  return dayjs(today);
};

const rootReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_VISIT_KINDS':
      return {
        ...state,
        visitKinds: {
          ...action.payload,
        },
        selectedVisitKindId: 0,
      };
    case 'RESET_VISIT_KINDS':
      return {
        ...state,
        visitKinds: {
          isLoaded: false,
          data: [],
        },
        selectedVisitKindId: 0,
      };

    case 'START_DOCTORS':
      return {
        ...state,
        doctors: {
          isLoaded: false,
          data: [],
          startIndex: 0,
          count: DEFAULT_COUNT,
          hasMore: true,
          responseError: null,
        },
      };
    case 'DOCTORS_LOADING':
      return {
        ...state,
        doctors: {
          ...state.doctors,
          isLoaded: false,
        },
      };

    case 'FETCH_DOCTORS':
      return {
        ...state,
        doctors: {
          isLoaded: true,
          data: [
            ...state.doctors.data.filter(
              (item) =>
                !action.payload
                  .map((it) => itemId(it))
                  .includes(item.itemId),
            ),
            ...action.payload.map((item) => ({
              ...item,
              timeSlotsIsLoaded: true,
              itemId: itemId(item),
            })),
          ],
          startIndex: state.doctors.startIndex + state.doctors.count,
          count: state.doctors.count,
          hasMore: action.payload.length === state.doctors.count,
        },
      };
    case 'REFREESH_DOCTORS':
      return {
        ...state,
        doctors: {
          ...state.doctors,
          isLoaded: true,
          data: [
            ...state.doctors.data.map((item) => {
              if (item.itemId === itemId(action.payload))
                return {
                  ...action.payload,
                  timeSlotsIsLoaded: true,
                  itemId: itemId(item),
                };
              return item;
            }),
          ],
        },
      };
    case 'REFREESH_DOCTOR_DATA':
      return {
        ...state,
        visitData: { ...state.visitData, ...action.payload },
      };

    case 'FETCH_DOCTOR_TIME_SLOT':
      return {
        ...state,
        doctors: {
          ...state.doctors,
          data: state.doctors.data.map((item) => {
            if (item.itemId === action.payload.itemId)
              return {
                ...item,
                timeSlots: action.payload.timeSlots,
                timeSlotsIsLoaded: action.payload.timeSlotsIsLoaded,
              };
            return item;
          }),
        },
      };
    case 'FETCH_DOCTOR_ERROR':
      return {
        ...state,
        doctors: {
          ...state.doctors,
          isLoaded: true,
          responseError: action.payload,
        },
      };

    case 'ADD_VISIT_SUBMITED':
      localStorage.removeItem('visitData');
      return {
        ...state,
        visit: {
          ...state.visit,
          isSubmited: true,
        },
        visitData: {},
      };
    case 'ADD_VISIT_SERVER_ERR':
      return {
        ...state,
        visit: {
          ...state.visit,
          ...action.payload,
        },
      };

    case 'RESET_VISIT':
      localStorage.removeItem('visitData');
      return {
        ...state,
        visit: {
          serverError: '',
          data: 0,
          isSubmited: false,
          isLoaded: false,
        },
        visitData: {},
      };
    case 'ADD_VISIT':
      localStorage.removeItem('visitData');
      return {
        ...state,
        visit: {
          ...state.visit,
          ...action.payload,
        },
        visitData: {},
      };

    case 'CANCEL_VISIT_CONFIRM':
      return {
        ...state,
        canceledVisit: {
          ...state.canceledVisit,
          ...action.payload,
          isConfirm: true,
        },
      };
    case 'CANCEL_VISIT_SUBMITED':
      return {
        ...state,
        canceledVisit: {
          ...state.canceledVisit,
          isSubmited: true,
        },
      };

    case 'CANCEL_VISIT_SERVER_ERR':
      return {
        ...state,
        canceledVisit: {
          ...state.canceledVisit,
          isLoaded: true,
          serverError: action.payload.serverError,
        },
      };

    case 'CANCEL_VISIT_REST':
      return {
        ...state,
        canceledVisit: {
          serverError: '',
          data: false,
          isConfirm: false,
          isSubmited: false,
          isLoaded: false,
          planningId: null,
          id: null,
        },
      };

    case 'CANCEL_VISIT':
      return {
        ...state,

        canceledVisit: {
          ...state.canceledVisit,
          ...action.payload,
        },
        visits: {
          ...state.visits,
          isLoaded: true,
          data: state.visits.data.map((item) =>
            item.id === action.payload.id
              ? {
                  ...item,
                  isCancelled: true,
                }
              : {
                  ...item,
                },
          ),
        },
      };

    case 'ICAME_VISIT_CONFIRM':
      return {
        ...state,
        iCameVisit: {
          ...state.iCameVisit,
          ...action.payload,
          isConfirm: true,
        },
      };
    case 'ICAME_VISIT_SUBMITED':
      return {
        ...state,
        iCameVisit: {
          ...state.iCameVisit,
          isSubmited: true,
        },
      };
    case 'ICAME_VISIT_SERVER_ERR':
      return {
        ...state,
        iCameVisit: {
          ...state.iCameVisit,
          isLoaded: true,
          serverError: action.payload.serverError,
        },
      };
    case 'ICAME_VISIT_REST':
      return {
        ...state,
        iCameVisit: {
          serverError: '',
          data: null,
          isConfirm: false,
          isSubmited: false,
          clinicId: null,
          id: null,
        },
      };
    case 'ICAME_VISIT':
      return {
        ...state,
        iCameVisit: {
          ...state.iCameVisit,
          ...action.payload,
          isConfirm: false,
        },
        visits: {
          ...state.visits,
          isLoaded: true,
          data: state.visits.data.map((item) =>
            item.id === action.payload.id
              ? {
                  ...item,
                  canAcceptICameMessage: false,
                }
              : {
                  ...item,
                },
          ),
        },
      };

    case 'CONFIRM_CREATE_PAYMENT':
      return {
        ...state,
        createInvoice: {
          ...state.createInvoice,
          ...action.payload,
          isConfirm: true,
        },
      };
    case 'CREATE_INVOICE_SUBMITED':
      return {
        ...state,
        createInvoice: {
          ...state.createInvoice,
          isSubmited: true,
        },
      };
    case 'CREATE_INVOICE_SERVER_ERR':
      return {
        ...state,
        createInvoice: {
          ...state.createInvoice,
          isLoaded: true,
          serverError: action.payload.serverError,
        },
      };

    case 'CREATE_INVOICE_REST':
      return {
        ...state,
        createInvoice: {
          serverError: '',
          data: null,
          isConfirm: false,
          isSubmited: false,
          clinicId: null,
          planningId: null,
          id: null,
        },
      };
    case 'CREATE_INVOICE':
      return {
        ...state,
        createInvoice: {
          ...state.createInvoice,
          ...action.payload,
          isConfirm: true,
        },
      };

    case 'SET_SELECTED_DATE':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_SELECTED_SPEC_OR_NAME':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_SELECTED_CLINIC_ID':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_SELECTED_VISITKIND_ID':
      return {
        ...state,
        ...action.payload,
      };

    case 'SET_IS_ONLINE':
      return {
        ...state,
        filterWithTimeSlotsOnly: action.payload.isOnline
          ? true
          : state.filterWithTimeSlotsOnly,
        isOnline: action.payload.isOnline,
      };
    case 'SET_WITH_SLOTS_ONLY':
      return {
        ...state,
        filterWithTimeSlotsOnly:
          action.payload.filterWithTimeSlotsOnly,
      };

    case 'SET_ORDER':
      return {
        ...state,
        sortOrder: action.payload,
      };
    case 'SET_CACHED':
      return {
        ...state,
        cachedTimeSlots: action.payload,
      };

    case 'SET_VISIT_DATA':
      return {
        ...state,
        visitData: { ...action.payload },
      };
    case 'SET_RATING_DATA':
      return {
        ...state,
        doctorRating: {
          ...state.doctorRating,
          isLoaded: true,
          ...action.payload,
        },
      };

    case 'SET_RATING_FETCH':
      return {
        ...state,
        doctorRating: {
          ...state.doctorRating,
          data: {
            ...state.doctorRating.data,
            reviews: [
              ...state.doctorRating.data.reviews,
              ...action.payload.data.reviews,
            ],
          },
          isLoaded: true,
          responseError: null,
          startIndex:
            state.doctorRating.startIndex + state.doctorRating.count,
          count: state.doctorRating.count,
          hasMore:
            action.payload.data?.reviews?.length &&
            action.payload.data?.reviews?.length <
              state.doctorRating.count
              ? false
              : true,
        },
      };

    case 'RESET_RATING_LOADING':
      return {
        ...state,
        doctorRating: {
          ...state.doctorRating,
          isLoaded: false,
        },
      };
    case 'RESET_RATING_DATA':
      return {
        ...state,
        doctorRating: {
          ...state.doctorRating,
          isLoaded: true,
          data: { reviews: [] },
          responseError: null,
          response: null,
          startIndex: 0,
          count: DEFAULT_COUNT_SIZE,
          hasMore: true,
        },
      };

    case 'FETCH_DIRECTIONS':
      return {
        ...state,
        directions: {
          ...action.payload,
        },
      };
    case 'FETCH_DIRECTIONS_ERROR':
      return {
        ...state,
        directions: {
          ...state.directions,
          isLoaded: true,
          responseError: action.payload,
        },
      };
    case 'FETCH_NEAREST_SLOTS':
      return {
        ...state,
        nearestSlots: {
          isLoaded: true,
          ...action.payload,
        },
      };
    case 'FETCH_NEAREST_SLOTS_LOADING':
      return {
        ...state,
        nearestSlots: {
          ...state.nearestSlots,
          isLoaded: false,
        },
      };
    default:
      return { ...state };
  }
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

const VisitProvider = ({ children }) => {
  const {
    userState: { appInfo },
  } = useUserStateDispatch();

  const visitData =
    JSON.parse(localStorage.getItem('visitData')) ?? {};

  const [state, setState] = React.useReducer(rootReducer, {
    doctors: {
      isLoaded: false,
      data: [],
      startIndex: 0,
      count: DEFAULT_COUNT,
      hasMore: true,
      responseError: null,
    },
    nearestSlots: {
      isLoaded: true,
      data: [],
    },
    docFreeDates: {
      isLoaded: false,
      data: [],
    },
    visit: {
      isLoaded: false,
      serverError: '',
      data: 0,
      isSubmited: false,
    },
    canceledVisit: {
      isLoaded: false,
      serverError: '',
      data: false,
      isConfirm: false,
      isSubmited: false,
      planningId: null,
      clinicId: null,
      mmkId: 'parent',
      id: null,
    },

    iCameVisit: {
      isLoaded: false,
      serverError: '',
      data: null,
      isConfirm: false,
      isSubmited: false,
      clinicId: null,
      id: null,
    },
    createInvoice: {
      isLoaded: false,
      serverError: '',
      data: null,
      isConfirm: false,
      isSubmited: false,
      clinicId: null,
      planningId: null,
      id: null,
    },

    visits: {
      isLoaded: false,
      data: [],
      startIndex: 0,
      count: DEFAULT_COUNT,
      hasMore: true,
      responseError: null,
    },
    directions: { isLoaded: false, data: [], responseError: null },
    selectedClinicId: 0,
    selectedDate: defaultDate(),
    selectedSpec: '',
    selectedName: '',
    selectedVisitKindId: 0,
    isOnline: false,
    filterWithTimeSlotsOnly: !!appInfo.filterWithTimeSlotsOnly,

    sortOrder: true,
    cachedTimeSlots: true,
    visitData,
    doctorRating: {
      isLoaded: true,
      data: { reviews: [] },
      responseError: null,
      response: null,
      startIndex: 0,
      count: DEFAULT_COUNT_SIZE,
      hasMore: true,
    },
  });

  return (
    <VisitContext.Provider value={{ state, setState }}>
      {children}
    </VisitContext.Provider>
  );
};

const useVisitState = () => {
  const context = React.useContext(VisitContext);

  return context;
};

export { VisitProvider, VisitContext, useVisitState };
