import React, { useMemo } from 'react';
import { TextField as Input } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiUIPicker from '../MUIDatePicker';

import {
  MAX_BIRTH_DATE_CHILD,
  MIN_BIRTH_DATE_CHILD,
} from '../../pages/visit/validationCreateVisit';
import dayjs from 'dayjs';

function ChildForm({
  values,
  errors,
  handleChange,
  setValues,
  setErrors,

  validate,
  appInfo,
}) {
  const { t } = useTranslation();

  const handleDateChange = (childBirthDate) => {
    childBirthDate =
      childBirthDate != null ? dayjs(childBirthDate) : null;
    const vals = {
      ...values,
      childBirthDate,
    };

    setValues(vals);
    setErrors(validate(vals, appInfo));
  };

  return useMemo(
    () => (
      <>
        <Input
          name="childLastName"
          variant="outlined"
          value={values?.childLastName || ''}
          onChange={handleChange}
          margin="normal"
          label={t('COMPONENT.FORM_LAST_NAME')}
          type="text"
          fullWidth
          required
          inputProps={{ maxLength: 50 }}
          error={errors?.childLastName != null}
          helperText={
            errors?.childLastName != null && errors?.childLastName
          }
        />
        <Input
          name="childFirstName"
          variant="outlined"
          value={values?.childFirstName || ''}
          onChange={handleChange}
          margin="normal"
          label={t('COMPONENT.FORM_NAME')}
          type="text"
          fullWidth
          required
          inputProps={{ maxLength: 50 }}
          error={errors?.childFirstName != null}
          helperText={
            errors?.childFirstName != null && errors?.childFirstName
          }
        />
        <Input
          name="childMiddleName"
          variant="outlined"
          value={values?.childMiddleName || ''}
          onChange={handleChange}
          margin="normal"
          label={t('COMPONENT.FORM_MIDLE_NAME')}
          type="text"
          fullWidth
          inputProps={{ maxLength: 50 }}
          error={errors?.childMiddleName != null}
          helperText={
            errors?.childMiddleName != null && errors?.childMiddleName
          }
        />
        <MuiUIPicker
          //id="date-picker-inline"
          label={t('COMPONENT.BIRTH_DATE')}
          value={
            values?.childBirthDate != null
              ? dayjs(values?.childBirthDate)
              : null
          }
          handleChange={handleDateChange}
          defaultValue={dayjs().add(MIN_BIRTH_DATE_CHILD, 'years')}
          minDate={dayjs().add(MIN_BIRTH_DATE_CHILD, 'years')}
          maxDate={
            MAX_BIRTH_DATE_CHILD === 0
              ? dayjs()
              : dayjs().add(MAX_BIRTH_DATE_CHILD, 'years')
          }
          required
          errorText={errors?.childBirthDate}
        />
      </>
    ),

    [values, errors],
  );
}

export default ChildForm;
