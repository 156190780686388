import React, { useEffect } from 'react';

import SignUpForm from '../../components/Forms/SignUpForm';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUserStateDispatch } from '../../context/UserContext';
import dayjs from 'dayjs';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

function SignUp({
  valsTab,

  setActiveTabId,

  values,
  errors,
  handleChange,
  handleSubmit,
  setValues,
  setErrors,

  isLoading,
  setIsLoading,
  serverResponse,
  setServerResponse,
  validateSignUp,
}) {
  const { t } = useTranslation();
  const {
    userState: { appInfo },
  } = useUserStateDispatch();
  useEffect(() => {
    const vals = {
      ...values,
      login: values?.phone,
    };
    setValues(vals);
    setErrors(validateSignUp(vals, appInfo));

    // eslint-disable-next-line
  }, []);

  const handleDateChange = (birthDate) => {
    birthDate = birthDate != null ? dayjs(birthDate) : null;
    const vals = {
      ...values,
      birthDate,
    };

    setValues(vals);
    setErrors(validateSignUp(vals, appInfo));
  };

  const handleChangeGender = (event) => {
    const vals = {
      ...values,
      gender: event.target.value,
    };
    setValues(vals);
    setErrors(validateSignUp(vals, appInfo));
  };

  return (
    <>
      <Typography
        variant="h4"
        style={{ textAlign: 'center', marginBottom: 24 }}
      >
        {t('SIGN.UP_BUTTON')}
      </Typography>
      <SignUpForm
        values={values}
        handleChange={handleChange}
        setValues={setValues}
        setErrors={setErrors}
        errors={errors}
        validate={validateSignUp}
        serverResponse={serverResponse}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setServerResponse={setServerResponse}
        handleSubmit={handleSubmit}
        handleDateChange={handleDateChange}
        handleChangeGender={handleChangeGender}
      />
      <Button
        color="primary"
        variant="text"
        onClick={() => {
          setValues({});
          setServerResponse('');
          setActiveTabId(valsTab?.activeTabIdPrev ?? 100);
        }}
        style={{ width: '100%', marginTop: 24 }}
      >
        {t('COMPONENT.BACK_ONE_STEP')}
      </Button>
    </>
  );
}

export default SignUp;
