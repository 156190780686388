import React from 'react';
import { DEFAULT_PAGE_SIZE } from '../actions/invoices';
import isEmpty from '../helpers';
import { useUserStateDispatch } from './UserContext';

const InvoicesContext = React.createContext();
const initInvoices = {
  isLoaded: false,
  data: [],
  pageIndex: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  hasMore: true,
  responseError: null,
};

const rootReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INVOICES':
      return {
        ...state,
        invoices: {
          isLoaded: true,
          data: [
            ...state.invoices.data.filter(
              (item) =>
                !action.payload.data
                  .map((it) => it.id)
                  .includes(item.id),
            ),
            ...action.payload.data.map((item) => ({
              ...item,
              invoiceDetails: { isLoaded: false, data: [] },
            })),
          ],
          pageIndex:
            state.invoices.pageIndex + state.invoices.pageSize,
          pageSize: state.invoices.pageSize,
          hasMore:
            action.payload.data.length === state.invoices.pageSize,
        },
      };
    case 'FETCH_INVOICES_ERROR':
      return {
        ...state,
        invoices: {
          ...state.invoices,
          isLoaded: true,
          responseError: action.payload,
        },
      };

    case 'FETCH_INVOICE_DETAILS': {
      localStorage.setItem('paymentUrl', action.payload.paidLink);
      return {
        ...state,
        invoices: {
          ...state.invoices,
          isLoaded: true,
          data: state.invoices.data.map((item) => ({
            ...item,
            invoiceDetails:
              item.id === action.payload.invoiceId
                ? { ...item.invoiceDetails, ...action.payload }
                : item.invoiceDetails,
          })),
        },
        paidData: {
          modalPaid: !!action.payload.paidLink,
          ...action.payload,
        },
      };
    }
    case 'FETCH_INVOICE_DETAILS_ERROR':
      localStorage.removeItem('paymentUrl');
      return {
        ...state,
        invoices: {
          ...state.invoices,
          isLoaded: true,
          data: state.invoices.data.map((item) => ({
            ...item,
            invoiceDetails:
              item.id === action.payload.invoiceId
                ? { ...action.payload }
                : item.invoiceDetails,
          })),
        },
        paidData: { isLoaded: true, modalPaid: false },
      };
    case 'FETCH_INVOICE':
      return {
        ...state,
        invoices: {
          ...state.invoices,
          isLoaded: true,
          data: !isEmpty(state.invoices.data)
            ? state.invoices.data.map((item) =>
                item.id === action.payload.invoiceId
                  ? {
                      ...action.payload.data,
                      invoiceDetails: item.invoiceDetails,
                    }
                  : {
                      ...item,
                    },
              )
            : [action.payload.data],
        },
      };

    case 'SET_FILTER':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_PAID_DATA':
      return {
        ...state,
        paidData: { ...state.paidData, ...action.payload },
      };
    case 'RESET_PAID_DATA': {
      localStorage.removeItem('paymentUrl');
      return {
        ...state,
        paidData: { isLoaded: true, modalPaid: false },
      };
    }
    case 'REST_INVOICES':
      return {
        ...state,
        invoices: initInvoices,
        //paymentStatus: 'Unpaid',
        //dateRange: [null, null],
      };

    default:
      return { ...state };
  }
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const InvoicesProvider = ({ children }) => {
  const {
    userState: { appInfo },
  } = useUserStateDispatch();

  const [state, setState] = React.useReducer(rootReducer, {
    invoices: initInvoices,

    //invoiceId: 0,
    //invoiceDetails: { isLoaded: false, data: [], paidLink: "" },
    paymentStatus: 'Unpaid',
    dateRange: [null, null],
    local: appInfo?.useLocalInvoices ?? true,
    paidData: {
      modalPaid: false,
      isLoaded: true,
    },
  });

  return (
    <InvoicesContext.Provider value={{ state, setState }}>
      {children}
    </InvoicesContext.Provider>
  );
};

const useInvoicesState = () => {
  const context = React.useContext(InvoicesContext);
  return context;
};

export { InvoicesProvider, InvoicesContext, useInvoicesState };
