import React from 'react';
import { Outlet } from 'react-router-dom';
import Container from '@mui/material/Container';

/* ui mui components */
import Divider from '@mui/material/Divider';
import { Grid } from '@mui/material';

import Footer from '../Footer';
import { Link, Typography } from '@mui/material';
/* lang */
import { useTranslation } from 'react-i18next';

import useStyles from './styles';
import HeaderOnlyLogo from '../Header/HeaderOnlyLogo';
import classNames from 'classnames';
import { useUserStateDispatch } from '../../context/UserContext';
import { getYearNow } from '../../helpers';

function LayoutOnlyLogo() {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    userState: { appInfo },
  } = useUserStateDispatch();
  return (
    <div className={classes.root}>
      <HeaderOnlyLogo />
      <Container
        className={classNames(
          classes.conteiner,
          classes.conteinerTopWhithoutSideBar,
        )}
      >
        <Outlet />

        <Footer>
          <Divider />

          <Grid container>
            <Grid item>
              <Typography className={classes.copyright}>
                {t('BOTTOM.COPY', { year: getYearNow() })}{' '}
                <Link to="/" className={classes.link}>
                  {appInfo.defTitle}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Footer>
      </Container>
    </div>
  );
}

export default LayoutOnlyLogo;
