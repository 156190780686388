import { useState, useEffect } from 'react';
import isEmpty, { getNumber } from '../helpers';

const useForm = (callback, validate, appInfo) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isEmpty(errors) && isSubmitting && callback != null) {
      callback();
      setIsSubmitting(false);
    }
  }, [isSubmitting]);

  const handleSubmit = () => {
    //if (event) event.preventDefault();
    if (callback != null && validate != null)
      setErrors(validate(values, appInfo));
    setIsSubmitting(true);
  };

  const handleChange = (event) => {
    //console.log('event event event ', event);
    if (event.persist) event.persist();

    /**/
    if (
      event.target.name === 'confirmationCode' ||
      event.target.name === 'code'
    ) {
      event.target.value = getNumber(event.target.value);
    }

    const vals = {
      ...values,
      [event.target.name]: event.target.value,
    };

    //console.log('== vals', vals);

    setValues(vals);
    if (
      callback != null &&
      validate != null &&
      event.target.value != null &&
      values[event.target.name] !== event.target.value
    ) {
      // console.log(
      //   '== event.target.value ===',
      //   event.target.value,
      //   'values[event.target.name]',
      //   values[event.target.name],
      //   'validate',
      //   validate,
      // );

      setErrors(validate(vals, appInfo));
    }
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
    setValues,
    setErrors,
  };
};

export default useForm;
