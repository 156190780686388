import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import { useUserStateDispatch } from '../../context/UserContext';
import { uploadUserPhoto } from '../../actions/user';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import { Typography, Button, Tooltip } from '@mui/material';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { deleteUserPhoto } from '../../actions/user';
import Loading from '../Loading';
import AvatarOnOffLine from './AvatarOnOffLine';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    background: theme.palette.baseButton.bgColor,
    boxSizing: 'border-box',
    minHeight: 325,
    padding: theme.spacing(4),
  },

  avatarFio: {
    marginTop: theme.spacing(2),
    color: '#fff',
  },
  avatarUpdate: {
    position: 'absolute',
    color: '#fff',
    width: '100%',
    height: '100%',
    paddingTop: '60%',
    background: 'rgba(0, 0, 0, 0.4)',
    borderRadius: '50%',
    opacity: 0,
    '&:hover': {
      opacity: 1,
    },
  },
}));

export default function Photo() {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    userState: {
      user: { lastName, firstName, middleName, photo },

      isLoaded,
      user,
    },
    userDispatch,
  } = useUserStateDispatch();

  const [open, setOpen] = useState(false);

  const MAX_FILE_SIZE = 20 * 1024 * 1024;
  const onChange = (e) => {
    if (e.target.files[0].size > MAX_FILE_SIZE) {
      userDispatch({
        type: 'SET_USER',
        payload: {
          serverResponse: {
            message: t('COMPONENT.FILE_TOO_MATCH', {
              maxFileSize: MAX_FILE_SIZE,
            }),
          },
        },
      });
      return;
    }

    userDispatch({
      type: 'SET_USER',
      payload: {
        isLoaded: false,
      },
    });

    uploadUserPhoto(userDispatch, user, e.target.files[0]);
  };
  const fio = `${lastName} ${firstName} ${middleName ?? ''}`;
  const srcUser = `data:image/jpeg;base64,${photo}`;

  const handleClose = () => {
    setOpen(false);
  };
  const submit = () => {
    userDispatch({
      type: 'SET_USER',
      payload: { isLoaded: false },
    });

    deleteUserPhoto(userDispatch, user);
  };
  const handleCloseOk = () => {
    setOpen(false);
    submit();
  };

  return (
    <Card className={classes.root}>
      {!isLoaded ? (
        <Loading />
      ) : (
        <>
          <input
            accept="image/*"
            className={classes.input}
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple
            type="file"
            name="myImage"
            onChange={onChange}
          />
          <label htmlFor="raised-button-file">
            <IconButton
              aria-haspopup="true"
              color="inherit"
              aria-controls="profile-menu"
              component="span"
            >
              <AvatarOnOffLine image={srcUser} size="medium" />
              <Typography
                variant="body2"
                className={classes.avatarUpdate}
                component="div"
              >
                {t('COMPONENT.BUT_UPDATE')}
              </Typography>
            </IconButton>
          </label>

          <div
            style={{
              position: 'relative',
              top: -34,
              left: 64,
            }}
          >
            <Tooltip title={t('PROFILE.DELETE_PHOTO')}>
              <IconButton onClick={() => setOpen(true)} size="small">
                <DeleteIcon style={{ color: '#fff' }} />
              </IconButton>
            </Tooltip>
          </div>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <Alert severity="warning">
                <Typography variant="body2">
                  {t('PROFILE.ARE_U_DELETE_PHOTO')}
                </Typography>
              </Alert>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="secondary">
                {t('COMPONENT.BUT_CLOSE')}
              </Button>
              <Button onClick={handleCloseOk} color="primary">
                {t('COMPONENT.BUT_CONFIRM')}
              </Button>
            </DialogActions>
          </Dialog>

          <Typography variant="h5" className={classes.avatarFio}>
            {fio}
          </Typography>
        </>
      )}
    </Card>
  );
}
