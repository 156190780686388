import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';

import { useCalypsoState } from '../../context/CalypsoContext';
import { calypsoAuth } from '../../actions/calypso';
import { Alert, Grid, Typography } from '@mui/material';
import { Calypso } from '../../types/calypso';
import isEmpty from '../../helpers';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading';
import { useUserStateDispatch } from '../../context/UserContext';
import config from '../../config';
import { useParams } from 'react-router-dom';
const useStyles = makeStyles(() => ({
  iframeContent: {
    width: '100%',
    height: 1200,
    minHeight: 960,
    border: 0,
    borderRadius: 4,
  },
}));
const urlForm = (item: Calypso) => {
  return `${item.address}/medialog/form/?formGuid=${item.formUUID}&token=${item.accessToken}&refreshToken=${item.refreshToken}&theme=${config.theme}`;
};
const CalypsoForm: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    userState: { calypsoData, user },
  } = useUserStateDispatch();
  const { formUUIDroute } = useParams();

  //console.log('formUUIDroute', formUUIDroute);
  const {
    state: { calypso },
    setState,
  } = useCalypsoState();

  useEffect(() => {
    const itemData = calypsoData.find(
      (item: Calypso) => item.formUUID === formUUIDroute,
    );
    if (!isEmpty(itemData))
      calypsoAuth(setState, itemData, user.mmkId, user.clinicId);
  }, [formUUIDroute]);

  //console.log('calypso', calypso);

  //return <>{calypso.data[0].title}</>;
  return (
    <>
      {!calypso.isLoaded ? (
        <Loading />
      ) : calypso.responseError != null ? (
        <Alert severity="warning">
          <Typography variant="h6" component="p">
            {calypso.responseError}
          </Typography>
        </Alert>
      ) : calypso.data != null ? (
        <Grid container key={calypso.data.formUUID}>
          <Grid item xs={12}>
            <iframe
              src={urlForm(calypso.data)}
              className={classes.iframeContent}
              title="Form"
              height="100%"
              width="100%"
            >
              no data
            </iframe>
          </Grid>
        </Grid>
      ) : (
        <Alert severity="warning">
          <Typography variant="h6" component="p">
            {t('CALIPSO.NO_RECORDS')}
          </Typography>
        </Alert>
      )}
    </>
  );
};

export default CalypsoForm;
