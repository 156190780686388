import React from 'react';
import { Grid, TextField as Input } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import Select from '@mui/material/Select';
// context
import { useUserStateDispatch } from '../../context/UserContext';

//form func
import { useTranslation } from 'react-i18next';
import InsuranceCompanyForm from './InsuranceCompanyForm';
import MuiUIPicker from '../MUIDatePicker';
import { reqRegField } from '../../helpers/validators';
import dayjs from 'dayjs';
import { handlePhoneChange } from '../../helpers/handlers';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function ProfileForm({
  values,
  errors,
  handleChange,
  setValues,
  setErrors,
  isDisable,

  validate,
  mmkId,
}) {
  const { t } = useTranslation();

  const {
    userState: { appInfo },
  } = useUserStateDispatch();

  const handleAnyChange = (val) => {
    const vals = {
      ...values,
      ...val,
    };
    console.log('vals', vals);
    setValues(vals);
    setErrors(validate(vals, appInfo));
  };

  return (
    <Grid container spacing={2}>
      {!appInfo.isSimpleRegistrationForm && (
        <>
          <Grid item xs={12} md={6}>
            <Input
              name="lastName"
              variant="outlined"
              value={values.lastName || ''}
              onChange={handleChange}
              margin="normal"
              label={t('COMPONENT.FORM_LAST_NAME')}
              type="text"
              fullWidth
              inputProps={{ maxLength: 50 }}
              required={reqRegField(
                appInfo.requiredRegistrationFields,
                'lastName',
              )}
              disabled={isDisable('lastName')}
              error={errors?.lastName != null}
              helperText={
                errors?.lastName != null && errors?.lastName
              }
            />
            <Input
              name="firstName"
              variant="outlined"
              value={values.firstName || ''}
              onChange={handleChange}
              margin="normal"
              label={t('COMPONENT.FORM_NAME')}
              type="text"
              fullWidth
              inputProps={{ maxLength: 50 }}
              required={reqRegField(
                appInfo.requiredRegistrationFields,
                'firstName',
              )}
              disabled={isDisable('firstName')}
              error={errors?.firstName != null}
              helperText={
                errors?.firstName != null && errors?.firstName
              }
            />

            <Input
              name="middleName"
              variant="outlined"
              value={values.middleName || ''}
              onChange={handleChange}
              margin="normal"
              label={t('COMPONENT.FORM_MIDLE_NAME')}
              type="text"
              fullWidth
              inputProps={{ maxLength: 50 }}
              disabled={isDisable('middleName')}
              error={errors?.middleName != null}
              helperText={
                errors?.middleName != null && errors?.middleName
              }
            />
            <FormControl
              variant="outlined"
              margin="normal"
              fullWidth
              required={reqRegField(
                appInfo.requiredRegistrationFields,
                'gender',
              )}
              error={errors.gender != null && errors.gender}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                {t('COMPONENT.FORM_GENDER')}
              </InputLabel>
              <Select
                //labelId="demo-simple-select-outlined-label"
                //id="demo-simple-select-outlined"
                name="gender"
                disabled={isDisable('gender')}
                value={values.gender != null ? values.gender : ''}
                onChange={handleChange}
                label={t('COMPONENT.FORM_GENDER')}
              >
                <MenuItem value="M">
                  {t('COMPONENT.FORM_GENDER_M')}
                </MenuItem>
                <MenuItem value="F">
                  {t('COMPONENT.FORM_GENDER_F')}
                </MenuItem>
              </Select>
              <FormHelperText>
                {errors.gender != null && errors.gender}
              </FormHelperText>
            </FormControl>
          </Grid>
        </>
      )}
      <Grid
        item
        xs={12}
        md={!appInfo.isSimpleRegistrationForm ? 6 : 12}
      >
        {mmkId === 'parent' && (
          <>
            {appInfo.authIdentifiers !== 'phone' && (
              <Input
                name="email"
                variant="outlined"
                value={values.email || ''}
                onChange={handleChange}
                margin="normal"
                label={t('COMPONENT.FORM_EMAIL')}
                type="email"
                fullWidth
                disabled={isDisable('email')}
                error={errors?.email != null}
                helperText={errors?.email != null && errors?.email}
              />
            )}

            {errors.emailAndPhone != null && (
              <FormHelperText>{errors.emailAndPhone}</FormHelperText>
            )}
            {appInfo.authIdentifiers !== 'email' && (
              <Input
                name="phone"
                variant="outlined"
                value={values.phone || ''}
                onChange={(e) =>
                  handlePhoneChange(
                    e,
                    values,
                    setValues,
                    setErrors,
                    validate,
                    appInfo,
                  )
                }
                //  disabled={values.oldEmail == null}
                disabled={isDisable('phone')}
                margin="normal"
                label={t('COMPONENT.FORM_PHONE')}
                type="text"
                fullWidth
                error={errors?.phone != null}
                helperText={errors?.phone != null && errors?.phone}
              />
            )}
          </>
        )}
        {!appInfo.isSimpleRegistrationForm && (
          <MuiUIPicker
            label={t('COMPONENT.BIRTH_DATE')}
            value={
              values.birthDate != null
                ? dayjs(values.birthDate)
                : null
            }
            handleChange={(birthDate) => {
              birthDate = birthDate != null ? dayjs(birthDate) : null;
              handleAnyChange({ birthDate });
            }}
            defaultValue={dayjs().add(appInfo.ageLimitHigh, 'years')}
            minDate={dayjs().add(appInfo.ageLimitLow, 'years')}
            maxDate={dayjs().add(appInfo.ageLimitHigh, 'years')}
            disabled={isDisable('birthDate')}
            required={reqRegField(
              appInfo.requiredRegistrationFields,
              'birthDate',
            )}
            errorText={errors?.birthDate}
          />
        )}
        <Input
          variant="filled"
          value={values.id || ''}
          margin="normal"
          label={t('COMPONENT.FORM_USER_ID')}
          type="text"
          fullWidth
        />
      </Grid>
      {!appInfo.isSimpleRegistrationForm && (
        <InsuranceCompanyForm
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleAnyChange={handleAnyChange}
          isDisable={isDisable}
        />
      )}
    </Grid>
  );
}

export default ProfileForm;
