import React, { useEffect, useState } from 'react';
import SignIn from './SignIn';
import FIOandPhone from './FIOandPhone';

import SignUp from './SignUp';
import SignInConfirm from '../sign_in_up/SignInConfirm';
import {
  createUser,
  singInUpConfirmationCode,
  loginUser,
} from '../../actions/user';
import useForm from '../../hooks/useForm';
import { validate as validateSignUp } from '../../pages/sign_in_up/validationSignUp';
import validateConfirmSignUp from '../../pages/sign_in_up/validationConfirmSignUp';
import validateSignIn from '../../pages/sign_in_up/validationSignIn';
import validateCreateVisit from './validationCreateVisit';
import { cleanPhoneValue } from '../../helpers/utils';

import { dateToHL7 } from '../../helpers/dateFormat';
import { useNavigate } from 'react-router-dom';
import { useUserStateDispatch } from '../../context/UserContext';
import { sendRequestEmailToClinic } from '../../actions/visit';
import { useVisitState } from '../../context/VisitContext';
import ChangePassword from '../sign_in_up/ChangePassword';
import { makeStyles } from '@mui/styles';
import { useLanguageValue } from '../../context/LanguageContext';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  conteiner: {
    [theme.breakpoints.up('sm')]: {
      margin: `0 ${theme.spacing(4)}`,
      minWidth: theme.spacing(61),
    },
  },
}));
/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function Sign({ visitData, toggleModal }) {
  const classes = useStyles();
  const {
    languageState: { language },
  } = useLanguageValue();

  const [valsTab, setValsTab] = useState({
    activeTabId: 100,
    ...config.defLoginPassword,
  });

  const setActiveTabId = (activeTabId) => {
    setValsTab({
      ...valsTab,
      activeTabIdPrev: valsTab.activeTabId,
      activeTabId,
    });
  };
  // local
  const [isLoading, setIsLoading] = useState(false);
  const [serverResponse, setServerResponse] = useState(null);

  const navigate = useNavigate();
  const {
    userDispatch,
    userState: { appInfo },
  } = useUserStateDispatch();
  const { t } = useTranslation();
  const { setState } = useVisitState();
  const { visitDate, doctor } = visitData;

  const createVisit = () => {
    sendRequestEmailToClinic({
      setState,
      lastName: values.lastName,
      firstName: values.firstName,
      middleName: values.middleName,
      phone: cleanPhoneValue(values.phone),
      plSubjId: doctor.plSubjId,
      visitDate,
      clinicId: doctor.clinicId,
    });
    toggleModal(visitDate);
  };

  const login = () =>
    loginUser(
      userDispatch,
      values?.login,
      values?.password,
      navigate,
      setIsLoading,
      setServerResponse,
      setValsTab,
      language,
      appInfo.isAnonymousChildrenEnabled,
    );

  const signUp = () =>
    createUser(
      {
        ...values,
        birthDate: values.birthDate
          ? dateToHL7(values.birthDate.toDate())
          : null,
      },
      setIsLoading,
      setServerResponse,
      appInfo.countryCode,
    );

  const confirmSignUp = () =>
    singInUpConfirmationCode(
      {
        ...values,
        birthDate: values.birthDate
          ? dateToHL7(values.birthDate.toDate())
          : null,
      },
      setIsLoading,
      setServerResponse,
    );
  const isConfirmForm =
    serverResponse?.action === 'CONFIRM_CODE_SENT' ||
    serverResponse?.action === 'WRONG_CONFIRMATION_CODE';

  let submit,
    validate = null;
  if (valsTab.activeTabId === 100) {
    submit = createVisit;
    validate = validateCreateVisit;
  }
  if (valsTab.activeTabId === 0) {
    submit = login;
    validate = validateSignIn;
  }
  if (valsTab.activeTabId === 1) {
    submit = isConfirmForm ? confirmSignUp : signUp;
    validate = isConfirmForm ? validateConfirmSignUp : validateSignUp;
  }
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
  } = useForm(submit, validate, appInfo);

  useEffect(() => {
    if (valsTab.activeTabId === 100) {
      setValues({
        ...values,
        needPhone: true,
      });
    }
    // eslint-disable-next-line
  }, [valsTab.activeTabId]);

  useEffect(() => {
    if (serverResponse?.action === 'MAY_LOGIN') {
      createVisit();
      setTimeout(() => login(), 3000);
    }
    // eslint-disable-next-line
  }, [serverResponse?.action]);

  return (
    <Box className={classes.conteiner}>
      {valsTab.activeTabId === 100 ? (
        <FIOandPhone
          setActiveTabId={setActiveTabId}
          visitData={visitData}
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setValues={setValues}
          setErrors={setErrors}
          isLoading={isLoading}
          validate={validateCreateVisit}
        />
      ) : valsTab.activeTabId === 0 ? (
        valsTab?.warningNote === 'CONFIRM_AUTH' ? (
          <>
            <Typography
              variant="h4"
              sx={{ textAlign: 'center', marginBottom: 3 }}
            >
              {t('SIGN.CH_CONFIRM_CODE')}
            </Typography>
            <SignInConfirm />
          </>
        ) : (
          <SignIn
            setActiveTabId={setActiveTabId}
            values={values}
            errors={errors}
            handleChange={handleChange}
            setValues={setValues}
            setErrors={setErrors}
            validate={validate}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            serverResponse={serverResponse}
          />
        )
      ) : valsTab.activeTabId === 1 ? (
        <SignUp
          valsTab={valsTab}
          setActiveTabId={setActiveTabId}
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setValues={setValues}
          setErrors={setErrors}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          serverResponse={serverResponse}
          setServerResponse={setServerResponse}
          validateSignUp={validateSignUp}
        />
      ) : valsTab.activeTabId === 2 ? (
        <>
          <Typography
            variant="h4"
            sx={{ textAlign: 'center', marginBottom: 3 }}
          >
            {t('SIGN.CH_PASS_BUTTON')}
          </Typography>
          <ChangePassword setValsTab={setValsTab} valsTab={valsTab} />
          <Button
            color="primary"
            variant="text"
            onClick={() => setActiveTabId(0)}
            style={{ width: '100%', marginTop: 24 }}
          >
            {t('COMPONENT.BACK_ONE_STEP')}
          </Button>
        </>
      ) : null}
    </Box>
  );
}

export default Sign;
