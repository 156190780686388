import React from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {
  useLanguageValue,
  LANGUAGES_ACTIONS,
  LANGUAGES,
} from '../../context/LanguageContext';

import { useUserStateDispatch } from '../../context/UserContext';

const useStyles = makeStyles(() => ({
  buttonGroupCastom: {
    border: 0,
    width: '100%',
    height: 42,
  },
}));

export default function UsersLanguageButtons() {
  const classes = useStyles();
  const { languageState, dispatchLanguage } = useLanguageValue();
  const {
    userState: { appInfo },
  } = useUserStateDispatch();

  const handleChangeLang = (e, langStatus) => {
    localStorage.setItem('lang', langStatus);
    switch (langStatus) {
      case LANGUAGES.RU:
        dispatchLanguage({ type: LANGUAGES_ACTIONS.SET_RUSSIAN });
        return;
      case LANGUAGES.FR:
        dispatchLanguage({ type: LANGUAGES_ACTIONS.SET_FRENCH });
        return;
      case LANGUAGES.EN:
        dispatchLanguage({ type: LANGUAGES_ACTIONS.SET_ENGLISH });
        return;
      default:
        dispatchLanguage({ type: LANGUAGES_ACTIONS.SET_RUSSIAN });
    }
  };
  return (
    <ToggleButtonGroup
      value={languageState.language}
      exclusive
      onChange={handleChangeLang}
      aria-label="sweetch-langs"
    >
      {Object.keys(LANGUAGES)
        .filter((it) => appInfo.availableLanguages.includes(it))
        .map((key) => (
          <ToggleButton
            key={key}
            value={LANGUAGES[key]}
            aria-label={LANGUAGES[key]}
            className={classes.buttonGroupCastom}
          >
            <Typography>{LANGUAGES[key]}</Typography>
          </ToggleButton>
        ))}
    </ToggleButtonGroup>
  );
}
