import React, { useState } from 'react';
import { Grid, Tabs, Tab, Box } from '@mui/material';
import { Link } from 'react-router-dom';

//components
import { Typography, Link as LinkBase } from '@mui/material';

import SignIn from './SignIn';
import SignUp from './SignUp';
import ChangePassword from './ChangePassword';

import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';

import UsersLanguageMenu from '../../components/Header/UsersLanguageMenu';
import UsersLanguageButtons from '../../components/Header/UsersLanguageButtons';

import config from '../../config';
import logoRuStore from '../../images/ruStoreSmall.svg';
import logoAppStore from '../../images/appStoreSmall.svg';
import googlePlay from '../../images/googlePlaySmall.svg';

import SignInConfirm from './SignInConfirm';
import { makeStyles } from '@mui/styles';
import SignInQr from './SignInQr';
import { useUserStateDispatch } from '../../context/UserContext';
import custom from '../../custom';
import СlinicWebsiteButtonSign from '../../components/Header/СlinicWebsiteButtonSign';
import LoginDoctorButton from '../../components/Header/LoginDoctorButton';
import { getYearNow, isPetKey } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  topbg: {
    width: '100%',
    height: 112,
    background:
      'radial-gradient(50% 50% at 50% 50%, #D0DBED 0%, rgba(208, 219, 237, 0) 100%)',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  topbghr: {
    width: '100%',
    height: 1,
    background:
      'radial-gradient(50% 50% at 50% 50%, #D0DBED 0%, rgba(208, 219, 237, 0) 100%)',
    position: 'absolute',
    top: 84,
    left: 0,
    zIndex: 99,
  },
  top: {
    width: '100%',
    height: 84,
    backgroundColor: '#FFFFFF',
    position: 'absolute',
    top: 0,
    left: 0,
    textAlign: 'center',
    zIndex: 100,
  },
  topContainer: {
    width: '100%',
    height: '100%',
    paddingLeft: '5%',

    [theme.breakpoints.down('md')]: {
      paddingLeft: '3%',
    },
    display: 'flex',
    overflow: 'hide',
    alignItems: 'center',
    zIndex: 101,
  },
  divide: {
    width: 2,
    height: theme.spacing(4),
    backgroundColor: '#B2B6C2',
    margin: '0 16px !important',
  },
  leftContainer: {
    width: '55%',
    height: '100%',
    paddingLeft: '5%',
    [theme.breakpoints.down('md')]: {
      padding: 0,
      width: '90%',
      overflow: 'visible',
      //textAlign: "center"
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '89vh',
    },
  },
  bottom: {
    width: '100%',
    backgroundColor: 'white',
  },
  bottomContainer: {
    width: '100%',
    height: '100%',
    paddingLeft: '5%',
    paddingBottom: '2%',
    paddingRight: '10%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '3%',
      paddingRight: '2%',
      paddingBottom: '3%',
    },
  },

  formContainer: {
    width: '100%',
    height: '89%',
    display: 'flex',
    flexDirection: 'column',
    //overflow: "auto",
  },

  heroImgContainer: {
    width: '45%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    overflow: 'none',
  },

  logotypeImage: {
    //  width: 154,
  },

  heroImage: {
    width: '84%',
    height: '84%',
    marginTop: '16%',
    marginBottom: theme.spacing(4),
  },
  logotypeText: {
    color: 'white',
    fontWeight: 500,
    fontSize: 84,
    [theme.breakpoints.down('md')]: {
      fontSize: 48,
    },
  },
  form: {
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  tab: {
    fontWeight: 500,
    fontSize: 18,
    textTransform: 'none',
  },
  greeting: {
    //fontWeight: 600,
    //textAlign: "center",
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(4),
  },
  toRight: {
    marginLeft: 'auto',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(8),
    },
  },
  signBtn: {
    marginRight: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  copyright: {
    color: '#B2B6C2',
    fontSize: 14,
    marginTop: theme.spacing(4),

    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
  },
  marginBottom: {
    marginBottom: theme.spacing(8),
  },
  marginTop: {
    marginTop: theme.spacing(8),
  },
  marginGoogleButtons: {
    margin: `${theme.spacing(2.5)} ${theme.spacing(2)} 0 0`,
  },

  appLink: {
    paddingRight: theme.spacing(2),
  },
}));

function Sign() {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    userState: { appInfo },
  } = useUserStateDispatch();
  const GooglePlayIcon = custom.GooglePlayIcon;
  const AppStoreIcon = custom.AppStoreIcon;
  const RuStoreIcon = custom.RuStoreIcon;

  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  );

  const [valsTab, setValsTab] = useState({
    activeTabId: 0,
    ...config.defLoginPassword,
  });
  const forPet = appInfo?.uiType === 'petClinic';
  const inHead = t(isPetKey('SIGN.IN_HEAD', forPet));

  return (
    <Grid container className={classes.container}>
      <div className={classes.topbg}></div>
      <div className={classes.topbghr}></div>
      <div className={classes.top}>
        <div className={classes.topContainer}>
          <div>
            <img
              src={custom.logoHero}
              alt={inHead}
              className={classes.logotypeImage}
            />
          </div>
          {appInfo.showStoreLinks && (
            <>
              <Box ml={8} />
              {appInfo.linkAppStore !== '' && (
                <LinkBase href={appInfo.linkAppStore} target="_blank">
                  <img src={logoAppStore} alt={inHead} />
                </LinkBase>
              )}
              {appInfo.linkAppStore !== '' &&
                (appInfo.linkGooglePlay !== '' ||
                  appInfo.linkRuStore !== '') && (
                  <div className={classes.divide}></div>
                )}

              {appInfo.linkGooglePlay !== '' && (
                <LinkBase
                  href={appInfo.linkGooglePlay}
                  target="_blank"
                >
                  <img src={googlePlay} alt={inHead} />
                </LinkBase>
              )}

              {(appInfo.linkAppStore !== '' ||
                appInfo.linkGooglePlay !== '') &&
                appInfo.linkRuStore !== '' && (
                  <div className={classes.divide}></div>
                )}

              {appInfo.linkRuStore !== '' && (
                <LinkBase href={appInfo.linkRuStore} target="_blank">
                  <img src={logoRuStore} alt={inHead} />
                </LinkBase>
              )}
            </>
          )}
          <div className={classes.toRight}>
            {appInfo.clinicWebsiteUrl && !isMobile && (
              <СlinicWebsiteButtonSign />
            )}

            {appInfo.showLanguageSwitch &&
              (isMobile ? (
                <UsersLanguageMenu />
              ) : (
                <UsersLanguageButtons />
              ))}

            {appInfo.isDoctorAuthEnabled && !isMobile && (
              <LoginDoctorButton />
            )}
          </div>
        </div>
      </div>
      <div className={classes.leftContainer}>
        <div className={classes.formContainer}>
          <Typography variant="h1" className={classes.greeting}>
            {inHead}
          </Typography>
          <div className={classes.form}>
            <Tabs
              value={valsTab.activeTabId}
              onChange={(e, id) =>
                setValsTab({ ...valsTab, activeTabId: id })
              }
              indicatorColor="primary"
              textColor="primary"
              style={{ marginBottom: 24 }}
              variant="scrollable"
              allowScrollButtonsMobile
            >
              <Tab
                label={t('SIGN.IN_BUTTON')}
                classes={{ root: classes.tab }}
              />
              <Tab
                label={t('SIGN.UP_BUTTON')}
                classes={{ root: classes.tab }}
              />

              {
                <Tab
                  label={t('SIGN.CH_PASS_BUTTON')}
                  classes={{ root: classes.tab }}
                />
              }
              {appInfo.showQrCodeAuth && (
                <Tab
                  label={t('SIGN.IN_BY_QR')}
                  classes={{ root: classes.tab }}
                />
              )}
            </Tabs>

            {valsTab.activeTabId === 0 &&
              (valsTab?.warningNote === 'CONFIRM_AUTH' ? (
                <SignInConfirm />
              ) : (
                <SignIn valsTab={valsTab} setValsTab={setValsTab} />
              ))}
            {valsTab.activeTabId === 1 && (
              <SignUp setValsTab={setValsTab} />
            )}
            {valsTab.activeTabId === 2 && (
              <ChangePassword
                valsTab={valsTab}
                setValsTab={setValsTab}
              />
            )}
            {valsTab.activeTabId === 3 && <SignInQr />}
          </div>

          {valsTab.activeTabId === 0 && (
            <Typography variant="body2" mb={6}>
              {t(isPetKey('SIGN.IN_TEXT', forPet))}
            </Typography>
          )}
        </div>
      </div>
      <div className={classes.heroImgContainer}>
        <img
          src={custom.heroImg}
          alt={inHead}
          className={classes.heroImage}
        />
      </div>
      <div className={classes.bottom}>
        <div className={classes.bottomContainer}>
          <Divider />
          <Grid container>
            {appInfo.showStoreLinks && (
              <Grid item className={classes.marginGoogleButtons}>
                {appInfo.linkGooglePlay !== '' && (
                  <LinkBase
                    href={appInfo.linkGooglePlay}
                    target="_blank"
                    className={classes.appLink}
                  >
                    <GooglePlayIcon />
                  </LinkBase>
                )}
                {appInfo.linkAppStore !== '' && (
                  <LinkBase
                    href={appInfo.linkAppStore}
                    target="_blank"
                    className={classes.appLink}
                  >
                    <AppStoreIcon />
                  </LinkBase>
                )}
                {appInfo.linkRuStore !== '' && (
                  <LinkBase
                    href={appInfo.linkRuStore}
                    target="_blank"
                    className={classes.appLink}
                  >
                    <RuStoreIcon />
                  </LinkBase>
                )}
              </Grid>
            )}
            <Grid item className={classes.marginGoogleButtons}>
              {appInfo.clinicWebsiteUrl && isMobile && (
                <СlinicWebsiteButtonSign />
              )}
            </Grid>
            <Grid item>
              <Typography className={classes.copyright}>
                {t('BOTTOM.COPY', { year: getYearNow() })}{' '}
                <Link to="/" className={classes.link}>
                  {custom.defTitle}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </Grid>
  );
}

export default Sign;
