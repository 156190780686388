import React, { useEffect, useState } from 'react';

import {
  createUser,
  singInUpConfirmationCode,
  loginUser,
} from '../../actions/user';

import useForm from '../../hooks/useForm';
import { validate as validateSignUp } from './validationSignUp';
import validateConfirmSignUp from './validationConfirmSignUp';

import { dateToHL7 } from '../../helpers/dateFormat';
import SignUpForm from '../../components/Forms/SignUpForm';
import { useUserStateDispatch } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

function SignUp({ setValsTab }) {
  // local
  const [isLoading, setIsLoading] = useState(false);
  const [serverResponse, setServerResponse] = useState(null);

  const navigate = useNavigate();
  const {
    userState: {
      user: { lang },
      appInfo,
    },
    userDispatch,
  } = useUserStateDispatch();
  const signUp = () =>
    createUser(
      {
        ...values,
        birthDate: values.birthDate
          ? dateToHL7(values.birthDate.toDate())
          : null,
      },
      setIsLoading,
      setServerResponse,
      appInfo.countryCode,
    );
  const login = () =>
    loginUser(
      userDispatch,
      values?.login,
      values?.password,
      navigate,
      setIsLoading,
      setServerResponse,
      setValsTab,
      lang,
      appInfo.isAnonymousChildrenEnabled,
    );
  const confirmSignUp = () =>
    singInUpConfirmationCode(
      {
        ...values,
        birthDate: values.birthDate
          ? dateToHL7(values.birthDate.toDate())
          : null,
      },
      setIsLoading,
      setServerResponse,
    );

  const isConfirmForm =
    serverResponse?.action === 'CONFIRM_CODE_SENT' ||
    serverResponse?.action === 'WRONG_CONFIRMATION_CODE';
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
  } = useForm(
    isConfirmForm ? confirmSignUp : signUp,
    isConfirmForm ? validateConfirmSignUp : validateSignUp,
    appInfo,
  );

  useEffect(() => {
    if (serverResponse?.action === 'MAY_LOGIN') login();
    // eslint-disable-next-line
  }, [serverResponse?.action]);

  const handleDateChange = (birthDate) => {
    birthDate = birthDate != null ? dayjs(birthDate) : null;

    const vals = {
      ...values,
      birthDate,
    };

    setValues(vals);
    setErrors(validateSignUp(vals, appInfo));
  };

  const handleChangeGender = (event) => {
    const vals = {
      ...values,
      gender: event.target.value,
    };
    setValues(vals);
    setErrors(validateSignUp(vals, appInfo));
  };
  //  console.log('\n\n=== values ===\n\n', values);
  return (
    <SignUpForm
      values={values}
      handleChange={handleChange}
      setValues={setValues}
      setErrors={setErrors}
      errors={errors}
      validate={validateSignUp}
      serverResponse={serverResponse}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      setServerResponse={setServerResponse}
      handleSubmit={handleSubmit}
      handleDateChange={handleDateChange}
      handleChangeGender={handleChangeGender}
    />
  );
}

export default SignUp;
