import React from 'react';
import { HashRouter } from 'react-router-dom';

import { Helmet } from 'react-helmet';

// hooks
import { useTranslation } from 'react-i18next';

import InitApp from './InitApp';
import AppRoutes from '../routes';

import custom from '../custom';
import { isPetKey } from '../helpers';
import config from '../config';
import { useUserStateDispatch } from '../context/UserContext';

export default function App() {
  const { t } = useTranslation();
  const {
    userState: { appInfo },
  } = useUserStateDispatch();
  const forPet = appInfo?.uiType === 'petClinic';

  return (
    <HashRouter>
      <InitApp />
      <Helmet>
        <title>
          {custom?.defTitle ?? ''} -{' '}
          {t(isPetKey('SIGN.IN_HEAD', forPet))}
        </title>
        <meta
          name="description"
          content={t(isPetKey('SIGN.IN_TEXT', forPet))}
        />
        {config.theme === 'white_fang' && (
          <script
            type="text/javascript"
            src={`/white_fang/mango-chat.min.js?time=${Date.now()}`}
          ></script>
        )}
      </Helmet>
      <AppRoutes />
    </HashRouter>
  );
}
