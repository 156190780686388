import React from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { LeftPlace } from '../../components/Wrappers/Wrappers';
import DoctorInfoUserAvatarCard from './DoctorInfoUserAvatarCard';
import DoctorInfoSlots from './DoctorInfoSlots';
import DoctorInfoUserCalendar from './DoctorInfoUserCalendar';
import DoctorInfoAbout from './DoctorInfoAbout';
import { useVisitState } from '../../context/VisitContext';

const useStyles = makeStyles((theme) => ({
  marginBottom: { marginBottom: theme.spacing(2) },
  marginTop: { marginTop: theme.spacing(2) },
  content: {
    minWidth: 852,
    [theme.breakpoints.down('md')]: {
      minWidth: 320,
    },
    height: '100%',
    paddingBottom: theme.spacing(4),
  },

  leftConent: {
    textAlign: 'center',
    justifySelf: 'center',
    minWidth: 322,
    paddingBottom: theme.spacing(1),
    boxSizing: 'border-box',
  },
  rightConent: {
    height: '100%',
    minWidth: 464,
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      minWidth: 'auto',
      marginTop: theme.spacing(2),
    },
  },

  leftPlace: {
    alignSelf: 'center',
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function DoctorInfo({
  modalDoc,
  toggleModalCreateVisit,
  setModalDoc,
  isOnline,
}) {
  const classes = useStyles();
  const {
    state: {
      visitData: { doctor, visitKind },
    },
  } = useVisitState();

  return (
    <Grid
      container
      spacing={0}
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.content}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        className={classes.leftConent}
      >
        <LeftPlace className={classes.leftPlace}>
          <DoctorInfoUserAvatarCard
            doctor={doctor}
            visitKind={visitKind}
          />
          <DoctorInfoUserCalendar
            isOnline={isOnline}
            modalDoc={modalDoc}
          />
        </LeftPlace>
      </Grid>
      <Grid item xs={12} sm className={classes.rightConent}>
        <DoctorInfoSlots
          toggleModalCreateVisit={toggleModalCreateVisit}
          setModalDoc={setModalDoc}
          isOnline={isOnline}
        />

        <DoctorInfoAbout />
      </Grid>
    </Grid>
  );
}
