const locale = (lang) => {
  switch (lang) {
    case 'en':
      return 'en-US';
    case 'fr':
      return 'fr-FR';
    default:
      return 'ru-RU';
  }
};

export const currencyFomated = (string, lang, currency = 'RUB') => {
  const number = Number.parseInt(string, 10);
  if (Number.isNaN(number)) {
    return '';
  }
  return number.toLocaleString(locale(lang), {
    style: 'currency',
    currency,
  });
};
