import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  loginByToken,
  accountRole,
  getDictsData,
  loginUserData,
  authDataDoctor,
  changeUser,
} from '../actions/user';

// context
import { useUserStateDispatch } from '../context/UserContext';
import { useLanguageValue } from '../context/LanguageContext';

import {
  Alert,
  Dialog,
  DialogContent,
  Typography,
} from '@mui/material';
import isEmpty from '../helpers';

// ----------------------------------------------------------------------

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

const ModalError = ({ serverResponseDictsError }) => {
  return (
    <Dialog open={true} keepMounted>
      <DialogContent>
        <Alert severity="error">
          <Typography variant="h6">
            {serverResponseDictsError}
          </Typography>
        </Alert>
      </DialogContent>
    </Dialog>
  );
};

export default function InitApp() {
  const { userState, userDispatch } = useUserStateDispatch();

  const {
    isAuthenticated,
    isAuthenticatedDoctor,
    user,
    serverResponseDictsError,
    appInfo,
  } = userState;
  const {
    languageState: { language },
  } = useLanguageValue();

  const navigate = useNavigate();

  const role = user?.role || accountRole.patient;
  const clinicId = user?.clinicId || 0;

  const gettinUserData = (isAnonymousChildrenEnabled) => {
    if (isAuthenticated) {
      loginUserData({
        dispatch: userDispatch,
        lang: language,
        navigate,
        role,
        isAnonymousChildrenEnabled,
      });
    }
    if (isAuthenticatedDoctor) {
      authDataDoctor(userDispatch, language, clinicId);
    }
  };

  useEffect(() => {
    getDictsData(userDispatch, gettinUserData);
    loginByToken(
      userDispatch,
      language,
      navigate,
      appInfo?.isAnonymousChildrenEnabled,
    );
  }, [language]);

  // Change User if UI for pets
  useEffect(() => {
    if (
      isAuthenticated &&
      !isEmpty(userState.mmkLinkedList[1]) &&
      appInfo?.uiType === 'petClinic'
    ) {
      changeUser({
        dispatch: userDispatch,
        mmkLinkedList: userState.mmkLinkedList,
        mmkId: userState.mmkLinkedList[1].number,
        clinicId: userState.mmkLinkedList[1].clinicId,
        isAnonymousChildrenEnabled:
          appInfo?.isAnonymousChildrenEnabled,
      });
    }
  }, [userState.mmkLinkedList.length]);

  return serverResponseDictsError ? (
    <ModalError serverResponseDictsError={serverResponseDictsError} />
  ) : (
    <React.Fragment />
  );
}
