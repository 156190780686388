import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

//import { getClinics } from "../../context/VisitContext";
import { useUserStateDispatch } from '../../context/UserContext';

import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import DirectionsItem from './DirectionsItem';
import isEmpty, { isNumeric } from '../../helpers';
import {
  HL7ToDateToSrtDate,
  HL7ToDateToTime,
} from '../../helpers/dateFormat';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading';
import { useVisitState } from '../../context/VisitContext';
import { getDirections } from '../../actions/visit';
import ModaleDoctorInfo from '../visit/ModaleDoctorInfo';
import ModaleCreateVisit from '../visit/ModaleCreateVisit';
import AlertDialogSlide from '../../components/Modals/AlertDialogSlide';
import { Alert } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  montHeader: {
    '&::first-letter': {
      textTransform: 'uppercase',
    },
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

export default function Directions() {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: { directions, visit, visitData },
    setState,
  } = useVisitState();

  const getPrarams = useParams();

  const {
    userState: {
      user: { mmkId, clinicId, lang },
      clinics,
    },
  } = useUserStateDispatch();

  const clinicIdNew =
    getPrarams?.paramClinicId != null
      ? getPrarams?.paramClinicId
      : clinicId;

  const specificRecordId =
    getPrarams?.specificRecordId != null
      ? getPrarams?.specificRecordId
      : 0;

  useEffect(() => {
    getDirections(setState, mmkId, clinicIdNew, specificRecordId);
  }, [mmkId, clinicId, specificRecordId]);

  const [modalDoc, setModalDoc] = useState(false);
  const [modalCreateVisit, setModalCreateVisit] = useState(false);

  const toggleModalDoc = useCallback(
    (item) => {
      if (!modalDoc && !isEmpty(item)) {
        setState({
          type: 'SET_VISIT_DATA',
          payload: { ...item },
        });
      }

      setModalDoc(!modalDoc);
    },

    [modalDoc],
  );

  const toggleModalCreateVisit = (
    visitDate,
    doctor,
    timeSlots,
    visitKindId,
    visitKindName,
    clinic,
    patientDirectionId,
  ) => {
    if (!modalCreateVisit)
      setState({
        type: 'SET_VISIT_DATA',
        payload: {
          visitDate,
          doctor,
          timeSlots,
          visitKindId,
          visitKindName,
          clinic,
          patientDirectionId,
        },
      });

    setModalCreateVisit(!modalCreateVisit);
  };
  const redirectToVisits = () => {
    if (visit?.data) {
      navigate(
        `/app/events_and_visits/${visit.data}/${visit.clinicId}`,
        //'/app/events_and_visits',
      );
    }
  };
  const response = isNumeric(visit.data)
    ? 'visit_added'
    : visit.data === true
    ? 'email_sended'
    : null;

  return (
    <>
      {visit.isSubmited && (
        <AlertDialogSlide
          state={visit}
          setState={setState}
          typeRset="RESET_VISIT"
          isOpen={true}
          contentTextOk={
            response === 'visit_added'
              ? t('VISIT.ALERT_OK')
              : response === 'email_sended'
              ? t('VISIT.ALERT_REQ_EMAIL_DOCTOR_OK')
              : ''
          }
          contentTextOkDetail={`${HL7ToDateToSrtDate(
            visitData.visitDate,
          )} ${HL7ToDateToTime(visitData.visitDate)}`}
          doCallbackWhenOk={
            response === 'visit_added' ? redirectToVisits : null
          }
          buttonTitle={
            response === 'visit_added'
              ? t('COMPONENT.BUTTON_SEE_APPOINMENT')
              : null
          }
        />
      )}
      {!directions.isLoaded ? (
        <Loading />
      ) : directions.responseError != null ? (
        <Alert severity="warning">
          <Typography variant="body2" component="p">
            {directions.responseError}
          </Typography>
        </Alert>
      ) : !isEmpty(directions.data) ? (
        <>
          {directions.data.map((item, inx) => {
            const monthYearCurr = HL7ToDateToSrtDate(
              item.beginDate,
              lang,
              'MMM YYYY',
              true,
            );
            const monthYearPrev = !isEmpty(directions.data[inx - 1])
              ? HL7ToDateToSrtDate(
                  directions.data[inx - 1].beginDate,
                  lang,
                  'MMM YYYY',
                  true,
                )
              : '';
            return (
              <React.Fragment key={item.id}>
                {monthYearCurr !== monthYearPrev && (
                  <Typography
                    variant="h5"
                    className={classes.montHeader}
                  >
                    {monthYearCurr}
                  </Typography>
                )}
                <DirectionsItem
                  item={item}
                  clinics={clinics}
                  lang={lang}
                  toggleModalDoc={toggleModalDoc}
                />
              </React.Fragment>
            );
          })}

          <ModaleDoctorInfo
            modalDoc={modalDoc}
            toggleModalCreateVisit={toggleModalCreateVisit}
            setModalDoc={setModalDoc}
            isOnline={false}
          />
          <ModaleCreateVisit
            modalCreateVisit={modalCreateVisit}
            toggleModalCreateVisit={toggleModalCreateVisit}
          />
        </>
      ) : (
        <Alert severity="warning">
          <Typography variant="h6" component="p">
            {t('DIRECTIONS.NO_DIRECTIONS_RECORDS')}
          </Typography>
        </Alert>
      )}
    </>
  );
}
