import React from 'react';

import useModalChatVisit from '../../visits/useModalChatVisit';
import VisitModalChatConf from '../../visits/VisitModalChatConf';
import Visits from '../../visits/Visits';
import { useUserStateDispatch } from '../../../context/UserContext';
import { useVisitsState } from '../../../context/VisitsContext';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function VisitsContainer({
  setExistVisits,

  isExistPromo,
}) {
  const {
    userState: { user },
  } = useUserStateDispatch();
  const {
    state: { visit },
  } = useVisitsState();

  const { modalChatVisit, toggleModalChatVisit } =
    useModalChatVisit();

  return (
    user?.mmkId != null && (
      <>
        <Visits
          limit={5}
          futureVisits={true}
          setExistVisits={setExistVisits}
          isExistPromo={isExistPromo}
          toggleModalChatVisit={toggleModalChatVisit}
        />
        <VisitModalChatConf
          visit={visit}
          lang={user?.lang}
          modalChatVisit={modalChatVisit}
          toggleModalChatVisit={toggleModalChatVisit}
        />
      </>
    )
  );
}
