import { makeStyles } from '@mui/styles';
export default makeStyles((theme) => ({
  itemButtons: {
    display: 'flex',
    flexDirection: 'column',
  },
  borderRadiusRight: {
    borderRadius: '0 6px 6px 0',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0 0 6px 6px',
    },
  },
  borderRadiusLeft: {
    borderRadius: '6px 0 0 6px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '6px 6px 0 0',
    },
  },
  ifMobileThenHide: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  item: {
    minHeight: theme.spacing(13),
    padding: theme.spacing(2),
  },
  itemVisit: {
    backgroundColor: theme.palette.bgLight.two,
  },

  itemConf: {
    backgroundColor: theme.palette.bgLight.one,
  },

  itemHeight100: {
    height: '100%',
  },

  itemGep: {
    width: 2,
    height: 30,
    backgroundColor: theme.palette.warning.main,
    borderRadius: 1,
  },
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex',
  },

  iconWatch: {
    fontSize: '1rem',
    height: '1.2em',
    marginRight: 4,
  },
  iconButt: {
    fontSize: '1.1rem',
    marginRight: 6,
  },
  iconButtCreateInvoice: {
    fontSize: '1.1rem',
    marginRight: 4,
  },
  iconCheck: {
    fontSize: '1.1rem',
    height: '1.2em',
    marginRight: 4,
  },
  iCame: {
    borderRadius: 48,
    minWidth: 144,
    minHeight: 36,
    padding: '6px 12px',
  },
  cancelled: {
    borderRadius: 48,
    padding: '6px 12px',
    minWidth: 144,
    minHeight: 36,
    color: theme.palette.primary.light,
    '&:hover, &:focus': {
      color: theme.palette.primary.main,
    },
  },

  pay: {
    marginTop: 30,
    textAlign: 'center',
  },
  payBut: {
    borderRadius: 48,
    minWidth: 144,
  },

  iconOrderNotPaid: {
    fontSize: '0.7rem',
    height: '1.5em',
    marginRight: 4,
  },
  container: {
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
    },
    //
    marginBottom: theme.spacing(2),
  },

  buttonConf: {
    textAlign: 'left',
    fontSize: '1.07rem',
    paddingLeft: 0,
    marginTop: '-10px',
  },

  iconConf: {
    color: theme.palette.primary.main,
    fontSize: '1.9rem',
    marginRight: 4,
  },

  locationOnIcon: {
    top: 5,
    left: -5,
    position: 'relative',
    color: theme.palette.primary.light,
  },
  unreadMessagesCount: {
    position: 'relative',
    bottom: 10,
    left: 15,
  },
  Accordion: {
    border: 0,
    backgroundColor: 'inherit',
    boxShadow: 'none',
    padding: 0,
    '&::before': {
      display: 'none',
    },
    '& .MuiAccordionSummary-root': {
      minHeight: 22,
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      color: theme.palette.primary.light,
      fontWeight: 500,
    },
    '& .MuiAccordionDetails-root ': {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      display: 'block',
    },
    '& .MuiIconButton-root': {
      padding: 0,
      color: theme.palette.primary.light,
    },
  },
}));
